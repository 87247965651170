import { NO_MEMBER_TAG_VALUE } from '@racemap/utilities/consts/starters';
import type {
  PreparedRanksPackage,
  PreparedStarterInfo,
  StarterInfo,
} from '@racemap/utilities/functions/timing';
import { isGroupEvent } from '@racemap/utilities/functions/utils';
import type { RacemapEvent } from '@racemap/utilities/types/events';
import type { Immutable } from 'immer';

export const prepareStarterInfo = (
  event: Immutable<RacemapEvent>,
  starterData: StarterInfo & Partial<PreparedStarterInfo>,
  tagsCollected: Map<any, any>,
  preparedStarterData: PreparedRanksPackage,
): {
  preparedStarter: PreparedStarterInfo;
  tagsCollected: Map<any, any>;
  preparedStarterData: PreparedRanksPackage;
} => {
  const preparedStarter: PreparedStarterInfo = {
    ...starterData,
    restDuration: 0,
  };
  if (starterData.currentDuration != null && starterData.activeDuration != null)
    preparedStarter.restDuration = starterData.currentDuration - starterData.activeDuration;

  // get the possible tags from the starters
  if (starterData.tags != null) {
    preparedStarter.tagsRank = {};

    for (const [tagKey, tagValue] of Object.entries(starterData.tags)) {
      // add tag to tags collection
      if (tagsCollected.has(tagKey)) {
        tagsCollected.get(tagKey).add(tagValue);
      } else {
        tagsCollected.set(tagKey, new Set([NO_MEMBER_TAG_VALUE, tagValue]));
      }

      // increase the group counter
      if (!preparedStarterData.tagsStarterCount.has(tagKey)) {
        preparedStarterData.tagsStarterCount.set(tagKey, new Map());
      }
      const state = preparedStarterData.tagsStarterCount.get(tagKey)?.get(tagValue) || 0;
      preparedStarterData.tagsStarterCount.get(tagKey)?.set(tagValue, state + 1);

      // calculate the rank (only for non-group events)
      if (preparedStarter.state == null && !isGroupEvent(event))
        preparedStarter.tagsRank[tagKey] = state + 1;
    }
  }

  if (starterData.tags != null && starterData.state != null && starterData.state !== '-') {
    if (tagsCollected.has('State')) {
      tagsCollected.get('State').add(starterData.state);
    } else {
      tagsCollected.set('State', new Set([starterData.state]));
    }

    starterData.tags.State = starterData.state;
  }

  return {
    preparedStarter: preparedStarter,
    tagsCollected: tagsCollected,
    preparedStarterData: preparedStarterData,
  };
};
