import type { FC } from 'react';
import { Form, type FormCheckProps, FormControl, FormLabel } from 'react-bootstrap';
import { FormCheck } from './FormComponents';

interface Props extends FormCheckProps {
  hint?: React.ReactNode;
  label: string;
  id: string;
  help?: React.ReactNode;
}

const FieldGroup: FC<Props> = ({ id, label, help, type, hint, ...props }) => {
  return (
    <Form.Group controlId={id}>
      <FormLabel>{label}</FormLabel>
      {hint}
      {type === 'switch' || type === 'checkbox' || type === 'radio' ? (
        <FormCheck {...props} label={label} />
      ) : (
        <FormControl target={id} {...props} />
      )}
      {help != null && <Form.Text>{help}</Form.Text>}
    </Form.Group>
  );
};

export default FieldGroup;
