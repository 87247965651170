import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import type { CSSProperties, FC } from 'react';
import { NavLink } from 'react-router-dom';
import { HStack } from '../MetaComponent';
import type { Section, Tab, Tabs } from './Sidebar';

export interface Props {
  section?: Section;
  tabs: Tabs;
  onTabChoosing?: (tab: Tab, section?: Section) => void;
}

export const SidebarSection: FC<Props> = ({ tabs, section, onTabChoosing }) => {
  return (
    <>
      {section != null && <SectionHeader status={section.status}>{section.label}</SectionHeader>}
      <List>
        {tabs.map((tab) => (
          <li key={tab.path} id={`sidebar-entry-${tab.title.toLowerCase()}`}>
            <NavLink to={`${tab.path}`} onClick={() => onTabChoosing?.(tab, section)}>
              <LinkEntry>
                <IconAndText>
                  <IconWrapper>{tab.icon}</IconWrapper> <Text>{tab.title}</Text>
                </IconAndText>
                {tab.badge != null && tab.badge}
              </LinkEntry>
            </NavLink>
          </li>
        ))}
      </List>
    </>
  );
};

const getStatusColor = (status: Section['status']): CSSProperties['color'] => {
  switch (status) {
    case 'success':
      return RacemapColors.BaseGreen;
    case 'primary':
      return RacemapColors.PaleBlue;
    case 'secondary':
      return RacemapColors.DarkGray;
    case 'error':
      return RacemapColors.DarkRed;
    default:
      return RacemapColors.PaleBlue;
  }
};

const SectionHeader = styled.div<{ status: Section['status'] }>`
  border-bottom: solid 1px ${({ status }) => getStatusColor(status)};
  padding-left: 5px;
  color: ${({ status }) => getStatusColor(status)};
`;

const LinkEntry = styled(HStack)`
  padding: 15px 20px;
  text-decoration: none;
  flex-direction: row;

  :hover {
    div {
      filter: brightness(0.5);
    }
  }
`;

const IconAndText = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  height: 20px;
  aspect-ratio: 1;
  margin-right: 10px;

  svg {
    height: 20px;
    aspect-ratio: 1;
  }
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 0;

  li:has(> a.active) {
      background: ${RacemapColors.CloudBlue};
  }
`;

const Text = styled.div`
  font-weight: 500;
`;
