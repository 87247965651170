import styled from '@emotion/styled';
import { MaxMobileWindowWidth, RacemapColors } from '@racemap/utilities/consts/common';
import type { Immutable } from 'immer';
import { type FC, useState } from 'react';
import { useInterval } from 'usehooks-ts';
import type { CurrentEvent } from '../../../store/events/events_reducers';
import { useStore } from '../../../store/reducers';
import { RelativeTime } from '../../BasicComponents/TimeDuration/RelativeTime';
import { Tristate } from '../../ButtonWithSpinner';
import { EventInfoBadges } from '../../EventInfoBadges';
import { IconFail, IconOk, IconSpinner } from '../../Icon';

interface Props {
  event: Immutable<CurrentEvent>;
}

export const EventMetaInfos: FC<Props> = ({ event }) => {
  return (
    <Container>
      <TitleAndBadges>
        <Title title={event.name}>
          {event.name == null || event.name === '' ? <i>Untitled</i> : event.name}
        </Title>
        <EventInfoBadges event={event} />
      </TitleAndBadges>
      <UpdatedAtLabel event={event} />
    </Container>
  );
};

const Container = styled.div`
  padding-left: 10px;
  padding-right: 10px;

  @media (max-width: ${MaxMobileWindowWidth}px) {
    max-width: calc(100% - 55px);
  }
`;

const TitleAndBadges = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.strong`
  display: inline-block;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const UpdatedAtLabel: FC<{ event: Immutable<CurrentEvent> }> = ({ event }) => {
  const [count, setCount] = useState(0);
  useInterval(() => {
    setCount(count + 1);
  }, 1000);
  const savingState = useStore((s) => s.events.savingState);

  return (
    <div style={{ color: RacemapColors.DarkGray }}>
      {savingState === Tristate.SPINNING ? (
        <>
          <IconSpinner /> saving
        </>
      ) : savingState === Tristate.FAILURE ? (
        <>
          <IconFail /> Save failed
        </>
      ) : (
        <>
          <IconOk /> saved <RelativeTime time={event.updatedAt} />
        </>
      )}
    </div>
  );
};
