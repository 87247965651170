import { VisibilityLabels, VisibilityStates } from '@racemap/utilities/consts/events';
import React, { useState } from 'react';
import { RadioButtons } from './FormComponents';
import {
  IconVisibilityArchived,
  IconVisibilityDraft,
  IconVisibilityListed,
  IconVisibilityPublic,
  IconVisibilityUnlisted,
} from './Icon';

export default function VisibilitySection({
  value,
  onChange = () => {},
  authorized,
  possibleStates = Object.values(VisibilityStates).filter((e) => e !== VisibilityStates.PUBLIC),
  target = 'event',
  disabled = false,
}: {
  value: VisibilityStates;
  onChange?: (arg0: VisibilityStates) => void;
  authorized: boolean;
  possibleStates?: Array<VisibilityStates>;
  target?: string;
  disabled?: boolean;
}) {
  const [hover, setHover] = useState<VisibilityStates | null | undefined>(null);
  const helpValue = hover != null ? hover : value;
  const IconVisibility = {
    DRAFT: <IconVisibilityDraft />,
    UNLISTED: <IconVisibilityUnlisted />,
    LISTED: <IconVisibilityListed />,
    ARCHIVED: <IconVisibilityArchived />,
    PUBLIC: <IconVisibilityPublic />,
  };

  return (
    <>
      <RadioButtons
        value={value}
        onChange={onChange}
        onHover={setHover}
        options={possibleStates.map((state) => {
          return {
            value: state,
            title: (
              <div>
                {' '}
                {IconVisibility[state]} {VisibilityLabels[state]}
              </div>
            ),
            disabled: disabled || state === VisibilityStates.DRAFT || !authorized,
          };
        })}
      />
      <div style={{ fontSize: 11 }}>{createHelpText(helpValue, authorized, target)}</div>
    </>
  );
}

const createHelpText = (visibilityState: VisibilityStates, authorized: boolean, target: string) => {
  switch (visibilityState) {
    case VisibilityStates.DRAFT:
      if (authorized && target !== 'stageGroup')
        return (
          <>
            Your Tracking Map is currently in a draft state, but already functioning. Make sure to
            set it to <strong>Unlisted, Listed or Open</strong> in order to activate your Tracking
            Map.
          </>
        );
      if (authorized && target === 'stageGroup')
        return (
          <>Set visibility status for your stage event, when Base Price of each stage is paid.</>
        );
      return (
        <>
          Test geolocation and live tracking in draft state of your event for free. You can use
          add-ons and adapt the settings to your use case.
        </>
      );
    case VisibilityStates.LISTED:
      if (target === 'contestGroup')
        return (
          <>
            We help you reach a large audience. If all single maps are listed, your Group/Stage Map
            is shown in our public event lists on the{' '}
            <a href="/events" target="_blank" rel="noopener noreferrer">
              Racemap website
            </a>{' '}
            and in the Racemap App. Additionally, you can share the link or embed the tracking map
            on other websites in order to expand your audience.
          </>
        );
      return (
        <>
          We help you reach a large audience. Your Tracking Map is shown in our public event lists
          on the{' '}
          <a href="/events" target="_blank" rel="noopener noreferrer">
            Racemap website
          </a>{' '}
          and in the Racemap App. Additionally, you can share the link or embed the tracking map on
          other websites in order to expand your audience.
        </>
      );
    case VisibilityStates.UNLISTED:
      if (target === 'contestGroup')
        return (
          <>
            Your Group/Stage Map is <strong>not</strong> listed in our public event lists on the{' '}
            <a href="/events" target="_blank" rel="noopener noreferrer">
              Racemap website
            </a>{' '}
            or in the Racemap App. Only people with access to the link will be able to view it. You
            can share the link and embed the Group/Stage Map on other websites in order to reach
            your target audience.
          </>
        );
      return (
        <>
          Your event is <strong>not</strong> listed in our public event lists on the{' '}
          <a href="/events" target="_blank" rel="noopener noreferrer">
            Racemap website
          </a>{' '}
          or in the Racemap App. Only people with access to the link will be able to view it. You
          can share the link and embed the tracking map on other websites in order to reach your
          target audience.
        </>
      );
    case VisibilityStates.ARCHIVED:
      if (target === 'contestGroup')
        return (
          <>
            There will no loads charged for an archived event. An archived event will not load the
            Group/Stage map, Leaderboard, Data API or display any other information. You can restore
            the event at any time.
          </>
        );
      return (
        <>
          There will no loads charged for an archived event. An archived event will not load the
          Tracking map, Leaderboard, Data API or display any other information. You can restore the
          event at any time.
        </>
      );
    case VisibilityStates.PUBLIC:
      return (
        <>
          We help you reach a large audience. Your Tracking Map is shown in our public event lists
          on the{' '}
          <a href="/events" target="_blank" rel="noopener noreferrer">
            Racemap website
          </a>{' '}
          and in the Racemap App. Additionally, you can share the link or embed the Tracking Map on
          another website in order to expand your audience. Everybody with the Racemap App will be
          able to join your Tracking Map. You may also add participants manually, with Keys or with
          the Passcode.
        </>
      );
    default:
      return <div />;
  }
};
