import styled from '@emotion/styled';
import classNames from 'classnames';
import type { CSSProperties, FC } from 'react';

export enum Animations {
  NONE = 'none',
  GLOW = 'glow',
  WAVE = 'wave',
}

interface Props {
  animation?: Animations;
  width?: CSSProperties['width'];
}

export const Placeholder: FC<Props> = ({ animation = Animations.NONE, width = '100%' }) => (
  <Container
    className={classNames({
      'placeholder-glow': animation === Animations.GLOW,
      'placeholder-wave': animation === Animations.WAVE,
    })}
    style={{ width }}
  >
    <div className="placeholder" style={{ padding: 5, width: '100%' }} />
  </Container>
);

const Container = styled.div`
  .placeholder {
    display: inline-block;
    min-height: 1em;
    border-radius: 9999px;
    vertical-align: middle;
    cursor: wait;
    background-color: currentcolor;
    opacity: 0.5;

    &.btn::before {
      display: inline-block;
      content: '';
    }
  }

  // Animation
  &.placeholder-glow {
    .placeholder {
      animation: placeholder-glow 2s ease-in-out infinite;
    }
  }

  @keyframes placeholder-glow {
    50% {
      opacity: 0.2;
    }
  }

  &.placeholder-wave {
    mask-image: linear-gradient(130deg, $black 55%, rgba(0, 0, 0, (1 - 0.2)) 75%, $black 95%);
    mask-size: 200% 100%;
    animation: placeholder-wave 2s linear infinite;
  }

  @keyframes placeholder-wave {
    100% {
      mask-position: -200% 0%;
    }
  }
`;
