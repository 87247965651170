import styled from '@emotion/styled';
import { MaxMobileWindowWidth } from '@racemap/utilities/consts/common';
import TestSelectors from '@racemap/utilities/consts/test_selectors';
import type { FC } from 'react';
import { useIsMobile } from '../../lib/customHooks';
import { ButtonRouter } from '../BasicComponents/ButtonRouter/ButtonRouter';
import { type Sections, Sidebar, type Tabs } from '../BasicComponents/Sidebar';

interface Props {
  tabs: Tabs;
  sections: Sections;
}

export const EventEditorSidebar: FC<Props> = ({ tabs, sections }) => {
  const isMobile = useIsMobile();

  return (
    <Container id={TestSelectors.admin.eventEditor.sidebar.container.toString()}>
      {isMobile ? (
        <ButtonRouter tabs={tabs} sections={sections} />
      ) : (
        <Sidebar tabs={tabs} sections={sections} />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 103px;
  left: 0px;
  bottom: 0px;
  width: 200px;
  background-color: white;
  z-index: 2;
  border-right: solid 1px #e7e7e7;
  padding-top: 10px;
  overflow-x: auto;

  @media (max-width: ${MaxMobileWindowWidth}px) {
    top: 50px;
    right: 0;
    bottom: unset;
    left: unset;
    width: 56px;
    height: 56px;
    border-right: unset;
    padding-top: unset;
    border-bottom: solid 1px #e7e7e7;
  }
`;
