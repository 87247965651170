import styled from '@emotion/styled';
import { ObjectId } from '@racemap/sdk/schema/base';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { Flex, Popconfirm } from 'antd';
import type { FC } from 'react';
import { IconClear } from '../../Icon';
import { Devider } from '../Devider/Devider';
import { UserChooser } from './UserChooser';
import { UserInfos } from './UserInfos';

interface Props {
  label?: string;
  value: Array<ObjectId>;
  onChange?: (userIds: Array<ObjectId>) => void;
  withDevider?: boolean;
  askForRemoval?: boolean;
  removalConfirmTitle?: string;
  removalConfirmDescription?: string;
}

export const UserList: FC<Props> = ({
  value,
  label = 'Users:',
  onChange,
  withDevider = true,
  removalConfirmDescription = 'Do you really want to remove that user?',
  removalConfirmTitle = 'Remove User',
  askForRemoval = true,
}) => {
  const locked = onChange == null;

  function handleNewUser(userId: ObjectId | null) {
    if (userId == null || locked) return;
    const newUsers = new Set([...value, userId].map((u) => u.toString()));

    onChange(Array.from(newUsers).map((u) => new ObjectId(u)));
  }

  function handleUserRemove(userId: ObjectId) {
    if (locked) return;

    const newUsers = new Set(value.filter((uId) => !uId.equals(userId)));
    onChange(Array.from(newUsers).map((u) => new ObjectId(u)));
  }

  return (
    <Container>
      <Label>{label}</Label>
      {!locked && (
        <UserChooserContainer>
          <UserChooser
            onChange={handleNewUser}
            clearAfterChoosing
            action="add"
            filterUserIds={value}
          />
        </UserChooserContainer>
      )}

      {withDevider && <Devider />}
      <Flex gap={10} wrap>
        {value.map((uId) => (
          <UserInfoContainer key={uId.toHexString()}>
            <UserInfos userId={uId} />
            {!locked && (
              <Popconfirm
                title={removalConfirmTitle}
                disabled={!askForRemoval}
                description={removalConfirmDescription}
                onConfirm={() => handleUserRemove(uId)}
              >
                <Flex>
                  <RemoveButton />
                </Flex>
              </Popconfirm>
            )}
          </UserInfoContainer>
        ))}
      </Flex>
    </Container>
  );
};

const RemoveButton = styled(IconClear)`
  color: ${RacemapColors.DarkGray};
  cursor: pointer;
`;

const Label = styled.div`
  font-size: medium;
  font-weight: 500;
  padding-bottom: 10px;
`;

const UserChooserContainer = styled.div`
  padding-bottom: 15px;
  max-width: 500px;
`;

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5px;
  border: solid 2px ${RacemapColors.DarkGray};
  min-width: 250px;
  border-radius: 0.25rem;
  background-color: white;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
