import styled from '@emotion/styled';
import { MaxMobileWindowWidth, RacemapColors } from '@racemap/utilities/consts/common';
import { AddOns, type ModulesStates } from '@racemap/utilities/consts/events';
import { isActivated, isEnabled } from '@racemap/utilities/functions/event';
import { isGroupEvent } from '@racemap/utilities/functions/utils';
import type { OptionModules } from '@racemap/utilities/types/events';
import type { Immutable } from 'immer';
import type { FC, ReactNode } from 'react';
import { Badge } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import { useCurrentEvent, useIsAdmin } from '../../lib/customHooks';
import { useDocumentTitle } from '../../lib/customHooks/useDocumentTitle';
import { useStore } from '../../store/reducers';
import type { Sections, Tabs } from '../BasicComponents/Sidebar';
import {
  IconAdmin,
  IconAdvertising,
  IconChartLine,
  IconDatafeed,
  IconKey,
  IconLeaderboard,
  IconLink,
  IconMap,
  IconMonitor,
  IconPaintRoller,
  IconPrediction,
  IconRunner,
  IconStart,
  IconWrench,
} from '../Icon';
import { EventEditorSidebar } from './EventEditorSidebar';
import { PaymentModal } from './PaymentModal';
import { TitleBar } from './TitleBar';

export enum SectionKeys {
  ACTIVATED = 0,
  AVAILABLE = 1,
  ADVANCED = 2,
  ADMIN = 3,
  ESSENTIALS = 4,
}

export const EventEditor: FC = () => {
  const isAdmin = useIsAdmin();
  const event = useCurrentEvent();
  const showPaymentModal = useStore((s) => s.events.showPaymentModal);
  const { closePaymentModal } = useStore((s) => s.events.actions);
  useDocumentTitle(event?.name ? `${event.name} | Racemap` : 'Racemap');

  if (event == null) return <></>;
  const isGroup = isGroupEvent(event);
  const starterCount = event.starters?.size || undefined;

  const sections: Sections = [
    {
      key: SectionKeys.ESSENTIALS,
      label: 'Essentials',
      status: 'primary',
    },
    {
      key: SectionKeys.ACTIVATED,
      label: 'Activated',
      status: 'success',
    },
    {
      key: SectionKeys.AVAILABLE,
      label: 'Available',
      status: 'primary',
    },
    {
      key: SectionKeys.ADVANCED,
      label: 'Expert',
      status: 'primary',
    },
    {
      key: SectionKeys.ADMIN,
      label: 'Admin',
      status: 'primary',
    },
  ];

  const groupEventTabs: Tabs = [
    {
      title: 'Groupbasic',
      path: 'basic',
      icon: <IconStart />,
      sectionKey: SectionKeys.ESSENTIALS,
    },
  ];

  const singleEventTabs: Tabs = [
    {
      title: 'Basic',
      path: 'basic',
      icon: <IconStart />,
      sectionKey: SectionKeys.ESSENTIALS,
    },
    {
      title: 'Map',
      path: 'map',
      icon: <IconMap />,
      sectionKey: SectionKeys.ESSENTIALS,
    },
    {
      title: 'Visualisation',
      path: 'visualisation',
      icon: <IconPaintRoller />,
      sectionKey: SectionKeys.ESSENTIALS,
    },
    {
      title: 'Monitor',
      path: 'monitor',
      icon: <IconMonitor />,
      sectionKey: getAddonSection(AddOns.MONITOR, event.modules),
      badge: getActivationBadge(event.modules.monitor),
    },
    {
      title: 'Prediction',
      path: 'prediction',
      icon: <IconPrediction />,
      sectionKey: getAddonSection(AddOns.PREDICTIVE, event.modules),
      badge: getActivationBadge(event.modules.predictive),
    },
    {
      title: 'Advanced',
      path: 'advanced',
      icon: <IconWrench />,
      sectionKey: SectionKeys.ADVANCED,
    },
    {
      title: 'Data API',
      path: 'dataapi',
      icon: <IconDatafeed />,
      sectionKey: getAddonSection(AddOns.API, event.modules),
      badge: getActivationBadge(event.modules.dataFeed),
    },
    {
      title: 'Passcode/Keys',
      path: 'passcode_keys',
      icon: <IconKey />,
      sectionKey: SectionKeys.ADVANCED,
    },
    {
      title: 'Sponsor Logo',
      path: 'sponsor',
      icon: <IconAdvertising />,
      sectionKey: getAddonSection(AddOns.SPONSOR, event.modules),
      badge: getActivationBadge(event.modules.sponsor),
    },
  ];

  const tabs: Tabs = [
    ...(isGroup ? groupEventTabs : singleEventTabs),
    {
      title: 'Participants',
      path: 'participants',
      icon: <IconRunner />,
      sectionKey: SectionKeys.ESSENTIALS,
      badge: getCountBadge(starterCount),
    },
    {
      title: 'Leaderboard',
      path: 'leaderboard',
      icon: <IconLeaderboard />,
      sectionKey: getAddonSection(AddOns.TIMING, event.modules),
      badge: getActivationBadge(event.modules.timing),
    },
    {
      title: 'Analytics',
      path: 'analytics',
      icon: <IconChartLine />,
      sectionKey: SectionKeys.ADVANCED,
    },
    {
      title: 'Integrations',
      path: 'integrations',
      icon: <IconLink />,
      sectionKey: SectionKeys.ADVANCED,
    },
    ...(isAdmin
      ? [
          {
            title: 'Admin',
            path: 'admin',
            icon: <IconAdmin />,
            sectionKey: SectionKeys.ADMIN,
          },
        ]
      : []),
  ];

  if (event == null) return <></>;
  return (
    <Container className="event-editor">
      <TitleBar />
      <Body>
        <EventEditorSidebar tabs={tabs} sections={sections} />

        <Content className="event-editor-content">
          <Outlet />
        </Content>
      </Body>

      {/* Modals */}
      <PaymentModal show={showPaymentModal} onClose={closePaymentModal} />
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  min-height: 100vh;
  display: flex;
  padding-top: 106px;
  bottom: 0;

  @media (max-width: ${MaxMobileWindowWidth}px) {
    padding-top: 0;
    flex-direction: column;
    top: 50px;
    min-height: unset;
  }
`;

const Body = styled.div`
  display: flex;
  flex: 1;

  @media (max-width: ${MaxMobileWindowWidth}px) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  position: absolute;
  right: 0;
  left: 200px;
  bottom: 0;
  top: 106px;
  overflow: auto;

  @media (max-width: ${MaxMobileWindowWidth}px) {
    position: relative;
    left: unset;
    right: unset;

    top: unset;
    padding-left: 0;
    margin-left: 0;
    flex: 1;
  }
`;

function getAddonSection(addon: AddOns, modules: Immutable<OptionModules>): SectionKeys {
  switch (addon) {
    case AddOns.API:
      return getTabSection(modules.dataFeed);
    case AddOns.SPONSOR:
      return getTabSection(modules.sponsor);
    case AddOns.MONITOR:
      return getTabSection(modules.monitor);
    case AddOns.PREDICTIVE:
      return isActivated(modules.predictive) ? SectionKeys.ACTIVATED : SectionKeys.AVAILABLE;
    case AddOns.TIMING:
      return getTabSection(modules.timing);
    default:
      return SectionKeys.AVAILABLE;
  }
}

function getTabSection(module: { state?: ModulesStates; enabled?: boolean }): SectionKeys {
  return isActivated(module) || isEnabled(module) ? SectionKeys.ACTIVATED : SectionKeys.AVAILABLE;
}

function getActivationBadge(module: { state?: ModulesStates; enabled?: boolean }): ReactNode {
  if (getTabSection(module) !== SectionKeys.ACTIVATED) return <></>;
  if (isActivated(module)) return <Badge style={{ color: RacemapColors.BaseGreen }}>ON</Badge>;

  return <Badge style={{ color: RacemapColors.DarkYellow }}>OFF</Badge>;
}

const numberFormatter = Intl.NumberFormat('en', { notation: 'compact' });

function getCountBadge(count?: number): ReactNode {
  if (count == null) return <></>;
  return (
    <Badge style={{ fontSize: '0.9rem', fontWeight: 600 }}>{numberFormatter.format(count)}</Badge>
  );
}
