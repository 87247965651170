import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import React, { type CSSProperties, type FC } from 'react';
import { Spinner } from 'react-bootstrap';
import { IconReload } from '../../Icon';

interface Props {
  isLoading?: boolean;
  onReload?: () => void;
  style?: CSSProperties;
  className?: string;
  text?: string;
  borderless?: boolean;
}

export const LoadingIndicator: FC<Props> = ({
  isLoading = true,
  onReload,
  text = 'Loading...',
  borderless = false,
  ...props
}) => {
  if (!isLoading && onReload == null) return <></>;

  const handleClick = () => {
    if (!isLoading && onReload != null) {
      onReload();
    }
  };
  return (
    <Container isLoading={isLoading} onClick={handleClick} borderless={borderless} {...props}>
      {isLoading ? <Spinner animation="border" size="sm" /> : <IconReload />}
      <Text>{isLoading ? text : 'Reload'}</Text>
    </Container>
  );
};

const Container = styled.div<{ isLoading: boolean; borderless: boolean }>`
  display: flex;
  flex-direction: row;
  background: white;
  height: 30px;
  align-items: center;
  border-radius: 15px;
  padding: 15px;
  color: rgba(0, 0, 0, 0.65);
  border: solid 1px
    ${({ isLoading }) => (isLoading ? RacemapColors.LightGray : RacemapColors.PaleBlue)};
  cursor: ${({ isLoading }) => (isLoading ? 'default' : 'pointer')};
  ${({ borderless }) => borderless && 'border: none;'}

  :hover {
    background: ${({ isLoading }) => (isLoading ? 'white' : RacemapColors.LightGray)};
  }
`;

const Text = styled.div`
  font-size: 1.1em;
  padding-left: 5px;
  font-weight: 500;
`;
