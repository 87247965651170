import { type FC, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { useStore } from 'src/store/reducers';
import { Header } from '../components/Headers/Header';
import { useCurrentUser } from '../lib/customHooks';

const publicRoutes = [
  /^\/auth\/login$/,
  /^\/auth\/logout$/,
  /^\/auth\/register$/,
  /^\/auth\/reset-password$/,
  /^\/auth\/forgot-password$/,
  /^\/auth\/confirm-email$/,
  /^\/upload$/,
  /^\/cost-calculator\/[^/]+$/,
  /^\/app$/,
  /^\/app\/redeem_key$/,
  /^\/app\/[^/]+$/,
  /^\/app\/[^/]+\/redeem_key$/,
  /^\/timing\/[^/]+$/,
  /^\/monitor\/[^/]+$/,
];

export const RootView: FC = () => {
  const currentUser = useCurrentUser();
  const isLoadingCurrentUser = useStore((s) => s.users.isLoadingCurrentUser);
  const isAuthenticated = currentUser != null;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoadingCurrentUser) return;
    if (isAuthenticated && location.pathname === '/auth/login') {
      navigate('/admin');
    } else if (!isAuthenticated && !publicRoutes.some((route) => route.test(location.pathname))) {
      navigate('/auth/login');
    }
  }, [isAuthenticated, isLoadingCurrentUser]);

  if (isLoadingCurrentUser) return <LoadingSpinner />;

  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};
