import { z } from 'zod';
import { DateSchema, ObjectIdSchema } from '../base';
import { EventLoadTypes } from '../events';

export const DailyLoadSchema = z.object({
  count: z.number(),
  time: DateSchema,
  type: z.nativeEnum(EventLoadTypes),
  eventId: ObjectIdSchema.optional(),
});

export const DailyLoadsSchema = z.array(DailyLoadSchema);
export type DailyLoad = z.infer<typeof DailyLoadSchema>;
export type DailyLoads = z.infer<typeof DailyLoadsSchema>;
