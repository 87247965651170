import type { RouteObject } from 'react-router';
import { MessageTemplatesView } from 'src/admin/views/MessageTemplatesView';

export const messageTemplatesRoutes: Array<RouteObject> = [
  {
    path: 'message-templates',
    element: <MessageTemplatesView />,
    children: [
      {
        path: '',
        lazy: async () => {
          const comp = await import('../components/MessageTemplatesManager/AllTemplatesList');
          return { Component: comp.AllTemplatesList };
        },
      },
      {
        path: ':templateId',
        lazy: async () => {
          const comp = await import(
            '../components/MessageTemplatesManager/MessageTemplateEditorPage'
          );
          return { Component: comp.MessageTemplateEditorPage };
        },
      },
    ],
  },
];
