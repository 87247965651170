import type Stripe from 'stripe';
import { calculateQuantity } from './calculateQuantity';

/**
 * Calculates the total amount for a given quantity of a product based on its price and billing scheme.
 *
 * @param {Stripe.Price} productPrice - The price object from Stripe, containing metadata and billing information.
 * @param {number} quantity - The quantity of the product to calculate the amount for.
 * @param {number | null} [tierIndex=null] - The index of the tier to use for tiered pricing schemes, if applicable.
 * @returns {number} - The total amount for the given quantity of the product.
 *
 * @throws {Error} If no tiers are found for a tiered pricing scheme.
 * @throws {Error} If no tier is found at the specified index for a tiered pricing scheme.
 * @throws {Error} If no unit amount is found for the specified tier index or quantity.
 * @throws {Error} If an unknown billing scheme is encountered.
 */
export const getAmountForQuantityBasedOnPrice = (
  productPrice: Stripe.Price,
  quantity: number,
  tierIndex: number | null = null,
): number => {
  if (quantity < 0) throw new Error('Quantity must be greater than or equal to 0.');

  const calculationQuantity = calculateQuantity(productPrice, quantity);
  const product = productPrice.lookup_key;

  if (productPrice.billing_scheme === 'per_unit' && productPrice.unit_amount != null) {
    return productPrice.unit_amount * calculationQuantity;
  }

  // the tier index is defined, so the unit amount for that special tier is relevant
  if (productPrice.billing_scheme === 'tiered' && tierIndex != null) {
    if (productPrice.tiers == null) throw new Error(`No tiers found for product ${product}`);
    const tier = productPrice.tiers[tierIndex];
    if (tier == null) throw new Error(`No tier found for product ${product} at index ${tierIndex}`);
    if (tier.unit_amount == null)
      throw new Error(`No unit amount found for product ${product} at index ${tierIndex}`);

    return tier.unit_amount * calculationQuantity;
  }

  // the tier index is not defined, so we need to calculate the price based on the quantity for
  // the volume pricing scheme
  if (productPrice.billing_scheme === 'tiered' && productPrice.tiers_mode === 'volume') {
    if (productPrice.tiers == null) throw new Error(`No tiers found for product ${product}`);
    const tier = productPrice.tiers.find((t) => t.up_to != null && calculationQuantity <= t.up_to);
    if (tier == null)
      throw new Error(`No tier found for product ${product} at quantity ${calculationQuantity}`);
    if (tier.unit_amount == null)
      throw new Error(
        `No unit amount found for product ${product} at quantity ${calculationQuantity}`,
      );

    return tier.unit_amount * calculationQuantity;
  }

  // the tier index is not defined, so we need to calculate the price based on the quantity for
  // the graduated pricing scheme
  if (productPrice.billing_scheme === 'tiered' && productPrice.tiers_mode === 'graduated') {
    if (productPrice.tiers == null) throw new Error(`No tiers found for product ${product}`);
    let totalPrice = 0;
    let remainingQuantity = calculationQuantity;
    let chargedQuantity = 0;

    for (const tier of productPrice.tiers) {
      if (tier.unit_amount == null)
        throw new Error(
          `No unit amount found for product ${product} at quantity ${remainingQuantity}`,
        );

      const tierQuantity = Math.min(
        remainingQuantity,
        (tier.up_to || Number.POSITIVE_INFINITY) - chargedQuantity,
      );
      totalPrice += tier.unit_amount * tierQuantity;
      chargedQuantity += tierQuantity;
      remainingQuantity -= tierQuantity;
    }

    return totalPrice;
  }

  throw new Error(`Unknown billing scheme for product ${product}: ${productPrice.billing_scheme}.`);
};
