import { HTTPFetchError, RacemapAPIClient } from '@racemap/utilities/api-client';
import { createPatchObj } from '@racemap/utilities/functions/createPatchObj';
import { isDefined } from '@racemap/utilities/functions/validation';
import type { User_Legacy } from '@racemap/utilities/types/users';
import type { PathInto, TypeOfPath } from '@racemap/utilities/types/utils';
import { type Immutable, produce } from 'immer';
import type { StoreApi } from 'zustand';
import { toastError } from '../../components/utils/Utils';
import type { DraftState, State } from '../reducers';

const apiClient = RacemapAPIClient.fromWindowLocation();

export interface UserState {
  users: {
    items: Map<string, User_Legacy>;
    currentUserId: null | string;
    isSaving: boolean;
    isLoadingCurrentUser: boolean;
    getter: {
      currentUser: () => Immutable<User_Legacy> | null;
      user: (userId: string | null | undefined) => Immutable<User_Legacy> | null;
      isAdmin: () => boolean;
    };
    actions: {
      loadUser: (userId: string) => Promise<User_Legacy>;
      loadUsers: () => Promise<void>;
      loadCurrentUser: () => Promise<void>;
      removeUser: (userId: string) => Promise<void>;
      updateUser: <P extends PathInto<User_Legacy>>(
        userId: string,
        userUpdates: Array<{ key: P; newValue: TypeOfPath<User_Legacy, P> }>,
      ) => Promise<void>;
      toggleFakeUser: () => Promise<void>;
      login: (email: string, password: string) => Promise<void>;
      logout: () => Promise<void>;
      register: (args: {
        name: string;
        email: string;
        password: string;
        company?: string;
        phone?: string;
        country?: string;
      }) => Promise<void>;
      resetPassword: (password: string, userId: string, token: string) => Promise<void>;
      requestPasswordReset: (email: string) => Promise<void>;
      confirmEmail: (userId: string, token: string) => Promise<void>;
    };
  };
}

export const createUserStore = (
  set: StoreApi<State>['setState'],
  get: StoreApi<State>['getState'],
): UserState => ({
  users: {
    items: new Map(),
    currentUserId: null,
    isSaving: false,
    isLoadingCurrentUser: true, // after side loading the first action is to start the loading of the current user
    getter: {
      currentUser: () => {
        const currentUserId = get().users.currentUserId || '';
        return get().users.items.get(currentUserId) || null;
      },
      isAdmin: () => {
        const currentUserId = get().users.currentUserId || '';
        return get().users.items.get(currentUserId)?.admin || false;
      },
      user: (userId) => {
        if (userId == null) return null;
        return get().users.items.get(userId) || null;
      },
    },
    actions: {
      loadUser: async (userId) => {
        const user = await apiClient.getUser(userId);
        set(
          produce((s) => {
            s.users.items.set(userId, user);
          }),
        );

        return user;
      },
      loadUsers: async () => {
        const { users } = await apiClient.getUsers();
        set(
          produce((s) => {
            users.forEach((user) => {
              s.users.items.set(user.id, user);
            });
          }),
        );
      },
      loadCurrentUser: async () => {
        set(
          produce((s) => {
            s.users.isLoadingCurrentUser = true;
          }),
        );
        try {
          const user = await apiClient.getCurrentUser();
          set(
            produce((s) => {
              s.users.items.set(user.id, user);
              s.users.currentUserId = user.id;
              s.users.isLoadingCurrentUser = false;
            }),
          );
        } catch {
          set(
            produce((s) => {
              s.users.isLoadingCurrentUser = false;
            }),
          );
        }
      },
      removeUser: async (userId) => {
        await apiClient.removeUser(userId);
        set(
          produce((s) => {
            s.users.items.delete(userId);
          }),
        );
      },
      updateUser: async (userId, updates) => {
        set(
          produce((s: DraftState) => {
            s.users.isSaving = true;
          }),
        );
        let currentUserState = get().users.items.get(userId);

        try {
          if (currentUserState == null) {
            currentUserState = await get().users.actions.loadUser(userId);
          }

          if (!isDefined(currentUserState)) {
            throw new Error(`Fail User Update. User with userId ${userId} is unknown.`);
          }

          // create the patch object, that only consist with the updates
          const patchObj = createPatchObj<User_Legacy>(updates, currentUserState as User_Legacy);
          set(
            produce((s: DraftState) => {
              s.users.items.set(
                userId,
                Object.assign(s.users.items.get(userId) || {}, patchObj as User_Legacy),
              );
            }),
          );

          const updatedUser = await apiClient.updateUser(userId, patchObj);
          set(
            produce((s) => {
              s.users.items.set(userId, updatedUser);
            }),
          );
        } catch (error) {
          if (error instanceof HTTPFetchError && error.serverError != null) {
            toastError(error.serverError);
          }

          set(
            produce((s: DraftState) => {
              if (isDefined(currentUserState)) {
                s.users.items.set(userId, Object.assign(currentUserState));
              }
            }),
          );
        }
        set(
          produce((s: DraftState) => {
            s.users.isSaving = false;
          }),
        );
      },
      toggleFakeUser: async () => {
        const currentUser = get().users.getter.currentUser();
        const user = {
          ...currentUser,
          fakeUser: !currentUser?.fakeUser,
          admin: !currentUser?.admin,
        };

        set(
          produce((s) => {
            s.users.items.set(user.id, user);
          }),
        );
      },
      login: async (email, password) => {
        await apiClient.login(email, password);
        const user = await apiClient.getCurrentUser();

        set(
          produce((s) => {
            s.users.items.set(user.id, user);
            s.users.currentUserId = user.id;
          }),
        );
      },
      logout: async () => {
        await apiClient.logout();
        set(
          produce((s) => {
            s.users.currentUserId = null;
          }),
        );
      },
      register: async (data) => {
        await apiClient.register(data);
      },
      resetPassword: async (password, userId, token) => {
        await apiClient.resetPassword(password, userId, token);
      },
      requestPasswordReset: async (email) => {
        await apiClient.requestPasswordReset(email);
      },
      confirmEmail: async (userId: string, token?: string) => {
        await apiClient.confirmEmail(userId, token);
      },
    },
  },
});
