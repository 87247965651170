import styled from '@emotion/styled';
import { DailyLoadSchema } from '@racemap/sdk/schema/billing/dailyLoads';
import { EventTypes } from '@racemap/sdk/schema/events';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { EventLoadTypes, type Load } from '@racemap/utilities/types/events';
import type { DailyLoads } from '@racemap/utilities/types/users';
import { Space, Table, type TableProps, Typography } from 'antd';
import { DateTime } from 'luxon';
import type { FC } from 'react';
import { formatNumber } from '../../misc/pricing';
import { getSeriesColor, loadTypeLabels } from '../Charts/LoadsChart';
import { IconSquare } from '../Icon';

const { Text } = Typography;

type Loads = Array<Load>;
interface Props {
  loads?: Loads | DailyLoads;
  zoomStartDate?: Date | null;
  zoomEndDate?: Date | null;
  isLoading: boolean;
  eventNameMapping?: Record<string, string>;
  eventType?: EventTypes;
}

export const LoadsCountTable: FC<Props> = ({
  loads,
  zoomStartDate,
  zoomEndDate,
  isLoading,
  eventNameMapping,
  eventType,
}) => {
  const parsedLoads = loads?.map(DailyLoadSchema.parse);
  const totalLoads = getTotalLoads(parsedLoads, zoomStartDate, zoomEndDate, eventNameMapping);
  const totalLoadsWithoutTotal = totalLoads
    .filter((p) => p.type !== 'total')
    .sort((a, b) =>
      `${loadTypeLabels[a.type as EventLoadTypes]} ${a.eventName}`.localeCompare(
        `${loadTypeLabels[b.type as EventLoadTypes]} ${b.eventName}`,
      ),
    );
  const total = totalLoads.find((p) => p.type === 'total');
  const columns = getColumns({ eventNameMapping, eventType });
  let columnsFinal = [...columns];

  if (zoomStartDate && zoomEndDate) {
    columnsFinal = [
      ...columns.slice(0, 1),
      {
        title: (
          <Space direction="vertical">
            <div>Loads of Zoom</div>
            <Text type="secondary">
              {DateTime.fromJSDate(zoomStartDate).toLocaleString(DateTime.DATE_SHORT)}&nbsp;-&nbsp;
              {DateTime.fromJSDate(zoomEndDate).toLocaleString(DateTime.DATE_SHORT)}
            </Text>
          </Space>
        ),
        dataIndex: 'countRange',
        key: 'countRange',
        width: 250,
        render: (count: number) => formatNumber(count),
      },
      ...columns.slice(1),
    ];
  }

  return (
    <Container>
      <Table<Entry>
        loading={isLoading}
        dataSource={totalLoadsWithoutTotal}
        columns={columnsFinal}
        bordered
        pagination={false}
        rowKey={(record) => `${record.type}-${record.eventId}`}
        summary={() => (
          <Table.Summary.Row>
            <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
            {zoomStartDate && zoomEndDate && (
              <Table.Summary.Cell index={2} align="center">
                {formatNumber(total?.countRange)}
              </Table.Summary.Cell>
            )}
            <Table.Summary.Cell index={1} align="center">
              {formatNumber(total?.count)}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )}
      />
    </Container>
  );
};

const Container = styled.div` 
  padding: 10px 16px;

  .ant-table-tbody tr td:not(:first-of-type) {
    text-align: center;
  }

  .ant-table-summary tr td {
    border-top: 2px solid ${RacemapColors.LightGray};
    font-weight: bold;
  }
`;

type TotalLoads = Record<'total' | string, number>;
type Entry = {
  type: EventLoadTypes | 'total';
  eventName: string;
  eventId: string;
  count: number;
  countRange?: number;
};

const getColumns = ({
  eventNameMapping,
  eventType,
}: {
  eventNameMapping?: Record<string, string> | undefined;
  eventType?: EventTypes;
}): NonNullable<TableProps<Entry>['columns']> => [
  {
    title: getTypeColumnTitle(eventType),
    dataIndex: 'type',
    key: 'type',
    render: (type: EventLoadTypes, { eventName, eventId }) => (
      <LoadLabel
        loadType={type}
        eventName={eventName}
        color={getSeriesColor(type, eventId, eventNameMapping)}
      />
    ),
  },
  {
    title: 'Loads',
    dataIndex: 'count',
    key: 'count',
    width: 250,
    align: 'center',
    render: (count: number) => formatNumber(count),
  },
];

const getTypeColumnTitle = (eventType: EventTypes | undefined): string => {
  switch (eventType) {
    case EventTypes.CONTEST_GROUP:
      return 'Type, Contest';
    case EventTypes.STAGE_GROUP:
      return 'Type, Stage';
    default:
      return 'Type';
  }
};

const getTotalLoads = (
  loads?: DailyLoads,
  zoomStartDate?: Date | null,
  zoomEndDate?: Date | null,
  eventNameMapping?: Record<string, string>,
): Array<Entry> => {
  const totalLoads: Partial<TotalLoads> = {};
  const totalLoadsRange: Partial<TotalLoads> = {};
  if (loads == null) return [];

  for (const load of loads) {
    const type = 'type' in load && load.type != null ? load.type : EventLoadTypes.MAP_LOADS;
    const key = `${type};${load.eventId}`;
    totalLoads[key] = (totalLoads[key] || 0) + load.count;
    totalLoads.total = (totalLoads.total || 0) + load.count;

    if (zoomStartDate && zoomEndDate) {
      const time = DateTime.fromJSDate(load.time);
      if (time > DateTime.fromJSDate(zoomStartDate) && time < DateTime.fromJSDate(zoomEndDate)) {
        totalLoadsRange[key] = (totalLoadsRange[key] || 0) + load.count;
        totalLoadsRange.total = (totalLoadsRange.total || 0) + load.count;
      }
    }
  }

  return Object.entries(totalLoads).map(([key, count]) => {
    const type = key.split(';')[0] as EventLoadTypes | 'total';
    const eventId = key.split(';')[1];
    const eventName = eventNameMapping?.[eventId] || '';

    return {
      type,
      eventName,
      eventId,
      count: count || 0,
      countRange: totalLoadsRange[key],
    };
  });
};

const LoadLabel: FC<{ loadType: EventLoadTypes; eventName?: string; color: string }> = ({
  loadType,
  eventName,
  color,
}) => (
  <Space>
    <IconSquare color={color} />
    {loadTypeLabels[loadType]}
    {eventName && `${eventName}`}
  </Space>
);
