import {
  Binary as BSONBinary,
  ObjectId as BSONObjectId,
  EJSON,
  deserialize,
  serialize,
} from 'bson';
import { z } from 'zod';

export const bsonSerialize = serialize;
export const bsonDeserialize = deserialize;
export const ejsonStringify = EJSON.stringify;
export const ejsonParse = EJSON.parse;

const ISO_DATETIME_REGEX =
  /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;

export const ISODateStringSchema = z
  .union([z.string(), z.date(), z.number()])
  .transform((d) => {
    if (d instanceof Date) return d.toISOString();
    if (typeof d === 'number') return new Date(d).toISOString();
    return d;
  })
  .refine((d) => ISO_DATETIME_REGEX.test(d), {
    message: 'Invalid ISO Date String.',
  });

export const ObjectIdHexStringSchema = z
  .string()
  .length(24)
  .refine(BSONObjectId.isValid, {
    message: 'Invalid object id. Has to be hex string with 24 chars.',
  })
  .describe('12 byte Object ID as Hex String.');
export type ObjectIdHexString = z.infer<typeof ObjectIdHexStringSchema>;

export const ObjectIdSchema = z
  .union([
    ObjectIdHexStringSchema,
    z.instanceof(Object),
    z.object({ _bsontype: z.literal('ObjectID'), id: z.string() }),
  ])
  .refine(
    (oId) => {
      if (oId instanceof BSONObjectId) return true;
      if (typeof oId === 'object' && '_bsontype' in oId && oId._bsontype === 'ObjectID')
        return BSONObjectId.isValid(oId.id);

      return BSONObjectId.isValid(oId.toString());
    },
    {
      message: 'Invalid object id.',
    },
  )
  .transform((oId) => {
    if (oId instanceof BSONObjectId) return oId;
    if (typeof oId === 'object' && '_bsontype' in oId && oId._bsontype === 'ObjectID')
      return new BSONObjectId(oId.id);

    return new BSONObjectId(oId.toString());
  })
  .describe('12 byte Object ID.');
export type ObjectId = z.infer<typeof ObjectIdSchema>;
export const ObjectId = BSONObjectId;
export const Binary = BSONBinary;

export const DateSchema = z
  .union([z.date(), z.string(), z.number()])
  .transform((d) => {
    if (d instanceof Date) return d;
    return new Date(d);
  })
  .describe('Date object')
  .refine((d) => {
    if (d instanceof Date) return !Number.isNaN(d.getTime());
    return !Number.isNaN(new Date(d).getTime());
  });

export type Date = z.infer<typeof DateSchema>;

export const BufferSchema = z
  .union([
    z.string(),
    z.instanceof(Buffer),
    z.instanceof(Binary),
    z.array(z.number()),
    // TODO: remove if buffer comes as string
    z.object({ type: z.string(), data: z.array(z.number()) }),
  ])
  .transform((b) => {
    if (b instanceof Buffer) return b;
    if (b instanceof Binary) return Buffer.from(b.buffer);
    if (Array.isArray(b)) return Buffer.from(b);
    if (typeof b === 'string') return Buffer.from(b, 'base64');
    if (typeof b === 'object') return Buffer.from(b.data);
    return Buffer.from(b);
  });

export type Buffer = z.infer<typeof BufferSchema>;
