import {
  type EditMode,
  EditModeTypes,
  GeoElementTypes,
  RacemapGeoTypes,
} from '@racemap/utilities/types/geos';

export const SimpleSelect: EditMode = {
  type: EditModeTypes.SimpleSelect,
  drawMode: 'simple_select',
};

export const DirectSelect: EditMode = {
  type: EditModeTypes.DirectSelect,
  drawMode: 'direct_select',
};

export const AddTrack: EditMode = {
  type: EditModeTypes.AddTrack,
  drawMode: 'draw_line_string',
  targetGeoType: GeoElementTypes.LineString,
  targetElementType: RacemapGeoTypes.TRACK,
};

export const AddPoi: EditMode = {
  type: EditModeTypes.AddPoi,
  drawMode: 'draw_point',
  targetGeoType: GeoElementTypes.Point,
  targetElementType: RacemapGeoTypes.POI,
};

export const AddPoiOnTrack: EditMode = {
  type: EditModeTypes.AddPoiOnTrack,
  drawMode: 'point_on_line_string',
  targetGeoType: GeoElementTypes.Point,
  targetElementType: RacemapGeoTypes.POI,
};

export const AddSplit: EditMode = {
  type: EditModeTypes.AddSplit,
  drawMode: 'point_on_line_string',
  targetGeoType: GeoElementTypes.Point,
  targetElementType: RacemapGeoTypes.SPLIT,
};

export const ModifySplit: EditMode = {
  type: EditModeTypes.ModifySplit,
  drawMode: 'point_on_line_string',
  targetGeoType: GeoElementTypes.Point,
  targetElementType: RacemapGeoTypes.SPLIT,
};

export const ExpandTrack: EditMode = {
  type: EditModeTypes.ExpandTrack,
  drawMode: 'expand_line_string',
  targetGeoType: GeoElementTypes.LineString,
  targetElementType: RacemapGeoTypes.TRACK,
};
