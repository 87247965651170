import styled from '@emotion/styled';
import { ObjectId } from '@racemap/sdk/schema/base';
import { UserOverviewSchema } from '@racemap/sdk/schema/user';
import { RacemapAPIClient } from '@racemap/utilities/api-client';
import { isEmptyString } from '@racemap/utilities/functions/utils';
import { isValidEmail } from '@racemap/utilities/functions/validation';
import { Select as ASelect, Space, Typography } from 'antd';
import type { BaseOptionType } from 'antd/es/select';
import { type FC, type ReactNode, useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import { useControlled, useUserInfo } from '../../../lib/customHooks';
import { IconPlus, IconRemove } from '../../Icon';
import { Plg } from '../Placeholder';
import { UserInfos } from './UserInfos';

const { Text } = Typography;

export interface UserInfo extends BaseOptionType {
  email: string;
  name?: string;
  id: ObjectId;
}

type Props = {
  value?: ObjectId | null;
  onChange?: (user: ObjectId | null) => void;
  placeholder?: string;
  disabled?: boolean;
  allowClear?: boolean;
  clearAfterChoosing?: boolean;
  filterUserIds?: ObjectId[];
  action?: 'add' | 'remove' | 'search';
};

export const UserChooser: FC<Props> = ({
  value,
  onChange,
  disabled = false,
  allowClear = true,
  clearAfterChoosing = false,
  action = 'search',
  filterUserIds = [],
  placeholder = 'Search for email or name...',
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [valueControlled, setValue] = useControlled<ObjectId | null>({
    controlledValue: value,
    initialValue: null,
    onChange,
    name: 'UserChooser',
  });
  const [selectedUserId, setSelectedUserId] = useState<ObjectId | null>(null);
  const { data: users, isLoading } = useSWR(['searchUsers', searchValue], loadUsers, {
    keepPreviousData: true,
    fallbackData: [],
  });

  useEffect(() => {
    if (valueControlled == null) return;
    setSelectedUserId(valueControlled);
  }, [valueControlled]);

  const handleOnChange = async (value: ObjectId | string | null) => {
    if (value == null) {
      setValue(null);
      return;
    }

    // handle that choosed option holds only a valid email
    if (isValidEmail(value)) {
      const user = await apiClient.userLookup({ email: value });
      if (user) {
        setValue(user.id);
        setSelectedUserId(user.id);
      }
      return;
    }
    setValue(new ObjectId(value));

    if (clearAfterChoosing) {
      setValue(null);
    } else {
      setSelectedUserId(new ObjectId(value));
    }
  };

  // if showSelf false, we filter out the current user
  const usersFiltered = users.filter((u) => !filterUserIds.some((fU) => fU.equals(u.id)));

  // if we found no user but the search value is a valid email, we add it to the options
  const options =
    usersFiltered.length === 0 && isValidEmail(searchValue)
      ? [{ value: searchValue, label: '', email: searchValue, name: '', id: null }]
      : usersFiltered;

  return (
    <Select
      placeholder={placeholder}
      disabled={disabled}
      allowClear={allowClear}
      showSearch
      searchValue={searchValue}
      onSearch={setSearchValue}
      value={valueControlled?.toHexString() || null}
      // @ts-expect-error wrong lib types
      onChange={handleOnChange}
      loading={isLoading}
      filterOption={false}
      options={options}
      // @ts-expect-error wrong lib types
      optionRender={OptionComponent}
      labelRender={() => <ValueComponent userId={selectedUserId} />}
      suffixIcon={getSuffixIcon(action)}
    />
  );
};

const getSuffixIcon = (action: 'add' | 'remove' | 'search') => {
  switch (action) {
    case 'add':
      return <IconPlus />;
    case 'remove':
      return <IconRemove />;
    case 'search':
      return false;
  }
};

const apiClient = RacemapAPIClient.fromWindowLocation();
const loadUsers = async ([, query]: [string, string]): Promise<Array<UserInfo>> => {
  const queryTrimmed = query.trim().toLowerCase();
  const users = (
    await apiClient.getUsers({
      search: queryTrimmed,
      limit: 4,
    })
  ).users.map((u) => UserOverviewSchema.parse(u));

  // update cache user to prevent user info component from fetching the same user
  await Promise.all(
    users.map((u) =>
      mutate(['GET', 'USER_INFO', u.id.toHexString()], u, {
        populateCache: true,
        revalidate: false,
      }),
    ),
  );

  return users.map((u) => ({
    value: u.id.toHexString(),
    label: u.name || u.email || u.id.toHexString(),
    ...u,
  }));
};

const OptionComponent = ({ data }: { data: UserInfo }): ReactNode => {
  if (!('email' in data) || isEmptyString(data.email)) return null;

  return <UserInfos userId={data.id} email={data.email} />;
};

const ValueComponent = ({ userId }: { userId: ObjectId | null }) => {
  const { error, user, isLoading } = useUserInfo(userId);

  // if user is not loaded yet, show placeholder
  if (isLoading && user === undefined)
    return (
      <Space>
        <Plg w="150px" /> <Plg w="200px" />{' '}
      </Space>
    );
  if (user == null) return null;
  if (error) return <Text type="danger">Error</Text>;

  return (
    <Space title={`${user.name}\n${user.email}`}>
      <strong>{user.name}</strong>
      <Text>{user.email}</Text>
    </Space>
  );
};

const Select = styled(ASelect)`
  width: 100%
`;
