import React, { type FC, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';

export const AdminRootView: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (/^\/admin\/?$/.test(location.pathname)) {
      navigate('/admin/events');
    }
  }, [location]);

  return <Outlet />;
};
