import type { MapType } from '@racemap/utilities/consts/events';
import { isEditableGeoElement } from '@racemap/utilities/functions/validation';
import type { EditMode } from '@racemap/utilities/types/geos';
import { type Immutable, produce } from 'immer';
import type { ViewState } from 'react-map-gl';
import type { StoreApi } from 'zustand';
import type { Selection } from '../../components/BasicComponents/Maps/Editor';
import { fitViewStateToBounds, getBounds, getDefaultViewState } from '../../lib/map-helpers';
import type { CurrentEvent } from '../events/events_reducers';
import type { DraftState, State } from '../reducers';
import { SimpleSelect } from './editModes';

export interface MapContext {
  mapId: string;
  viewState: ViewState;
  selection: Selection;
  options: Options;
}

type Options = {
  mapStyle: MapType;
  editMode: EditMode;
};

export type MapsState = {
  maps: {
    ctxs: Map<string, MapContext>;
    currentMapId: null | string;
    getter: {
      currentMapContext: () => Immutable<MapContext> | null;
    };
    actions: {
      setCurrentMapId: (mapId: string | null) => void;
      createMapContext: (mapId: string, event: Immutable<CurrentEvent>) => void;
      updateViewState: (mapId: string, newViewport: ViewState) => void;
      updateOptions: (mapId: string, newOptions: Options) => void;
      updateOneOption: (mapId: string, optionName: keyof Options, newValue: any) => void;
      changeEditMode: (mapId: string, editMode: EditMode) => void;
      cancelMode: (mapId: string, selecedFeatureIndex: Array<number>) => void;
      updateSelection: (mapId: string, newSelection: Immutable<Selection>) => void;
      updateOneSelection: (mapId: string, selectionName: keyof Selection, newValue: any) => void;
    };
  };
};

export const createMapStore = (
  set: StoreApi<State>['setState'],
  get: StoreApi<State>['getState'],
): Immutable<MapsState> => ({
  maps: {
    ctxs: new Map(),
    currentMapId: null,
    getter: {
      currentMapContext: () => {
        const mapId = get().maps.currentMapId;
        if (mapId == null) return null;

        return get().maps.ctxs.get(mapId) || null;
      },
    },
    actions: {
      setCurrentMapId: (mapId) => {
        set(
          produce((s: DraftState) => {
            s.maps.currentMapId = mapId;
          }),
        );
      },
      createMapContext: (mapId, event) => {
        const features = event.geo.features?.filter(isEditableGeoElement) || [];
        const bounds = getBounds(features);

        const defaultViewState = {
          ...getDefaultViewState(true),
          ...event.playerOptions.viewport,
        };
        const viewState = fitViewStateToBounds({
          bounds,
          baseViewState: defaultViewState,
        });

        set(
          produce((s: DraftState) => {
            s.maps.ctxs.set(mapId, {
              mapId,
              selection: { featureIds: [] },
              options: {
                editMode: SimpleSelect,
                mapStyle: event.playerOptions.mapType,
              },
              viewState,
            });
          }),
        );
      },
      updateViewState: (mapId, newViewStat) =>
        set(
          produce((state: DraftState) => {
            const ctx = state.maps.ctxs.get(mapId);
            if (ctx == null) return;

            ctx.viewState = newViewStat;
          }),
        ),
      updateOptions: (mapId, newOptions) =>
        set(
          produce((state: DraftState) => {
            const ctx = state.maps.ctxs.get(mapId);
            if (ctx == null) return;

            ctx.options = newOptions;
          }),
        ),
      updateOneOption: (mapId: string, optionName: keyof Options, newValue: any) =>
        set(
          produce((state: DraftState) => {
            const ctx = state.maps.ctxs.get(mapId);
            if (ctx == null) return;

            ctx.options = {
              ...ctx.options,
              [optionName]: newValue,
            };
          }),
        ),
      changeEditMode: (mapId, editMode) =>
        set(
          produce((state: DraftState) => {
            const ctx = state.maps.ctxs.get(mapId);
            if (ctx == null) return;

            ctx.options.editMode = editMode;
          }),
        ),
      cancelMode: (mapId) => get().maps.actions.changeEditMode(mapId, SimpleSelect),
      updateSelection: (mapId, newSelection) =>
        set(
          produce((state: DraftState) => {
            const ctx = state.maps.ctxs.get(mapId);
            if (ctx == null) return;

            ctx.selection = Object.assign(newSelection);
          }),
        ),
      updateOneSelection: (mapId, selectionName, newValue) =>
        set(
          produce((state: DraftState) => {
            const ctx = state.maps.ctxs.get(mapId);
            if (ctx == null) return;

            ctx.selection[selectionName] = newValue;
          }),
        ),
    },
  },
});
