import CheckmarkAnimationUrl from '@assets/checkmark-animation.gif';
import { VisibilityStates } from '@racemap/utilities/consts/events';
import { Button, Typography } from 'antd';
import type { FC } from 'react';
import { useCurrentEvent } from '../../../lib/customHooks';
import { useStore } from '../../../store/reducers';
import VisibilitySection from '../../VisibilitySection';

const { Title } = Typography;

export const DoneView: FC = () => {
  const currentEvent = useCurrentEvent();
  const { updateEvent } = useStore((s) => s.events.actions);

  if (currentEvent == null) return <></>;
  return (
    <div style={{ textAlign: 'center' }}>
      <img
        src={CheckmarkAnimationUrl}
        alt="Checkmark animation"
        style={{ width: 200, height: 200 }}
      />
      <p>
        <Title level={4}>Select the visibility of your Tracking Map</Title>
      </p>
      <VisibilitySection
        value={currentEvent.visibility}
        onChange={(newValue) => {
          updateEvent(currentEvent.id, [{ key: 'visibility', newValue }]);
        }}
        authorized={true}
        possibleStates={Object.values(VisibilityStates).filter(
          (e) => e !== VisibilityStates.PUBLIC && e !== VisibilityStates.ARCHIVED,
        )}
      />
    </div>
  );
};

interface FooterProps {
  onClose: () => void;
}

export const DoneViewFooter: FC<FooterProps> = ({ onClose }) => {
  return <Button onClick={onClose}>Close</Button>;
};
