import { EventTypes } from '@racemap/utilities/consts/events';
import { compareNumber } from '@racemap/utilities/functions/compareNumber';
import type { RacemapEvent } from '@racemap/utilities/types/events';
import type { Immutable } from 'immer';
import { useCurrentEvent } from '../lib/customHooks';
import { useStore } from '../store/reducers';
import { EventList } from './EventList';

export const GroupEventList: React.FC = () => {
  const contestGroup = useCurrentEvent();
  const groupEvents = useStore((s) =>
    Array.from(s.events.items.values()).filter((e) => e.parent === contestGroup?.id),
  ) as Immutable<Array<RacemapEvent>>;
  const { loadChildEvents, updateEvent } = useStore((s) => s.events.actions);
  const groupEventsSorted = [...groupEvents].sort((eA, eB) => compareNumber(eA.index, eB.index));

  if (contestGroup == null) return null;

  const handleEventRemove = async (event: Immutable<RacemapEvent>) => {
    await updateEvent(event.id, [
      {
        key: 'parent',
        newValue: null,
      },
      {
        key: 'index',
        newValue: null,
      },
      {
        key: 'type',
        newValue: EventTypes.REGULAR,
      },
    ]);
    await loadChildEvents(contestGroup.id);
  };

  const handleSwapEvents = async (e1: Immutable<RacemapEvent>, e2: Immutable<RacemapEvent>) => {
    const edx1 = groupEventsSorted.findIndex((e) => e.id === e1.id);
    const edx2 = groupEventsSorted.findIndex((e) => e.id === e2.id);
    if (edx1 === -1 || edx2 === -1) return;

    await updateEvent(e1.id, [{ key: 'index', newValue: edx2 }]);
    await updateEvent(e2.id, [{ key: 'index', newValue: edx1 }]);
  };

  return (
    <EventList
      events={groupEventsSorted}
      onSwapEvents={handleSwapEvents}
      onEventRemove={handleEventRemove}
    />
  );
};
