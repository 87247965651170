import { z } from 'zod';
import { StripeProducts } from './StripeProducts';

export const TotalUsagesSchema = z.record(
  z.nativeEnum(StripeProducts),
  z.object({
    quantity: z.number(),
    amount: z.number().optional(),
    amountTax: z.number().optional(),
    taxBehavior: z.enum(['exclusive', 'inclusive']).optional(),
  }),
);

export type TotalUsages = z.infer<typeof TotalUsagesSchema>;
