import { z } from 'zod';
import { DateSchema, ObjectIdSchema } from './base';

export enum EventTypes {
  REGULAR = 'REGULAR',
  CONTEST = 'CONTEST',
  CONTEST_GROUP = 'CONTEST_GROUP',
  STAGE = 'STAGE',
  STAGE_GROUP = 'STAGE_GROUP',
}

export const EventOverviewSchema = z.object({
  id: ObjectIdSchema,
  name: z.string(),
  startTime: DateSchema,
  endTime: DateSchema,
  isRepeating: z.boolean(),
  isPredictive: z.boolean(),
  updatedAt: DateSchema,
  geo: z.object({
    shadowgeojsonHash: z.string().optional(),
  }),
  type: z.nativeEnum(EventTypes),
});

export type EventOverview = z.infer<typeof EventOverviewSchema>;

export const EventOverviewListSchema = z.array(EventOverviewSchema);

export type EventOverviewList = z.infer<typeof EventOverviewListSchema>;

export enum EventLoadTypes {
  MAP_LOADS = 'MAP',
  TIMING_LOADS = 'TIMING',
  MONITOR_LOADS = 'MONITOR',
  CURRENT_LOADS = 'CURRENT_API',
  TIMES_LOADS = 'TIMES_API',
  TIMES_ID_LOADS = 'TIMES_ID_API',
  RANKS_LOADS = 'RANKS_API',
  DISTANCE_LOADS = 'DISTANCE_API',
}
