import type { FC } from 'react';
import { MiniBadge } from './MiniBadge';

export const ResellerBadge: FC = () => {
  return (
    <MiniBadge color="cyan" bordered={false}>
      Reseller
    </MiniBadge>
  );
};
