import type Stripe from 'stripe';

/**
 * Calculates the quantity of products based on the package size and the desired quantity.
 *
 * @param {Stripe.Price} productPrice - The price object from Stripe, which includes metadata about the package size.
 * @param {number} quantity - The desired quantity of the product.
 * @returns {number} - The calculated quantity, adjusted for the package size.
 */
export function calculateQuantity(productPrice: Stripe.Price, quantity: number) {
  const packageSize = Number.parseInt(productPrice.metadata.packageSize, 10) || 1;
  const quantityFactor = 1 / packageSize;
  const calculationQuantity = Math.ceil(quantity * quantityFactor);
  return calculationQuantity;
}
