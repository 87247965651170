import { RacemapColors } from '@racemap/utilities/consts/common';
import type {
  EChartsOption,
  MarkLineComponentOption,
  TooltipComponentFormatterCallbackParams,
} from 'echarts';
import EChartsReact from 'echarts-for-react';
import { type Immutable, castDraft, produce } from 'immer';
import React, { type FC } from 'react';

export type EntryTooltipFormatterCallback = (entryData: {
  value: number;
  start: number;
  end: number;
  label: string;
  marklines: Marklines;
  meta: Record<string, string>;
}) => string;
export type MarklineTooltipFormatterCallback = (marklineData: {
  value: number;
  label: string;
}) => string;
export interface Entry {
  value: number;
  label: string;
  tooltipFormatter?: EntryTooltipFormatterCallback;
  meta?: Record<string, string>;
}
export interface Markline {
  value: number;
  label: string;
  showLabel?: boolean;
  lineStyle?: MarkLineComponentOption['lineStyle'];
  tooltipFormatter?: MarklineTooltipFormatterCallback;
}
type Data = Immutable<Array<Entry>>;
type Marklines = Immutable<Array<Markline>>;
interface Props {
  data: Data;
  marklines: Marklines;
  title?: string;
}

export const RelationBarChart: FC<Props> = ({ data, marklines, title = '0' }) => {
  const option = getOption(title, data, marklines);

  return <EChartsReact option={option} style={{ height: '45px' }} />;
};

const getOption = (title: string, data: Data, marklines: Marklines): Immutable<EChartsOption> => {
  const option: Immutable<EChartsOption> = {
    legend: {
      show: false,
    },
    animation: false,
    grid: {
      height: '10px',
      left: 70,
      right: 25,
      top: 7,
      bottom: 30,
      containLabel: false,
    },
    xAxis: {
      type: 'value',
      max: 0,
      axisLabel: {
        show: false,
      },
    },
    yAxis: {
      type: 'category',
      data: [title],
      show: true,
      axisTick: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: 'white',
        },
      },
      axisLabel: {
        margin: 15,
      },
    },
    tooltip: {
      trigger: 'item',
    },
    series: [],
  };

  return produce(option, (draft) => {
    let count = 0;
    for (let index = 0; index < data.length; index++) {
      const entry = data[index];
      if (draft.series == null) draft.series = [];
      if (!Array.isArray(draft.series)) continue;

      const startValue = count;
      const endValue = count + entry.value;
      const belongingMarklines = marklines
        .filter((mL) => mL.value >= Math.floor(startValue) && mL.value <= Math.floor(endValue))
        .sort((a, b) => a.value - b.value);

      draft.series.push({
        name: entry.label,
        id: index,
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          formatter: '{a}',
          textShadowBlur: 1,
          textShadowColor: 'white',
          fontSize: 13,
          fontWeight: 400,
        },
        emphasis: {
          focus: 'series',
        },
        color: RacemapColors.CloudBlue,
        data: [entry.value],
        tooltip: {
          formatter: (params: TooltipComponentFormatterCallbackParams): string => {
            if (Array.isArray(params) || params.componentType !== 'series') return '';
            const { componentType, seriesIndex } = params;
            if (componentType !== 'series' || seriesIndex == null) return '';
            const start = startValue;
            const end = endValue;
            const entry = data[seriesIndex];

            return (
              entry.tooltipFormatter?.({
                value: entry.value,
                start,
                end,
                label: entry.label,
                marklines: belongingMarklines,
                meta: entry.meta || {},
              }) || ''
            );
          },
        },
        markLine:
          belongingMarklines.length > 0
            ? {
                symbol: 'none',
                label: { formatter: '{b}', color: 'white', position: 'start' },
                animation: false,
                lineStyle: {
                  color: RacemapColors.PaleBlue,
                  type: 'solid',
                  width: 2,
                  opacity: 0.8,
                },
                data: belongingMarklines.map((mL) => ({
                  xAxis: Math.floor(mL.value),
                  name: mL.label,
                  label: { show: mL.showLabel },
                  lineStyle: castDraft(mL.lineStyle),
                  tooltip: {
                    formatter: (params: TooltipComponentFormatterCallbackParams): string => {
                      if (Array.isArray(params)) return '';

                      return (
                        mL.tooltipFormatter?.({
                          value: mL.value,
                          label: mL.label,
                        }) || ''
                      );
                    },
                  },
                })),
              }
            : undefined,
      });

      count += entry.value;
    }

    if (draft.xAxis != null && !Array.isArray(draft.xAxis)) {
      draft.xAxis.max = count;
    }
  });
};
