import type { FC } from 'react';
import { Col, Form } from 'react-bootstrap';

type Props = {
  value: number;
  onChange: (count: number) => void;
  max?: number;
  min?: number;
  label: React.ReactNode;
};

export const InputCountForm: FC<Props> = ({ value, onChange, label, max, min }) => {
  return (
    <>
      <Col sm={1} xs={4} style={{ marginBottom: '1rem' }}>
        <Form.Control
          type="number"
          value={value}
          onChange={({ target }) => {
            onChange(Number.parseInt(target.value, 10));
          }}
          max={max}
          min={min || 0}
          style={{ width: '60px' }}
        />
      </Col>
      <Col sm={4} xs={8} className="cost-calculator-label" style={{ marginLeft: '1%' }}>
        {label}
      </Col>
    </>
  );
};
