import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import type { FC } from 'react';
import { IconMinus, IconOk } from '../../Icon';
import { Center } from '../MetaComponent';

export enum CheckboxValues {
  NOT_CHECKED = 0,
  CHECKED = 1,
  PARTIAL_CHECKED = 2,
}

interface Props {
  value: CheckboxValues | boolean;
  onChange: (newValue: boolean) => void;
  size?: number;
}

export const Checkbox: FC<Props> = ({ value, onChange, size = 17 }) => {
  const isChecked =
    value === true || value === CheckboxValues.CHECKED || value === CheckboxValues.PARTIAL_CHECKED;
  let icon: React.ReactNode = null;
  if (value === true || value === CheckboxValues.CHECKED) {
    icon = <IconOk fixedWidth fontSize={'10px'} color={RacemapColors.PaleBlue} />;
  } else if (value === CheckboxValues.PARTIAL_CHECKED) {
    icon = <IconMinus fixedWidth fontSize={'12px'} color={RacemapColors.DarkGray} />;
  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    onChange(!isChecked);
  };
  return (
    <Container onClick={handleClick} className="rm-checkbox">
      <Box checked={isChecked} className="box" size={size}>
        {icon}
      </Box>
    </Container>
  );
};

const Container = styled(Center)`
  cursor: pointer;
  z-index: 1;

  :hover {
    .box {
      border-width: 3px;
    }
  }
`;

const Box = styled(Center)<{ checked: boolean; size?: number }>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border: solid 2px ${RacemapColors.Gray};
  border-radius: 3px;
  ${({ checked }) => (checked ? `background-color: ${RacemapColors.CloudBlue};` : '')}
  ${({ checked }) => (checked ? `border-color: ${RacemapColors.PaleBlue};` : '')}

  transition: background-color 300ms ease, border-color 300ms ease;

  svg {
    opacity: ${({ checked }) => (checked ? 1 : 0)};

    transition: opacity 300ms ease;
  }
`;
