import { z } from 'zod';
import { EventLoadTypes } from '../events';
import { BillableItemTypes } from '../user';
import { PRICING_VERSIONS } from './PRICING_VERSIONS';
import { StripeProducts } from './StripeProducts';

const EventStatisticSchema = z.object({
  eventId: z.string(),
  eventName: z.string(),
  billableNow: z.record(z.nativeEnum(StripeProducts), z.number().optional()),
  totalCounts: z.record(z.nativeEnum(StripeProducts), z.number().optional()),
  pricingVersion: z.nativeEnum(PRICING_VERSIONS),
  loadCounts: z.record(z.nativeEnum(EventLoadTypes), z.number()),
  freeItemCounts: z.record(z.nativeEnum(BillableItemTypes), z.number()),
});
export type EventStatistic = z.infer<typeof EventStatisticSchema>;

export const EventStatisticsSchema = z.array(EventStatisticSchema);
