import { DateTime } from 'luxon';
import { RacemapColors } from '../../consts/common';
import { DeviceClasses } from '../../consts/events';
import { DEFAULT_KEY_LENGTH } from '../../functions/random';
import type { GenericImportStarterRecord, ImportStarterCheckResult } from '../../types/events';

export const validateStarterImportRecord = (
  starter: GenericImportStarterRecord,
  keys: Set<string> = new Set(),
  stopOnError = false,
): ImportStarterCheckResult | null => {
  const result: ImportStarterCheckResult = {
    importId: starter.importId,
    name: starter.name || '',
    errors: [],
  };

  // we always expect an inportId
  if (starter.importId == null || starter.importId === '') {
    result.errors.push('The importId must not be empty.');
    if (stopOnError) return result;
  } else {
    if (starter.importId.toString().length < 1) {
      result.errors.push('The importId has to have 1 character at least.');
    }
  }

  // we expect at least a name or a startNumber
  if (
    (starter.name == null || starter.name === '') &&
    (starter.startNumber == null || starter.startNumber === '')
  ) {
    result.errors.push('At least name or startNumber has to have a value.');
    if (stopOnError) return result;
  }

  // if there is a key it has to have the right length and has to exist
  if (starter.key != null) {
    if (typeof starter.key !== 'string') {
      result.errors.push('A key has to be a string.');
      if (stopOnError) return result;
    }
    if (starter.key.toString().length !== DEFAULT_KEY_LENGTH) {
      result.errors.push(`A key has to have the default length of ${DEFAULT_KEY_LENGTH}`);
      if (stopOnError) return result;
    } else {
      if (!keys.has(starter.key)) {
        result.errors.push(`The key ${starter.key} does not exist.`);
        if (stopOnError) return result;
      }
    }
  }

  // if there is a startTime we expect it to be in ISOFormat with millis
  if (starter.startTime != null) {
    if (!DateTime.fromISO(starter.startTime).isValid) {
      result.errors.push(
        'The startTime has to be UTC matching the ISOString format i.e. 2019-11-15T13:34:22.123Z',
      );
      if (stopOnError) return result;
    }
  }

  // if there is a endTime we expect it to be in ISOFormat with millis
  if (starter.endTime != null) {
    if (!DateTime.fromISO(starter.endTime).isValid) {
      result.errors.push(
        'The endTime has to be UTC matching the ISOString format i.e. 2019-11-15T13:34:22.123Z',
      );
      if (stopOnError) return result;
    }
  }

  // if there are any other times we expect each of it to match the ISOFormat with millis
  if (starter.times != null) {
    for (const [timeType, timeValue] of Object.entries(starter.times)) {
      if (!DateTime.fromISO(timeValue).isValid) {
        result.errors.push(
          `The ${timeType}-Time has to be UTC matching the ISOString format i.e. 2019-11-15T13:34:22.123Z`,
        );
        if (stopOnError) return result;
      }
    }
  }

  // if there is a color it has to be in html hex notation
  if (starter.markerColor != null) {
    if (!/^#[0-9a-f]{3}([0-9a-f]{3})?$/i.test(starter.markerColor)) {
      result.errors.push(
        `The markerColor has to match the HTML color pattern ${RacemapColors.CloudBlue}`,
      );
      if (stopOnError) return result;
    }
  }

  // if there are devices we have to check each of it
  if (starter.devices != null && Array.isArray(starter.devices)) {
    for (const device of starter.devices) {
      const errors = validateDevice(device, stopOnError);
      if (errors.length > 0) {
        result.errors.push(...errors);
        if (stopOnError) return result;
      }
    }
  } else if (
    starter.deviceId != null &&
    starter.deviceType != null &&
    starter.deviceClass != null
  ) {
    const errors = validateDevice(
      {
        id: starter.deviceId,
        type: starter.deviceType,
        class: starter.deviceClass,
      },
      stopOnError,
    );
    if (errors.length > 0) {
      result.errors.push(...errors);
      if (stopOnError) return result;
    }
  } else if (starter.devices != null && !Array.isArray(starter.devices)) {
    result.errors.push('The devices property has to be a list (array).');
    if (stopOnError) return result;
  }

  if (starter.tags != null) {
    if (typeof starter.tags !== 'object') {
      result.errors.push('The tags have to be a object with non-empty strings as values.');
    }
  }

  for (const [key, value] of Object.entries(starter.tags || {})) {
    if (key.startsWith('times.')) {
      if (typeof value !== 'string' || !DateTime.fromISO(value).isValid) {
        result.errors.push(
          `The ${key}-Time has to be UTC matching the ISOString format i.e. 2019-11-15T13:34:22.123Z`,
        );
        if (stopOnError) return result;
      }
    }

    if (key.startsWith('tags.')) {
      if (typeof value !== 'string' || value === '') {
        result.errors.push(`The ${key} has to be a string.`);
        if (stopOnError) return result;
      }
    }
  }

  if (result.errors.length > 0) return result;
  return null;
};

type Device = {
  id: GenericImportStarterRecord['deviceId'];
  type: GenericImportStarterRecord['deviceType'];
  class: GenericImportStarterRecord['deviceClass'];
};

function validateDevice(device: Device, stopOnError: boolean): Array<string> {
  const errors: Array<string> = [];
  if (device.id == null || device.type == null || device.class == null) {
    errors.push('Each device has to have the properties (id, type, class)');
    if (stopOnError) return errors;
  }
  if (
    device.class != null &&
    !Object.values(DeviceClasses).includes(device.class.toString() as DeviceClasses)
  ) {
    errors.push(
      `Property class of a device can only have one of this values (${Object.values(
        DeviceClasses,
      ).join(', ')})`,
    );
    if (stopOnError) return errors;
  }
  return errors;
}
