import type { HydratedDocument } from 'mongoose';
import { z } from 'zod';
import { DateSchema, ObjectIdSchema } from './base';

export enum TOSVersions {
  NONE = 'NONE',
  '2019-10' = '2019-10',
}

export enum BillableItemTypes {
  BASE_PRICE = 'basePrice',
  ACTIVATE_EVENT = 'activateEvent',
  SPONSOR = 'sponsor',
  KEY = 'key',
  TIMING = 'timing',
  MONITOR = 'monitor',
  DATA_FEED = 'dataFeed',
  STARTER = 'starter',
  GPS_DEVICE = 'gpsDevice',
  TRANSPONDER = 'transponder',
  PAGE_VIEW = 'pageView',
  SIM_CARD = 'simCard',
  APP = 'app',
  TRACKER_MANAGEMENT = 'trackerManagement',
  SMS = 'sms',
  EVENT_CYCLE = 'eventCycle',
  EVENT_DAY = 'eventDay',
  DATA_USAGE_ZONE_1 = 'dataUsageZone1',
  DATA_USAGE_ZONE_2 = 'dataUsageZone2',
  DATA_USAGE_ZONE_3 = 'dataUsageZone3',
  FREE_EVENT_CYCLE = 'freeEventCycle',
  FREE_TIMING = 'freeTiming',
  FREE_MONITOR = 'freeMonitor',
  FREE_SPONSOR = 'freeSponsor',
  FREE_DATA_FEED = 'freeDataFeed',

  // deprecated
  EXTEND_EVENT = 'extendEvent',
}

const UserStatisticsSchema = z.object({
  numberCreatedEvents: z.number().default(0),
  numberPaidEvents: z.number().default(0),
});

const IntegrationsSchema = z.object({
  stripeId: z.string().optional(),
  mailchimpSubscribed: z.boolean(),
  raceResultCustomerId: z.string().nullable(),
  raceResultApiKey: z.string().nullable(),
});

const AddressSchema = z.object({
  city: z.string().nullable(),
  country: z.string().nullable(),
  line1: z.string().nullable(),
  line2: z.string().nullable(),
  postal_code: z.string().nullable(),
  state: z.string().nullable(),
});

export const TaxIdSchema = z.object({
  id: z.string(),
  object: z.literal('tax_id'),
  country: z.string().nullable(),
  created: z.number(),
  customer: z.string().nullable(),
  deleted: z.undefined(),
  livemode: z.boolean(),
  type: z.string(),
  value: z.string(),
  verification: z.object({
    status: z.enum(['pending', 'verified', 'unverified', 'unavailable']),
    verified_address: z.string().nullable(),
    verified_name: z.string().nullable(),
  }),
});

const ShippingSchema = z.object({
  address: AddressSchema.optional(),
  carrier: z.string().nullish(),
  name: z.string().optional(),
  phone: z.string().nullish(),
  tracking_number: z.string().nullish(),
});

const CardSchema = z.object({
  address_line1_check: z.string().nullish(),
  address_zip_check: z.string().nullish(),
  brand: z.string().nullish(),
  country: z.string().nullish(),
  cvc_check: z.string().nullish(),
  description: z.string().optional(),
  dynamic_last4: z.string().nullish(),
  exp_month: z.number().nullish(),
  exp_year: z.number().nullish(),
  fingerprint: z.string().optional(),
  funding: z.string().nullish(),
  iin: z.string().optional(),
  issuer: z.string().optional(),
  last4: z.string().nullish(),
  name: z.string().nullish(),
  three_d_secure: z.string().optional(),
  tokenization_method: z.string().nullish(),
});

export const UserSchema = z.object({
  id: ObjectIdSchema,
  admin: z.boolean(),
  isBetaUser: z.boolean(),
  receivedAt: DateSchema.optional(),
  updatedAt: DateSchema,
  createdAt: DateSchema,
  isConfirmed: z.boolean(),
  encodedPass: z.string().optional(),
  resetPassToken: z.string().optional(),
  confirmationToken: z.string().optional(),
  saltToken: z.string().optional(),
  payments: z
    .object({
      lastUsageSync: DateSchema,
    })
    .optional(),
  name: z.string().optional(),
  company: z.string().optional(),
  email: z.string(),
  phoneNumber: z.string().optional(),
  country: z.string().optional(),
  useTimingAPI: z.boolean().optional(),
  isReseller: z.boolean(),
  password: z.string().optional(),
  fakeUser: z.boolean().optional(),
  acceptedTOSVersion: z.nativeEnum(TOSVersions),
  apiToken: z.string().nullable(),
  stats: UserStatisticsSchema.optional(),
  parentId: ObjectIdSchema.nullable(),
  integrations: IntegrationsSchema,
  subscriptions: z.object({
    [BillableItemTypes.APP]: z.number(),
  }),
  skipNotifications: z.boolean(),
  checkout: z
    .object({
      address: AddressSchema,
      taxIds: z.array(TaxIdSchema),
      shipping: ShippingSchema.nullish(),
      defaultCard: CardSchema.nullable(),
    })
    .optional(),
  createdByTest: z.boolean().default(false),
  promotions: z.object({
    december2024: z.boolean().optional(),
  }),
});

export const AdminUserSchema = UserSchema.extend({
  admin: z.literal(true),
});
export const ResellerUserSchema = UserSchema.extend({
  isReseller: z.literal(true),
});
export const BetaUserSchema = UserSchema.extend({
  isBetaUser: z.literal(true),
});
export const ChildUserSchema = UserSchema.extend({
  parentId: ObjectIdSchema,
});
export const RegularUserSchema = UserSchema.extend({
  admin: z.literal(false),
  parentId: z.null(),
  isReseller: z.literal(false),
});

export type User = z.infer<typeof UserSchema>;
export type Admin = z.infer<typeof AdminUserSchema>;
export type Reseller = z.infer<typeof ResellerUserSchema>;
export type BetaUser = z.infer<typeof BetaUserSchema>;
export type ChildUser = z.infer<typeof ChildUserSchema>;
export type RegularUser = z.infer<typeof RegularUserSchema>;

export const UserOverviewSchema = z.object({
  id: ObjectIdSchema,
  name: z.string().optional(),
  email: z.string(),
  admin: z.boolean().optional(),
  isReseller: z.boolean().optional(),
  isConfirmed: z.boolean().optional(),
  createdByTest: z.boolean().optional(),
  createdAt: DateSchema.optional(),
  stats: UserStatisticsSchema.optional(),
  integrations: z
    .object({
      stripeId: z.string().optional(),
    })
    .optional(),
});

export type UserOverview = z.infer<typeof UserOverviewSchema>;

const UserDocumentSchemaBase = UserSchema.omit({ id: true }).extend({
  _id: ObjectIdSchema,
  testPassword: z.function().args(z.string()).returns(z.boolean()),
  createSaltToken: z.function().returns(z.void()),
  encodePassword: z.function().args(z.string()).returns(z.void()),
  createResetPassToken: z.function().returns(z.void()),
  invalidateEmailConfirmation: z.function().returns(z.void()),
  getStripeEmail: z.function().returns(z.string()),
  addStripeId: z
    .function()
    .args(z.object({ phone: z.string().optional(), country: z.string().optional() }).optional())
    .returns(z.promise(z.void())),
  createdByTest: z.boolean().optional(),
});

export const UserDocumentSchema = UserDocumentSchemaBase.extend({
  canRead: z.function().args(UserDocumentSchemaBase.nullish()).returns(z.promise(z.boolean())),
  canEdit: z.function().args(UserDocumentSchemaBase.nullish()).returns(z.promise(z.boolean())),
});

export interface UserDocumentMethods {
  canEdit(user: UserDocument | null | undefined): Promise<boolean>;
  canRead(user: UserDocument | null | undefined): Promise<boolean>;
  testPassword(password: string): boolean;
  createSaltToken(): void;
  encodePassword(password: string): void;
  createResetPassToken(): void;
  invalidateEmailConfirmation(): void;
  getStripeEmail(): string;
  addStripeId(args?: { phone?: string; country?: string; company?: string }): Promise<void>;
}

export type UserDocument = HydratedDocument<User, UserDocumentMethods>;

// TODO: remove checkout in UserDocumentSchema optional and make it here required
export type UserDocumentWithStripeCustomerInfos = UserDocument;
