import type { FC } from 'react';
import { useLocation } from 'react-router';
import { AdminHeader } from './AdminHeader';
import { NavbarWithoutHeader } from './NavbarWithoutHeader';

export const Header: FC = () => {
  const { pathname } = useLocation();

  if (pathname.startsWith('/admin')) {
    return <AdminHeader />;
  }

  if (
    pathname.startsWith('/upload') ||
    pathname.startsWith('/tracker/') ||
    (pathname.startsWith('/app') && !pathname.includes('redeem_key'))
  ) {
    return <NavbarWithoutHeader />;
  }

  return null;
};
