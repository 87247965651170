import { type RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ErrorPage } from 'src/components/ErrorPage';
import { MonitorView } from 'src/views/MonitorView';
import { RootView } from 'src/views/RootView';
import { adminRoutes } from './admin.routes';

export const routes: Array<RouteObject> = [
  {
    path: '/',
    element: <RootView />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'auth/login',
        lazy: async () => {
          const comp = await import('../views/LoginView');
          return { Component: comp.LoginView };
        },
      },
      {
        path: 'auth/logout',
        lazy: async () => {
          const comp = await import('../views/LogoutView');
          return { Component: comp.LogoutView };
        },
      },
      {
        path: 'auth/register',
        lazy: async () => {
          const comp = await import('../views/RegisterView');
          return { Component: comp.RegisterView };
        },
      },
      {
        path: 'auth/reset-password',
        lazy: async () => {
          const comp = await import('../views/ResetPasswordView');
          return { Component: comp.ResetPasswordView };
        },
      },
      {
        path: 'auth/forgot-password',
        lazy: async () => {
          const comp = await import('../views/ForgotPasswordView');
          return { Component: comp.ForgotPasswordView };
        },
      },
      {
        path: 'auth/confirm-email',
        lazy: async () => {
          const comp = await import('../views/ConfirmEmailView');
          return { Component: comp.ConfirmEmailView };
        },
      },
      {
        path: 'tracker/:trackerId',
        lazy: async () => {
          const comp = await import('../admin/views/TrackerLandingView');
          return { Component: comp.TrackerLandingView };
        },
      },
      {
        path: 'upload',
        lazy: async () => {
          const comp = await import('../admin/views/UploadLandingView');
          return { Component: comp.UploadLandingView };
        },
      },
      {
        path: 'cost-calculator/:product',
        lazy: async () => {
          const comp = await import('../admin/views/CostCalculatorLandingView');
          return { Component: comp.CostCalculatorLandingView };
        },
      },
      {
        path: 'app',
        lazy: async () => {
          const comp = await import('../views/CustomAppLandingView');
          return { Component: comp.CustomAppLandingView };
        },
      },
      {
        path: 'app/redeem_key',
        lazy: async () => {
          const comp = await import('../views/RedeemKeyLandingView');
          return { Component: comp.RedeemKeyLandingView };
        },
      },
      {
        path: 'app/:appIdentifier',
        lazy: async () => {
          const comp = await import('../views/CustomAppLandingView');
          return { Component: comp.CustomAppLandingView };
        },
      },
      {
        path: 'app/:appIdentifier/redeem_key',
        lazy: async () => {
          const comp = await import('../views/RedeemKeyLandingView');
          return { Component: comp.RedeemKeyLandingView };
        },
      },
      {
        path: ':appIdentifier/redeem_key',
        lazy: async () => {
          const comp = await import('../views/RedeemKeyLandingView');
          return { Component: comp.RedeemKeyLandingView };
        },
      },
      {
        path: 'timing/:eventSlug',
        lazy: async () => {
          const comp = await import('../views/LeaderboardView');
          return { Component: comp.LeaderboardView };
        },
      },
      {
        path: 'monitor/:eventSlug',
        element: <MonitorView />,
        children: [
          {
            index: true,
            lazy: async () => {
              const comp = await import('../components/Monitor/Monitor');
              return { Component: comp.Monitor };
            },
          },
        ],
      },
      ...adminRoutes,
    ],
  },
];

const router = createBrowserRouter(routes);

export const MainRouter = () => {
  return <RouterProvider router={router} />;
};
