import type { Position } from 'geojson';
import geokdbush from 'geokdbush';
import type { Immutable } from 'immer';
import KDBush from 'kdbush';
import { type DeviceStateFlag, Targets } from '../consts/events';
import { formatJSONTime, formatKM, formatTimeDuration } from '../formatting';
import { calculateDistanceInMeter } from '../point_utils';
import { floorTo } from '../roundTo';
import type { RacemapEvent, RacemapStarter, Tags } from '../types/events';
import type { SplitObject } from '../types/geos';
import type { LatLng } from '../types/trackping';
import type { PointWithOffset } from '../types/types';
import type { ID } from '../types/utils';
export type { TagsCollection } from '@racemap/sdk/schema/starters';

export type StarterTrack = Array<PointWithOffset>;
export type StarterTrackWithTimeOffset = Array<PointWithSpeedAndOffset>;

export type StarterTracks = Map<ID, Array<PointWithOffset>>;
export type StarterTracksWithTimeOffset = Map<ID, Array<PointWithSpeedAndOffset>>;

export type PointWithTimeOffset = PointWithOffset & {
  timeOffset?: number;
  relativeDelta?: number;
  geofenceId?: string;
  manualDuration?: number;
  distanceToShadowTrack?: number;
};

export type PointWithSpeedAndOffset = PointWithTimeOffset & {
  speed: number; // Its used as meters per millisecond
  forceFinish?: boolean;
  splitTimeError?: number;
  splitOffsetError?: number;
  isBreak?: boolean;
};

export type Geofence = {
  name: string;
  offset?: number;
  timeOffset?: number;
  id?: string;
  position?: Position;
  thresholds?: Thresholds;
};

export type Thresholds = {
  latHigh: number;
  latLow: number;
  lngHigh: number;
  lngLow: number;
};

export interface TimeAtGeofence {
  id: string;
  relativeProgress: number;
  time: number;
  relativeDeltaProgress?: number;
}

export interface StarterInfo {
  id: ID;
  name: string;
  trackId: ID;
  startNumber: string;
  currentSpeed: number;
  tags: Tags | null;
  markerColor: string;
  rank: number;
  state: DeviceStateFlag | null;
  progress: number;
  activeDuration: number | null;
  results: Array<TimeAtGeofence>;
  online: boolean;
  manualFinishDuration: number;
  currentDuration: number | null;
}

export interface PreparedStarterInfo extends StarterInfo {
  tagsRank?: Record<string, number>;
  restDuration: number;
}

export interface RanksPackage {
  name: string;
  startTime: string;
  endTime: string;
  geoJSON: string;
  location: string;
  image: string;
  timekeepings: Array<Geofence>;
  starters: Array<StarterInfo>;
}

export interface PreparedRanksPackage extends RanksPackage {
  tagsStarterCount: Map<string, Map<string, number>>;
  starters: Array<PreparedStarterInfo>;
}

export enum RanksQueryFrom {
  MONITOR = 'MONITOR',
  LEADERBOARD = 'LEADERBOARD',
  APP = 'APP',
}

export function convertTracks(starterTracks: StarterTracks): StarterTracksWithTimeOffset {
  const output = new Map();
  for (const [trackId, track] of starterTracks.entries()) {
    output.set(trackId, track);
  }
  return output;
}

function calculateDistanceToShadowTrack(
  shadowTrackIndex: KDBush,
  point: PointWithSpeedAndOffset,
): number {
  const closestPoint = geokdbush.around(shadowTrackIndex, point.lng, point.lat, 1)[0];
  return calculateDistanceInMeter(closestPoint[1], closestPoint[0], point.lat, point.lng);
}

export function prepareStarterTracks(
  tracks: StarterTracksWithTimeOffset,
  event: RacemapEvent,
  eventHasVirtTarget: boolean,
  chunkedShadowtrack: Array<LatLng> | null = null,
) {
  // TODO: change function to generate new tracks and make no inplace modification
  // Calculate results for each track (starter)
  // We are accumulating the time and the offset each starter collects while moving along its own track
  // If there is a gap longer then event.breakTimeout we remove this delatTime and deltaOffset from collection
  const { breakTimeout } = event.playerOptions;
  const withShadowtrack = chunkedShadowtrack != null;
  const breaksArePossible = breakTimeout !== -1;

  if (eventHasVirtTarget) {
    const speedThresehold = event.maxSpeed / 3600;
    const minSpeedThreshold = event.minSpeed / 3600;
    for (const track of tracks.values()) {
      if (track.length > 0) {
        track[0].speed = 0;
        track[0].offset = 0;
        track[0].timeOffset = 0;
        let thisTime = 0;
        let thisDistance = 0;
        for (let i = 1; i < track.length; i++) {
          thisTime = new Date(track[i].time).getTime() - new Date(track[i - 1].time).getTime();
          if (breakTimeout === -1 || thisTime < breakTimeout) {
            thisDistance = calculateDistanceInMeter(
              track[i - 1].lat,
              track[i - 1].lng,
              track[i].lat,
              track[i].lng,
            );
            const thisSpeed = thisDistance / thisTime;
            if (thisSpeed < speedThresehold && thisSpeed > minSpeedThreshold) {
              track[i].offset = track[i - 1].offset + thisDistance;
              track[i].timeOffset = (track[i - 1].timeOffset || 0) + thisTime;
              track[i].speed = thisTime > 0 ? thisDistance / thisTime : 0;
            } else {
              track[i].offset = track[i - 1].offset;
              track[i].timeOffset = track[i - 1].timeOffset;
              track[i].speed = 0;
            }
          } else {
            track[i].offset = track[i - 1].offset;
            track[i].timeOffset = track[i - 1].timeOffset;
            track[i].speed = 0;
          }
        }
      }
    }
  } else {
    let shadowTrackIndex = null;
    if (chunkedShadowtrack != null) {
      shadowTrackIndex = new KDBush(
        chunkedShadowtrack,
        (p: Position) => p[0],
        (p: Position) => p[1],
      );
    }

    for (const track of tracks.values()) {
      // here we are just accumulating all results and offsest
      // we have to multiply by 10 cause offsets from the server are devided by 10
      // negative offset indicates the point was not on the shadow track
      let hasBeenOnceOnShadowTrack = false;

      for (const [index, point] of track.entries()) {
        if (index === 0) {
          point.offset = 0;
          point.timeOffset = 0;
          point.speed = 0;

          if (withShadowtrack) {
            point.distanceToShadowTrack = calculateDistanceToShadowTrack(shadowTrackIndex, point);
          }
          continue;
        }
        const isOnShadowTrack = point.offset >= 0;
        hasBeenOnceOnShadowTrack = !hasBeenOnceOnShadowTrack && isOnShadowTrack;

        // time between two points
        const elapsedTime =
          new Date(point.time).getTime() - new Date(track[index - 1].time).getTime();

        if (breaksArePossible && elapsedTime > breakTimeout) {
          point.speed = 0;
          point.isBreak = true;
        }

        point.offset *= 10;
        point.speed = point.offset - track[index - 1].offset / elapsedTime;
        point.timeOffset = hasBeenOnceOnShadowTrack
          ? (track[index - 1].timeOffset || 0) + elapsedTime
          : 0;

        if (withShadowtrack) {
          if (point.speed === 0) {
            point.distanceToShadowTrack = track[index - 1].distanceToShadowTrack;
          } else {
            point.distanceToShadowTrack = calculateDistanceToShadowTrack(shadowTrackIndex, point);
          }
        }
      }
    }
  }
}

export function getPseudoDeviceStateForRepeatEvent(
  starter: Immutable<RacemapStarter>,
  lastPoint: PointWithOffset | null,
  progress: number,
): RacemapStarter['deviceState'] {
  const battery = Math.round(progress != null ? (1 - progress * 0.8) * 100 : 100);
  // if the last point is not null and the time of the last point is less than 5 minutes ago
  const isOnline = !!(
    lastPoint != null && new Date(lastPoint.time).getTime() > Date.now() - 300000
  );

  return {
    ...(starter.deviceState || {}),
    battery,
    // depnding on isOnline we set the lastConnectedAt or lastDisconnectedAt resulting in a valid starter online state
    lastDisconnectedAt: !isOnline ? new Date() : undefined,
    lastConnectedAt: isOnline ? new Date() : undefined,
  };
}

export function sortTimes(a: number | null | undefined, b: number | null | undefined): number {
  if (a == null || Number.isNaN(a)) {
    if (b == null || Number.isNaN(b)) {
      return 0;
    }
    return 1;
  }
  if (b == null || Number.isNaN(b)) {
    return -1;
  }
  return a - b;
}

export function testEventHasVirtDuration(event: Immutable<RacemapEvent>) {
  return (
    (!eventHasShadowtrack(event) || event.modules.projection.enabled) &&
    event.modules.timing.target === Targets.DURATION &&
    event.modules.timing.duration != null
  );
}

export function testEventHasVirtDistance(event: Immutable<RacemapEvent>) {
  return (
    (!eventHasShadowtrack(event) || event.modules.projection.enabled) &&
    event.modules.timing.target === Targets.DISTANCE &&
    event.modules.timing.distance != null
  );
}

export function getVirtualRaceDistance(
  event: Immutable<RacemapEvent>,
  splits: Immutable<Array<SplitObject>>,
): number {
  const timekeepingSplits = splits?.filter((split) => split.properties.timekeeping);
  const withShadowtrack = eventHasShadowtrack(event);

  if (withShadowtrack && timekeepingSplits?.length > 0) {
    const lastTimekeepingSplit = timekeepingSplits?.[timekeepingSplits.length - 1];
    const firstTimekeepingSplit = timekeepingSplits?.[0];

    return (
      (lastTimekeepingSplit.properties.offset || 0) - (firstTimekeepingSplit.properties.offset || 0)
    );
  }

  if (withShadowtrack && splits.length > 0) {
    const lastSplit = splits[splits.length - 1];
    if (lastSplit.properties.offset == null) throw new Error("Offset shouldn't be null");

    return lastSplit.properties.offset;
  }

  return event.modules.timing.distance;
}

export function getVirtualRaceDuration(event: Immutable<RacemapEvent>) {
  return event?.modules?.timing?.duration;
}

export function eventHasShadowtrack(event: Immutable<RacemapEvent>) {
  return event.geo != null && event.geo.shadowgeojsonHash != null;
}

export function buildCSVFile(
  tableData: Array<StarterInfo | null>,
  geofences: Array<Geofence>,
  eventHasVirtDuration: boolean,
) {
  return new Blob(
    [
      Array.from(
        (function* () {
          yield `ID;Name;Start Number;Rank;Current Progress (${
            eventHasVirtDuration ? 'hh:mm:ss' : 'km'
          });${geofences
            .map(
              (g) =>
                `${g.name} (${
                  eventHasVirtDuration ? formatTimeDuration(g.timeOffset) : formatKM(g.offset)
                }) Split;${g.name} (${formatKM(g.offset)}) Timestamp`,
            )
            .join(';')}`;
          for (const starterData of tableData) {
            if (starterData == null) continue;
            yield [
              starterData.id,
              starterData.name,
              starterData.startNumber,
              starterData.rank,
              floorTo(starterData.progress / 1000, 2),
              ...starterData.results
                .slice(0)
                .map((time) =>
                  time != null
                    ? `${
                        eventHasVirtDuration
                          ? formatKM(time.relativeProgress)
                          : formatTimeDuration(time.relativeProgress)
                      };${formatJSONTime(time.time) || ''}`
                    : ';',
                ),
            ].join(';');
          }
        })(),
      ).join('\n'),
    ],
    {
      type: 'text/csv;charset=utf-8;',
    },
  );
}

export function buildGPXFile(points: Array<PointWithOffset>, eventName: string): Blob {
  return new Blob(
    [
      `<?xml version="1.0" encoding="UTF-8" standalone="no" ?>
<gpx version="1.1" creator="Racemap">
  <metadata><time>${new Date().toISOString()}</time></metadata>
  <trk>
    <name>${eventName}</name>
    <trkseg>${points
      .map(
        (p) => `
      <trkpt lat="${p.lat}" lon="${p.lng}">
        <time>${new Date(p.time).toISOString()}</time>
      </trkpt>`,
      )
      .join('')}
    </trkseg>
  </trk>
</gpx>
`,
    ],
    { type: 'application/gpx+xml' },
  );
}
