import type { StripePriceWithTax } from '@racemap/sdk/schema/billing';
import type { StripeProducts } from '@racemap/sdk/schema/billing/StripeProducts';
import { getProductPriceFactory } from './getProductPriceFactory';

export const getTotalPrice = (
  productCounts: Partial<Record<StripeProducts, number>>,
  priceList: Record<StripeProducts, StripePriceWithTax> | undefined,
): number => {
  const getProductPrice = getProductPriceFactory(priceList);
  if (getProductPrice == null) return 0;

  return Object.entries(productCounts).reduce(
    (total, [product, quantity]) =>
      total + getProductPrice(product as StripeProducts, quantity || 0).amount,
    0,
  );
};
