import styled from '@emotion/styled';
import { MaxMobileWindowWidth } from '@racemap/utilities/consts/common';
import type { FC } from 'react';
import { Outlet } from 'react-router-dom';

export const MessageTemplatesView: FC = () => {
  return (
    <Container>
      <Outlet />
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  padding: 10px;
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;

  @media (max-width: ${MaxMobileWindowWidth}px) {
    padding: 0px;
  }
`;
