import React, { type FC } from 'react';
import { animated, useSpring } from 'react-spring';
import { IconArrowLeft, IconArrowRight } from '../../Icon';

interface Props {
  isDown: boolean;
  showClosedToLeft?: boolean;
}

export const AnimatedArrow: FC<Props> = ({ isDown, showClosedToLeft }) => {
  const targetDegree = showClosedToLeft ? -90 : 90;
  const { rotate } = useSpring({
    rotate: isDown ? targetDegree : 0,
  });

  return (
    <animated.div style={{ width: 21, transform: rotate.to((r: number) => `rotate(${r}deg)`) }}>
      {showClosedToLeft ? <IconArrowLeft fixedWidth /> : <IconArrowRight fixedWidth />}
    </animated.div>
  );
};
