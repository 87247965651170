import crossFetch from 'cross-fetch';
import type { RequestInit as NodeRequestInit } from 'node-fetch';

const isNode =
  typeof process !== 'undefined' && process.release != null && process.release.name === 'node';

const fetch = (() => {
  if (isNode) {
    const http = require('node:http');
    const https = require('node:https');

    // keepAlive causes the prediction test http calls to exeed the timeouts cause the connection does not return properly
    const httpAgent = new http.Agent({
      keepAlive: true,
    });
    const httpsAgent = new https.Agent({
      keepAlive: true,
    });

    return function fetch(
      input: RequestInfo,
      init: NodeRequestInit | RequestInit = {},
    ): Promise<Response> {
      return crossFetch(input, {
        ...init,
        // @ts-expect-error cross fetch implement the types of node-fetch not correct
        agent: (_parsedURL) => (_parsedURL.protocol === 'http:' ? httpAgent : httpsAgent),
      });
    };
  }
  return crossFetch;
})();

export default fetch;
