import styled from '@emotion/styled';
import type { FC } from 'react';
import type { Variant } from 'react-bootstrap/esm/types';
import { getStatusIcon } from '../../Icon';

interface Props
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  status?: Variant;
}

export const StatusCell: FC<Props> = ({ status, ...props }) => {
  return <Container {...props}>{getStatusIcon(status)}</Container>;
};

const Container = styled.div`
  border-bottom: 1px solid #dee2e6;
  padding: 3px;
  justify-content: center;
  grid-column: 3;
`;
