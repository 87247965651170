import { AuthorizationStates } from '@racemap/utilities/consts/events';
import { isGroupEvent } from '@racemap/utilities/functions/utils';
import { Popover } from 'antd';
import type { Immutable } from 'immer';
import type { FC } from 'react';
import { useCurrentUser, useEventBasePrice, useUser } from '../../../lib/customHooks';
import { formatPrice } from '../../../misc/pricing';
import type { CurrentEvent } from '../../../store/events/events_reducers';

interface Props {
  event: Immutable<CurrentEvent>;
}

export const PriceField: FC<Props> = ({ event }) => {
  const user = useCurrentUser();
  const eventBasePrice = useEventBasePrice();
  const { user: creator } = useUser({ userId: event.creatorId });

  if (isGroupEvent(event) || creator == null) return null;
  if (user == null || user.id !== event.creatorId || eventBasePrice == null) return <></>;
  if (event.authorization !== AuthorizationStates.NONE) return <></>;

  return (
    <Popover
      title={'This price does not include VAT'}
      content={
        'Please update your account with the correct country and/or VAT ID in order to change the tax rate.'
      }
    >
      Base Price: <strong>{formatPrice(eventBasePrice)}</strong>
    </Popover>
  );
};
