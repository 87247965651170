import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { isEmptyString } from '@racemap/utilities/functions/utils';
import { DateTime } from 'luxon';
import type { FC } from 'react';
import { DatePicker } from '../../BasicComponents/DateTimePicker';

interface Props {
  timestamp: string;
  label: string;
  setTimestamp: (value: string) => void;
  minDate?: DateTime;
}

export const TimestampEditor: FC<Props> = ({ timestamp, label, setTimestamp, minDate }) => {
  const handleChange = (timestamp: string) => {
    if (isEmptyString(timestamp)) return;

    setTimestamp(timestamp);
  };

  return (
    <EditableTimestampField
      timestamp={timestamp}
      label={label}
      onChange={(timestamp: string) => {
        handleChange(timestamp);
      }}
      minDate={minDate}
    />
  );
};

const EditableTimestampField: FC<{
  timestamp: string;
  label: string;
  onChange: (newValue: string) => void;
  minDate?: DateTime;
}> = ({ timestamp, label, onChange, minDate }) => {
  const handleChange = (date: DateTime | null) => {
    if (date) {
      onChange(date.toString());
    }
  };

  return (
    <InfoFieldText>
      <div>{label}:</div>
      <DatePicker
        minDate={minDate}
        className="custom-datepicker"
        defaultValue={DateTime.fromISO(timestamp)}
        onChange={handleChange}
        showTime
      />
    </InfoFieldText>
  );
};

const InfoFieldText = styled.div`
  color: ${RacemapColors.DarkGray};
  display: flex;
  flex-direction: row;
  align-items: left;
    .custom-datepicker {
      margin-left: 5px;
      
    padding: 2px 5px;
      height: 20px;
      width: 180px;
  }
`;
