import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { Popover, type PopoverProps, Space } from 'antd';
import type { FC, PropsWithChildren } from 'react';
import { IconExclamationCircle, IconQuestion, IconQuestionOutline } from '../../Icon';

interface Props extends PropsWithChildren {
  title?: string;
  placement?: PopoverProps['placement'];
  variant?: 'description' | 'description-light' | 'warning';
  text?: string;
  suffix?: string;
  underlineText?: boolean;
  triggerByHover?: 'icon' | 'both';
  width?: number;
}

export const PopoverHint: FC<Props> = ({
  title,
  children,
  placement,
  variant = 'description-light',
  text = '',
  suffix = '',
  underlineText = false,
  triggerByHover = 'icon',
  width,
}) => {
  const icon =
    variant === 'description' ? (
      <IconQuestion />
    ) : variant === 'description-light' ? (
      <IconQuestionOutline />
    ) : (
      <IconExclamationCircle color={RacemapColors.DarkYellow} />
    );
  const textPrepared = underlineText ? <u>{text}</u> : text;

  if (triggerByHover === 'icon') {
    return (
      <Space size={2}>
        {textPrepared}
        <Popover
          trigger={['hover', 'click', 'focus']}
          title={title ? <TitleWrapper>{title}</TitleWrapper> : undefined}
          placement={placement}
          content={<ContentWrapper width={width}>{children}</ContentWrapper>}
          overlayInnerStyle={{ padding: 0 }}
          overlayStyle={{ zIndex: 1070 }}
        >
          <IconWrapper>{icon}</IconWrapper>
        </Popover>
        {suffix}
      </Space>
    );
  }
  return (
    <Popover
      trigger={['hover', 'click', 'focus']}
      title={title ? <TitleWrapper>{title}</TitleWrapper> : undefined}
      placement={placement}
      content={<ContentWrapper width={width}>{children}</ContentWrapper>}
      overlayInnerStyle={{ padding: 0 }}
      overlayStyle={{ zIndex: 1070 }}
    >
      {' '}
      <Space size={3}>
        {textPrepared}
        <IconWrapper>{icon}</IconWrapper>
        {suffix}
      </Space>
    </Popover>
  );
};

const IconWrapper = styled.div`
  opacity: 0.7;
`;

const ContentWrapper = styled.div<{ width?: number }>`
  max-width: 300px;
  padding: 12px;
  ${({ width }) => (width ? `min-width: ${width}px;` : '')}
`;

const TitleWrapper = styled.div`
  font-weight: 550;
  color: ${RacemapColors.DarkDarkGray};
  padding: 12px;
  border-bottom: 1px solid ${RacemapColors.Gray};
  background-color: ${RacemapColors.LightLightGray};
`;
