import { DateSchema } from '@racemap/sdk/schema/base';
import { z } from 'zod';
import { EventLoadTypes } from '../events';
import { BillableItemTypes } from '../user';
import { DailyLoadsSchema } from './dailyLoads';
import { EventStatisticsSchema } from './eventStatistics';
import { TaxBreakdownSchema } from './taxBrakedown';
import { TotalUsagesSchema } from './totalUsages';

const UsageGroupedByTimeKeyAndZoneSchema = z.record(
  z.string(),
  z.object({
    [BillableItemTypes.DATA_USAGE_ZONE_1]: z.number().optional(),
    [BillableItemTypes.DATA_USAGE_ZONE_2]: z.number().optional(),
    [BillableItemTypes.DATA_USAGE_ZONE_3]: z.number().optional(),
  }),
);

export const BillingInfoSchema = z.object({
  dailyLoads: DailyLoadsSchema,
  eventStatistics: EventStatisticsSchema,
  totals: TotalUsagesSchema,
  totalLoadCounts: z.record(z.nativeEnum(EventLoadTypes), z.number()),
  totalAmount: z.number().optional(),
  totalAmountTax: z.number().optional(),
  taxBreakdowns: TaxBreakdownSchema.optional(),
  startTime: DateSchema,
  endTime: DateSchema,
  dataUsages: UsageGroupedByTimeKeyAndZoneSchema,
});

export type BillingInfo = z.infer<typeof BillingInfoSchema>;
