import type { Immutable } from 'immer';
import type { ManagedTracker } from '../store/trackers/trackers_reducers';

export const isSearchedTracker = (
  tracker: Immutable<ManagedTracker>,
  searchValue: string,
): boolean => {
  const testTrackerName = `${tracker.trackerName}`.toLocaleLowerCase();
  const testImei = `${tracker.trackerId}`.toLocaleLowerCase();
  const testIccid = `${tracker.meta?.iccid}`.toLocaleLowerCase();
  const testId = `${tracker.id}`.toLocaleLowerCase();
  const testTrackerId = `${tracker.trackerId}`.toLocaleLowerCase();
  const searchValues = searchValue
    .trim()
    .toLocaleLowerCase()
    // remove any whitespace before and after a comma
    .replace(/\s*,\s*/g, ',')
    // split by comma
    .split(',')
    // remove any empty strings
    .filter(Boolean);
  for (const searchValue of searchValues) {
    if (
      testTrackerName.includes(searchValue) ||
      testImei.includes(searchValue) ||
      testIccid.includes(searchValue) ||
      testId.includes(searchValue) ||
      testTrackerId.includes(searchValue)
    ) {
      return true;
    }
  }
  return false;
};
