import { RacemapColors } from '@racemap/utilities/consts/common';
import { EventLoadTypes } from '@racemap/utilities/types/events';
import tinycolor from 'tinycolor2';

export const getSeriesColor = (
  type: EventLoadTypes,
  eventId: string | undefined,
  eventNameMapping?: Record<string, string>,
) => {
  const indexInEventMap = Object.entries(eventNameMapping || {})
    .sort((a, b) => a[1].localeCompare(b[1]))
    .map((e) => e[0])
    .indexOf(eventId || '');
  const lightningFactor = indexInEventMap === -1 ? 0 : indexInEventMap * 8;

  const color = tinycolor(loadColorMapping[type]).lighten(lightningFactor).toRgbString();

  return color;
};

export const loadColorMapping: Record<EventLoadTypes, string> = {
  [EventLoadTypes.MAP_LOADS]: RacemapColors.PaleBlue,
  [EventLoadTypes.CURRENT_LOADS]: RacemapColors.BaseGreen,
  [EventLoadTypes.DISTANCE_LOADS]: RacemapColors.DarkYellow,
  [EventLoadTypes.MONITOR_LOADS]: RacemapColors.BaseRed,
  [EventLoadTypes.RANKS_LOADS]: RacemapColors.DarkBlue,
  [EventLoadTypes.TIMES_ID_LOADS]: RacemapColors.Orange,
  [EventLoadTypes.TIMES_LOADS]: RacemapColors.AltRed,
  [EventLoadTypes.TIMING_LOADS]: RacemapColors.DarkRed,
};

export const loadTypeLabels: Record<EventLoadTypes, string> = {
  [EventLoadTypes.MAP_LOADS]: 'Map',
  [EventLoadTypes.CURRENT_LOADS]: 'Current API',
  [EventLoadTypes.DISTANCE_LOADS]: 'Distance API',
  [EventLoadTypes.MONITOR_LOADS]: 'Monitor',
  [EventLoadTypes.RANKS_LOADS]: 'Ranks API',
  [EventLoadTypes.TIMES_ID_LOADS]: 'Times ID API',
  [EventLoadTypes.TIMES_LOADS]: 'Times API',
  [EventLoadTypes.TIMING_LOADS]: 'Leaderboard',
};
