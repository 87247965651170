import type { FC } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { useIsMobile } from '../../lib/customHooks';

interface LinkEntryProps {
  children: React.ReactNode;
  to?: string;
  id?: string;
  active?: boolean;
  title?: string;
  as?: React.ElementType;
}

export const LinkEntry: FC<LinkEntryProps> = ({ children, ...props }) => {
  const isMobile = useIsMobile();

  return isMobile ? (
    <Nav.Link {...props}>{children}</Nav.Link>
  ) : (
    <NavDropdown.Item {...props}>{children}</NavDropdown.Item>
  );
};
