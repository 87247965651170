import styled from '@emotion/styled';
import { type FC, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useCurrentEvent, useCurrentUser } from '../../../lib/customHooks';
import { useStore } from '../../../store/reducers';
import { DoneView, DoneViewFooter } from './DoneView';
import { EmbeddedStripeView } from './EmbeddedStripeView';
import { type Checks, OverviewView } from './OverviewView';
import { UserRole, ViewStates, checkPaymentStatus, getUserRole, initPayment } from './utils';

type Props = {
  show: boolean;
  onClose: () => void;
};

export const PaymentModal: FC<Props> = ({ show = false, onClose }) => {
  const currentUser = useCurrentUser();
  const currentEvent = useCurrentEvent();

  const [viewState, setViewState] = useState<ViewStates | null>(null);
  const [checks, setChecks] = useState<Checks>({
    acceptTerms: false,
    acceptSubscription: false,
  });
  const { loadCurrentEvent } = useStore((s) => s.events.actions);

  useEffect(() => {
    if (viewState != null || currentEvent == null) return;
    resetPaymentState(currentEvent.id);
  }, [currentEvent, currentUser]);

  if (currentUser == null || currentEvent == null) return <></>;
  const userRole = getUserRole(currentUser, currentEvent);

  async function payment() {
    try {
      if (currentEvent == null) return;
      const paymentInfos = await initPayment(currentEvent);

      switch (paymentInfos.status) {
        case 'PAID':
          await moveToDone();
          break;
      }
    } catch (e) {
      if (e instanceof Error) {
        toast.error(e.message);
        console.error(e.message);
      }
    }
  }

  async function resetPaymentState(eventId: string) {
    const paymentState = await checkPaymentStatus(eventId);
    switch (paymentState.status) {
      case 'PAID':
        await moveToDone();
        break;
      case 'NONE':
        setViewState(ViewStates.OVERVIEW);
        break;
    }
  }

  function moveToStripe() {
    setViewState(ViewStates.STRIPE);
  }

  async function moveToDone() {
    if (currentEvent == null) return;

    setViewState(ViewStates.DONE);
    await loadCurrentEvent(currentEvent.id);
  }

  const handleOverviewNext = async () => {
    switch (userRole) {
      case UserRole.ADMIN:
      case UserRole.RESELLER:
      case UserRole.CHILD:
        await payment();
        break;
      case UserRole.REGULAR:
        moveToStripe();
        break;
    }
  };

  let content = <></>;
  switch (viewState) {
    case ViewStates.OVERVIEW:
      content = (
        <OverviewView
          onClose={onClose}
          onNext={handleOverviewNext}
          checks={checks}
          onChecksChange={setChecks}
        />
      );
      break;
    case ViewStates.STRIPE:
      content = (
        <EmbeddedStripeView
          user={currentUser}
          event={currentEvent}
          onBack={() => setViewState(ViewStates.OVERVIEW)}
          onCancel={onClose}
          onNext={moveToDone}
        />
      );
      break;
    case ViewStates.DONE:
      content = (
        <>
          <Modal.Header>
            <Modal.Title>Payment complete</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <DoneView />
          </Modal.Body>
          <Modal.Footer>
            <DoneViewFooter onClose={onClose} />
          </Modal.Footer>
        </>
      );
      break;
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      size={viewState === ViewStates.OVERVIEW ? 'lg' : undefined}
      dialogAs={ModalDialog}
    >
      {content}
    </Modal>
  );
};

const ModalDialog = styled(Modal.Dialog)`
  max-width: 800px;
  width: fit-content;
`;
