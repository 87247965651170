import type { StringUnitLength } from 'luxon';

export const formatShortRelativeTime = (diffSec: number, size: StringUnitLength = 'long') => {
  const unit = size === 'long' ? 'seconds' : 'sec';
  if (diffSec < 0 && diffSec > -60) {
    return `few ${unit}`;
  }
  if (diffSec > 0 && diffSec < 60) {
    return `in few ${unit}`;
  }
  if (diffSec === 0) {
    return 'now';
  }
  throw new Error('Unable to format relative time for the given value');
};
