import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import classNames from 'classnames';
import type { TargetType } from 'dnd-core';
import { type FC, type PropsWithChildren, useState } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { animated, useSpring } from 'react-spring';
import tinycolor from 'tinycolor2';
import FileDialog from '../../lib/FileDialog';
import { IconUpload } from '../Icon';

export interface Props {
  onFilesDrop: (files: FileList) => void;
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  allowedItemTypes?: Array<string>;
  allowedItemKind?: 'file' | string;
  showOverlay?: boolean;
  acceptTargetTypes?: TargetType;
  showOverlayAlways?: boolean;
  overlayText?: string;
  withFileDialog?: boolean;
}

interface Item {
  files: FileList;
  items: Array<{ kind: string; type: string }>;
}

export const DropZone: FC<PropsWithChildren<Props>> = ({
  onFilesDrop,
  className,
  children,
  disabled = false,
  allowedItemKind,
  allowedItemTypes,
  showOverlay = true,
  showOverlayAlways = false,
  acceptTargetTypes,
  overlayText = 'Place your file here',
  withFileDialog = false,
  ...props
}) => {
  const [text, setText] = useState(overlayText);
  const [variant, setVariant] = useState<'info' | 'danger'>('info');
  const accept =
    acceptTargetTypes != null
      ? acceptTargetTypes
      : allowedItemKind === 'file'
        ? NativeTypes.FILE
        : Object.values(NativeTypes);

  const [collect, drop] = useDrop<Item, unknown, { canDrop: boolean; isOver: boolean }>({
    accept,
    drop(_, monitor) {
      if (onFilesDrop != null && monitor.canDrop()) {
        onFilesDrop(monitor.getItem<{ files: FileList }>().files);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: (_, monitor) => {
      if (disabled) return false;

      const { items } = monitor.getItem();
      if (items == null || items.length < 1) return true;

      const firstItem = items[0];

      if (allowedItemKind != null && allowedItemKind !== firstItem.kind) {
        setText('Invalid File Kind!');
        setVariant('danger');

        return false;
      }
      if (allowedItemTypes != null && !allowedItemTypes.includes(firstItem.type)) {
        setText('Invalid File Type!');
        setVariant('danger');

        return false;
      }

      setText(overlayText);
      setVariant('info');
      return true;
    },
  });
  const highlight = !disabled && collect.isOver;

  const handleFileDialogRequest = () => {
    FileDialog.show({ accept: allowedItemTypes?.join(',') })
      .then(onFilesDrop)
      .catch((e) => console.error(e));
  };

  return (
    <Container ref={drop} className={classNames({ 'file-hover': highlight }, className)} {...props}>
      {children}
      {showOverlay && (
        <Overlay
          isOver={highlight}
          text={text}
          variant={variant}
          showAlways={showOverlayAlways}
          withFileDialog={withFileDialog}
          onFileDialogRequest={handleFileDialogRequest}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Overlay: FC<{
  isOver: boolean;
  variant?: 'success' | 'danger' | 'warning' | 'info';
  text?: string;
  showAlways?: boolean;
  withFileDialog?: boolean;
  onFileDialogRequest: () => void;
}> = ({ isOver, variant = 'info', text, showAlways, withFileDialog, onFileDialogRequest }) => {
  const basicTransparency = showAlways ? 0.6 : 0;
  const { opacity } = useSpring({ opacity: isOver ? 0.95 : basicTransparency });
  const color = getVariantColor(variant);
  const colorBackground = tinycolor(color).lighten(0.5).toHexString();

  const handleFileDialogClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    onFileDialogRequest();
  };

  return (
    <OverlayBody style={{ opacity, borderColor: color }}>
      <Background style={{ background: colorBackground }} />
      <IconUpload style={{ color }} />
      {text != null && <Text style={{ color }}>{text}</Text>}
      {withFileDialog && showAlways && (
        <ChooseFileLink onClick={handleFileDialogClick} style={{ color }}>
          ...or choose file
        </ChooseFileLink>
      )}
    </OverlayBody>
  );
};

const OverlayBody = styled(animated.div)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  border-radius: 10px;
  border: dashed 4px ${RacemapColors.DarkGray};
  color: ${RacemapColors.DarkGray};
  pointer-events: none;
  z-index: 999;
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.3;
  z-index: 0;
`;

const Text = styled.div`
  font-weight: 600;
  font-size: small;
`;

const ChooseFileLink = styled.a`
  font-size: small;
  color: ${RacemapColors.DarkBlue};
  text-decoration: underline;
  cursor: pointer;
  pointer-events: all;
`;

const getVariantColor = (variant: 'success' | 'danger' | 'warning' | 'info'): string => {
  switch (variant) {
    case 'success':
      return RacemapColors.BaseGreen;
    case 'danger':
      return RacemapColors.DarkRed;
    case 'warning':
      return RacemapColors.BaseYellow;
    default:
      return RacemapColors.PaleBlue;
  }
};
