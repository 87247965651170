import type { ObjectId } from '@racemap/utilities/types/utils';
import { UserList } from './BasicComponents/UserChooser/UserList';

type Props = {
  value: Array<ObjectId>;
  onChange: (arg0: Array<ObjectId>) => void;
};

const AddEditors = ({ value, onChange }: Props) => {
  return (
    <UserList
      value={value}
      withDevider={false}
      onChange={onChange}
      label="Change Editors"
      removalConfirmTitle="Remove Editor"
      removalConfirmDescription="Do you really want to remove that user from editors?"
    />
  );
};

export default AddEditors;
