import styled from '@emotion/styled';
import { theme as AntTheme } from 'antd';
import type { CSSProperties } from 'react';
import { themeConfig } from '../../../lib/theme';

const globalToken = AntTheme.getDesignToken(themeConfig);

export const Flex = styled.div`
  display: flex;
`;

export const Center = styled(Flex)`
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const VStack = styled(Flex)<{
  gap?: CSSProperties['gap'];
  justify?: CSSProperties['justifyContent'];
}>`
  align-items: flex-start;
  justify-content: ${({ justify = 'space-between' }) => justify};
  flex-direction: column;
  row-gap: ${({ gap = 'normal' }) => gap};
`;

export const Bold = styled.span`
  font-weight: ${globalToken.fontWeightStrong}
`;

export const HStack = styled(Flex)<{
  justify?: CSSProperties['justifyContent'];
  align?: CSSProperties['alignItems'];
  width?: CSSProperties['width'];
  gap?: CSSProperties['gap'];
  wrap?: CSSProperties['flexWrap'];
}>`
  justify-content: ${({ justify = 'space-between' }) => justify};
  align-items: ${({ align = 'center' }) => align};
  width: ${({ width = 'auto' }) => width};
  column-gap: ${({ gap = 'normal' }) => gap};
  flex-wrap: ${({ wrap = 'nowrap' }) => wrap};
  flex-direction: row;
`;
