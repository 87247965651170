import type { FC } from 'react';
import { MiniBadge } from './MiniBadge';

export const AdminBadge: FC = () => {
  return (
    <MiniBadge color="blue" bordered={false}>
      Admin
    </MiniBadge>
  );
};
