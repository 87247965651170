import type { ObjectId } from '@racemap/sdk/schema/base';
import type { Tracker, TrackerState } from '@racemap/sdk/schema/trackers';
import { type Immutable, produce } from 'immer';
import { TRACKER_TYPES, TrackerActivationType, USER_ROLE } from '../consts/trackers';
import type { TrackerDocument, TrackerMessageObject, TrackerObject } from '../types/trackers';
import type { User, User_Legacy } from '../types/users';
import { isArchivedTracker } from './validation';

const isNode =
  typeof process !== 'undefined' && process.release != null && process.release.name === 'node';

export const getIdString = (id: string | ObjectId): string => {
  if (typeof id === 'string') return id;
  return id.toHexString();
};

export const isOwner = (tracker: Immutable<TrackerObject | Tracker>, userId: string): boolean => {
  const ownerId = tracker.owner != null ? tracker.owner : null;

  return userId != null && ownerId != null && userId === getIdString(ownerId);
};

export const isEditor = (tracker: Immutable<TrackerObject | Tracker>, userId: string): boolean => {
  return userId != null && tracker.editors.map((id) => getIdString(id)).includes(userId);
};

export const isBorrower = (
  tracker: Immutable<TrackerObject | Tracker>,
  userId: string,
): boolean => {
  return (
    userId != null &&
    tracker.borrowers != null &&
    tracker.borrowers.length > 0 &&
    tracker.borrowers?.map((a) => getIdString(a.id)).includes(userId)
  );
};

export const isAccessible = (
  tracker: Immutable<Tracker> | null,
  user: Immutable<User> | Immutable<User_Legacy> | null,
): boolean => {
  if (user == null || tracker == null) return false;
  if (user.admin) return true;

  return !isArchivedTracker(tracker);
};

export const isRacemapTracker = (
  tracker: Immutable<TrackerObject> | TrackerDocument | null,
): boolean => {
  return tracker?.activation.type === TrackerActivationType.RACEMAPTRACKER;
};

export const isAlreadyPaid = (
  tracker: Immutable<TrackerObject> | TrackerDocument | null,
): boolean => {
  return (
    tracker?.activation.paidUntil != null && new Date(tracker.activation.paidUntil) > new Date()
  );
};

export const getUserRoleForTracker = (
  tracker: Immutable<TrackerObject | Tracker>,
  userId: string | ObjectId,
): USER_ROLE | null => {
  if (tracker == null || userId == null) return null;

  if (isOwner(tracker, getIdString(userId))) return USER_ROLE.OWNER;
  if (isEditor(tracker, getIdString(userId))) return USER_ROLE.EDITOR;
  if (isBorrower(tracker, getIdString(userId))) return USER_ROLE.BORROWER;
  return null;
};

export const isTrackerType = (value: string): value is TRACKER_TYPES => {
  return Object.values<string>(TRACKER_TYPES).includes(value);
};

export const binariesMessagePayload = (message: TrackerMessageObject): TrackerMessageObject => {
  const payload = message.payload as unknown;
  const responsePayload = message.response?.responsePayload as unknown;

  if (payload == null) return message;

  if (typeof payload !== 'string') {
    if (message.payload?.type === 'Buffer') return message as TrackerMessageObject;

    throw new Error('Receive message with an unknown payload format!');
  }

  return produce(message, (draft) => {
    if (isNode) {
      const buffer = Buffer.from(payload, 'base64');
      draft.payload = buffer.toJSON();

      if (typeof responsePayload === 'string') {
        const responseBuffer = Buffer.from(responsePayload, 'base64');
        if (draft.response != null) {
          draft.response.responsePayload = responseBuffer.toJSON();
        }
      }
    } else {
      const buffer = Uint8Array.from(atob(payload), (c) => c.charCodeAt(0));
      draft.payload = { type: 'Buffer', data: Array.from(buffer) };

      if (typeof responsePayload === 'string') {
        const responseBuffer = Uint8Array.from(atob(responsePayload), (c) => c.charCodeAt(0));
        if (draft.response != null) {
          draft.response.responsePayload = { type: 'Buffer', data: Array.from(responseBuffer) };
        }
      }
    }
  });
};

export const binariesTrackerMessages = <T extends { messages?: Array<TrackerMessageObject> }>(
  tracker: T,
): T => {
  tracker.messages = tracker.messages?.map(binariesMessagePayload) || [];
  return tracker;
};

export const getTrackerDefaultPassword = (trackerType: TRACKER_TYPES): string => {
  switch (trackerType) {
    case TRACKER_TYPES.GL300:
    case TRACKER_TYPES.GL300W:
      return 'gl300';
    case TRACKER_TYPES.GL300LTEME:
    case TRACKER_TYPES.GL300LTEMA:
    case TRACKER_TYPES.GL300LTEMG:
      return 'gl300m';
    case TRACKER_TYPES.GL310LTEMG:
      return 'gl310m';
    case TRACKER_TYPES.GL320LTEMG:
      return 'gl320m';
    case TRACKER_TYPES.PrimeII:
      return 'AIR11';
    case TRACKER_TYPES.LK1062G:
      return '123456';
    case TRACKER_TYPES.LK1063G:
      return '123456';
    default:
      return 'racemap';
  }
};

export const asciiToHexString = (ascii: string): string => {
  return ascii
    .split('')
    .map((c) => c.charCodeAt(0).toString(16))
    .join('');
};

export const isTrackerOnline = (state?: TrackerState): boolean | null => {
  if (!state) {
    return null;
  }
  if (!state.lastDisconnectedAt && !state.lastConnectedAt) {
    return false; // Tracker is considered offline if both timestamps are not set
  }

  if (!state.lastConnectedAt) {
    return false; // If lastConnectedAt is not set, assume tracker is offline
  }

  if (!state.lastDisconnectedAt) {
    return true; // If lastDisconnectedAt is not set, assume tracker is online
  }

  // Compare lastConnectedAt and lastDisconnectedAt timestamps to determine online status
  return state.lastConnectedAt > state.lastDisconnectedAt;
};
