import DatePicker from 'antd/es/date-picker';
import type { PickerProps } from 'antd/es/date-picker/generatePicker';
import type { DateTime } from 'luxon';
import luxonGenerateConfig from 'rc-picker/lib/generate/luxon';

const CustomDatePicker = DatePicker.generatePicker<DateTime>(luxonGenerateConfig);

const CustomMonthPicker: React.FC<PickerProps<DateTime>> = (props) => (
  <CustomDatePicker picker="month" {...props} />
);

export default CustomMonthPicker;
