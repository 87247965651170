import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { Button } from 'antd';
import classNames from 'classnames';
import type { ComponentType, FC } from 'react';
import { IconCopy } from '../../Icon';
import { copyIntoClipBoard } from '../../utils/Utils';

const { Group: ButtonGroup } = Button;

interface Props {
  label: React.ReactNode;
  link: string;
  disabled?: boolean;
  copyLink?: string;
  icon?: ComponentType<{ className?: string }>;
  useMonospaceFont?: boolean;
  className?: string;
}

export const CopyButton: FC<Props> = ({
  label,
  link,
  disabled = false,
  copyLink = link,
  icon: IconComponent = null,
  useMonospaceFont = false,
  className,
}) => {
  return (
    <Container>
      <ButtonGroup>
        <Button
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          disabled={disabled}
          className={classNames(
            { 'monospace-font-button': useMonospaceFont },
            'copy-button-link-button',
            className,
          )}
        >
          <div
            className={classNames({ 'monospace-font': useMonospaceFont })}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {IconComponent != null && <IconComponent className="icon-copy-btn" />}
            <div className="copy-button-label">{label}</div>
          </div>
        </Button>
        <Button
          onClick={() => {
            copyIntoClipBoard(copyLink);
          }}
          title="Copy URL to clipboard"
          disabled={disabled}
          className="copy-button-action-button"
        >
          <IconCopy />
        </Button>
      </ButtonGroup>
    </Container>
  );
};

const Container = styled.div`
  .monospace-font-button {
    padding: 0;
  }

  .monospace-font {
    border: solid 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
    height: calc(100% - 4px);
    padding: 0 5px;
    border-radius: 0.25rem;
    background: white;
    color: ${RacemapColors.PaleBlue};
  }
`;
