import styled from '@emotion/styled';
import { Badge as BadgeRaw } from 'react-bootstrap';
import { IconExclamation, IconFail, IconOk } from '../../Icon';
import { type Section, type Sections, TabState } from './Sidebar';

export function getSection(
  sectionKey: string | number | undefined,
  sections: Sections,
): Section | undefined {
  if (sectionKey == null) return undefined;
  return sections.find((s) => s.key === sectionKey);
}

export function getStateIcon(state?: TabState): React.ReactNode {
  switch (state) {
    case TabState.ACTIVATED:
      return (
        <Badge variant="success">
          <IconOk />
        </Badge>
      );
    case TabState.ENABLED:
      return (
        <Badge
          style={{ color: '#62bd19', backgroundColor: 'transparent', border: '2px solid #62bd19' }}
          variant="success"
        >
          <IconOk />
        </Badge>
      );
    case TabState.ERROR:
      return (
        <Badge variant="danger">
          <IconFail />
        </Badge>
      );
    case TabState.WARNING:
      return (
        <Badge variant="warning">
          <IconExclamation />
        </Badge>
      );
    default:
      return <></>;
  }
}

const Badge = styled(BadgeRaw)`
  aspect-ratio: 1;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
