import classNames from 'classnames';
import { useState } from 'react';
import { Badge, Button, Card, Col, Collapse, Row } from 'react-bootstrap';
import { IconArrowDown, IconArrowRight, IconCheckCircle, IconExclamationTriangle } from './Icon';

export default function SmartPanel({
  title,
  children,
  groupHint = null,
  disabled = false,
  isActivated = false,
  hint = false,
  withActivation = true,
  preOpened = false,
  isFilled = false,
  showPrice = false,
  price = null,
  onChange,
  onExpand = () => {},
  onCollapse = () => {},
}: {
  title: string;
  children: React.ReactNode;
  groupHint?: React.ReactNode;
  disabled?: boolean;
  isActivated?: boolean;
  hint?: boolean;
  withActivation?: boolean;
  preOpened?: boolean;
  isFilled?: boolean;
  showPrice?: boolean;
  price?: string | null;
  onChange?: (newValue: boolean) => void;
  onExpand?: () => void;
  onCollapse?: () => void;
}) {
  const [expanded, setExpanded] = useState(preOpened);

  const handleChange = async (newValue: boolean) => {
    onChange(newValue);
  };

  const handleHeaderClick = () => {
    if (!disabled) {
      setExpanded(!expanded);
      if (expanded) onCollapse();
      if (!expanded) onExpand();
    }
  };

  return (
    <Card className={classNames([{ disabled: disabled }])}>
      <Card.Header onClick={handleHeaderClick}>
        <Row>
          <Col sm={price != null && showPrice ? 6 : 8}>
            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              {expanded ? <IconArrowDown /> : <IconArrowRight />}
              <div style={{ marginLeft: '6px' }}>{title}</div>
              <div style={{ marginLeft: '30px' }}>
                {isActivated && hint && (
                  <Badge variant="warning">
                    {' '}
                    <IconExclamationTriangle />{' '}
                  </Badge>
                )}
              </div>
            </div>
            {groupHint}
          </Col>
          {price != null && showPrice && <Col sm={6}>{price}</Col>}
          {isFilled && (
            <Col sm={4} style={{ textAlign: 'right' }}>
              <IconCheckCircle style={{ color: '#28a745' }} />
            </Col>
          )}
          {isActivated && (
            <Col sm={4} style={{ textAlign: 'right' }}>
              <Badge variant="primary">ACTIVATED</Badge>
            </Col>
          )}
        </Row>
      </Card.Header>
      <Collapse in={expanded}>
        <Card.Body>
          {children}
          {withActivation && (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                style={{ width: '100px' }}
                type="submit"
                onClick={() => handleChange(!isActivated)}
              >
                {isActivated ? 'Deactivate' : 'Activate'}
              </Button>
            </div>
          )}
        </Card.Body>
      </Collapse>
    </Card>
  );
}
