import type { FC } from 'react';
import { Button as BSButton, type ButtonProps } from 'react-bootstrap';
import { IconFail, IconPlus, IconPowerOff, IconTrash } from './Icon';

export interface Props extends ButtonProps {
  label: React.ReactNode;
  icon?: React.ReactNode;
}

interface ButtonType extends FC<Props> {
  Plus: FC<Props>;
  Close: FC<Props>;
  PowerOff: FC<Props>;
  Trash: FC<Props>;
}

const Button = (({ label, icon, ...props }) => {
  return (
    <BSButton {...props}>
      {icon != null && (
        <>
          {icon}
          &nbsp;
        </>
      )}
      {label}
    </BSButton>
  );
}) as ButtonType;

Button.Close = function ButtonClose(props) {
  return <Button {...props} icon={<IconFail />} />;
};

Button.Plus = function ButtonPlus(props) {
  return <Button {...props} icon={<IconPlus />} />;
};

Button.PowerOff = function ButtonPowerOff(props) {
  return <Button {...props} icon={<IconPowerOff />} />;
};

Button.Trash = function ButtonTrash(props) {
  return <Button {...props} icon={<IconTrash />} />;
};

export default Button;
