import styled from '@emotion/styled';
import type { ObjectId } from '@racemap/sdk/schema/base';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { Flex, Typography } from 'antd';
import type { FC } from 'react';
import { useUserInfo } from '../../../lib/customHooks';
import { Plg } from '../Placeholder';
import { UserAvatar } from './UserAvatar';

const { Text } = Typography;

interface Props {
  userId: ObjectId | null;
  email?: string;
  withAvatar?: boolean;
}

export const UserInfos: FC<Props> = ({ userId, email, withAvatar = true }) => {
  const { user, error, isLoading, isUnknownUser } = useUserInfo(userId, email);

  if (isLoading && user === undefined) return <Placeholder withAvatar={withAvatar} />;
  if (isUnknownUser)
    return <UserInfoEntry withAvatar={withAvatar} email={email || ''} name="Unknown" disabled />;
  if (error) return <ErrorVersion withAvatar={withAvatar} />;
  if (user == null) return null;

  return <UserInfoEntry withAvatar={withAvatar} name={user.name} email={user.email} />;
};

const UserInfoEntry = ({
  withAvatar = true,
  name,
  email,
  disabled = false,
}: { withAvatar?: boolean; name?: string; email: string; disabled?: boolean }): JSX.Element => {
  return (
    <Container disabled={disabled} onClick={(e) => disabled && e.stopPropagation()}>
      {withAvatar && <UserAvatar />}
      <UserData>
        <strong>{name || ''}</strong>
        <EmailField>{email}</EmailField>
      </UserData>
    </Container>
  );
};

const Placeholder = ({ withAvatar }: { withAvatar: boolean }): JSX.Element => {
  return (
    <Container>
      {withAvatar && <UserAvatar />}
      <UserData>
        <Plg w="150px" />
        <Plg w="200px" />
      </UserData>
    </Container>
  );
};

const ErrorVersion = ({ withAvatar }: { withAvatar: boolean }): JSX.Element => {
  return (
    <Container>
      {withAvatar && <UserAvatar />}
      <UserData>
        <Text strong type="danger">
          Error
        </Text>
        <EmailField color={RacemapColors.BaseRed}>Failed to load user data</EmailField>
      </UserData>
    </Container>
  );
};

const Container = styled(Flex)<{ disabled?: boolean }>`
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  width: 100%;
  gap: 8px;
`;

const UserData = styled.div`
  display: flex;
  flex-direction: column;
`;

const EmailField = styled.div<{ color?: string }>`
  color: ${({ color }) => color || RacemapColors.DarkGray};
`;
