import type { ObjectId } from '@racemap/sdk/schema/base';
import type { Document } from 'mongoose';
import type { UserDocument } from './users';

export enum EntityEventType {
  SOS = 'SOS',
  NO_MOVEMENT = 'NO_MOVEMENT',
  AWAY_FROM_TRACK = 'AWAY_FROM_TRACK',
  LOW_BATTERY = 'LOW_BATTERY',
  UNKNOWN = 'UNKNOWN',
  POWER_OFF = 'POWER_OFF',
  CRASH = 'CRASH',
  HEARTBEAT = 'HEARTBEAT',
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
  REBOOT = 'REBOOT',
  DEVICE_INFO = 'DEVICE_INFO',
}

export enum EntityType {
  STARTERS = 'STARTERS',
  DEVICES = 'DEVICES',
  EVENTS = 'EVENTS',
}

export const EntityEventLabels: Record<EntityEventType, string> = {
  [EntityEventType.SOS]: 'SOS',
  [EntityEventType.NO_MOVEMENT]: 'No movement',
  [EntityEventType.AWAY_FROM_TRACK]: 'Away from track',
  [EntityEventType.LOW_BATTERY]: 'Low battery',
  [EntityEventType.POWER_OFF]: 'Power off',
  [EntityEventType.UNKNOWN]: 'Unknown',
  [EntityEventType.CRASH]: 'Crash/Fall Accident',
  [EntityEventType.HEARTBEAT]: 'Heartbeat',
  [EntityEventType.OFFLINE]: 'Offline',
  [EntityEventType.ONLINE]: 'Online',
  [EntityEventType.REBOOT]: 'Reboot',
  [EntityEventType.DEVICE_INFO]: 'Device info',
};

export interface EntityEventRaw {
  entityType: EntityType;
  eventType: EntityEventType;
  location?: {
    lat?: number;
    lng?: number;
  };
  information?: string;
}

export type EntityEventDocument = EntityEventRaw &
  Document & {
    creatorId?: ObjectId;
    updaterId?: ObjectId;
    entityId: ObjectId;
    occuredAt: Date;
    createdAt?: Date;
    updatedAt?: Date;
    solvedAt?: Date | null;
    solvedBy?: ObjectId;

    canEdit: (this: EntityEventDocument, user?: UserDocument | null) => Promise<boolean>;
    canRead: (this: EntityEventDocument, user?: UserDocument | null) => Promise<boolean>;
    canSolve: (this: EntityEventDocument, user?: UserDocument | null) => Promise<boolean>;
    solve: (this: EntityEventDocument, user?: UserDocument) => Promise<void>;
  };
export interface EntityEvent extends EntityEventRaw {
  id: string;
  updaterId: string;
  occuredAt: string;
  createdAt: string;
  updatedAt: string;
  solvedAt: string | null;
  solvedBy: string | null;
  entityId: string;
  creatorId: string;
}

export type EntityEvents = Array<EntityEvent>;
