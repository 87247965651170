import {
  isAtomicEvent,
  isContestGroupEvent,
  longestCommonPrefix,
} from '@racemap/utilities/functions/utils';
import type { RacemapEvent } from '@racemap/utilities/types/events';
import { Space, Typography } from 'antd';
import type { Immutable } from 'immer';
import { DateTime } from 'luxon';
import { type FC, useMemo, useState } from 'react';
import {
  useChildEvents,
  useCurrentEvent,
  useCurrentUser,
  useEventLoads,
  useUser,
} from 'src/lib/customHooks';
import type { CurrentEvent } from 'src/store/events/events_reducers';
import { BillingLink } from '../../BasicComponents/Links';
import { LoadsChart } from '../../Charts';
import { LoadsCountTable } from '../../LoadsCountTable/LoadsCountTable';
import { Buttons } from './Buttons';

const { Text } = Typography;

export const LoadsInfo: FC = () => {
  const currentEvent = useCurrentEvent();
  const { user: creator } = useUser({ userId: currentEvent?.creatorId });
  const currentUser = useCurrentUser();
  const childEvents = useChildEvents(currentEvent?.id);
  const { loads, isLoading } = useEventLoads(currentEvent?.id);
  const [zoomStartDate, setZoomStart] = useState<Date | null>(null);
  const [zoomEndDate, setZoomEnd] = useState<Date | null>(null);
  const defaultZoomStart =
    currentEvent != null
      ? DateTime.fromISO(currentEvent.startTime).minus({ day: 1 }).toJSDate()
      : undefined;
  const defaultZoomEnd =
    currentEvent != null
      ? DateTime.fromISO(currentEvent.endTime).plus({ day: 1 }).toJSDate()
      : undefined;

  const chart = useMemo(() => {
    if (currentEvent == null) return null;
    const mapping = getEventNameMapping(childEvents, currentEvent);
    const eventTimes = getEventTimes(childEvents, currentEvent);

    return (
      <LoadsChart
        loads={loads || []}
        markLines={Object.values(eventTimes).flatMap(({ start, end, label }) => [
          {
            time: start,
            label: `${label} Start`,
          },
          {
            time: end,
            label: `${label} End`,
          },
        ])}
        zoomStart={defaultZoomStart}
        zoomEnd={defaultZoomEnd}
        interval="hourly"
        showLoading={isLoading}
        onDateRangeChange={(start, end) => {
          if (
            start != null &&
            DateTime.fromJSDate(start).isValid &&
            start?.getTime() !== defaultZoomStart?.getTime()
          ) {
            setZoomStart(start);
          }
          if (
            end != null &&
            DateTime.fromJSDate(end).isValid &&
            end?.getTime() !== defaultZoomEnd?.getTime()
          ) {
            setZoomEnd(end);
          }
        }}
        eventNameMapping={mapping}
      />
    );
  }, [currentEvent?.startTime, currentEvent?.endTime, loads, isLoading, childEvents]);

  const table = useMemo(() => {
    if (currentEvent == null) return null;
    const mapping = getEventNameMapping(childEvents, currentEvent);

    return (
      <LoadsCountTable
        loads={loads || []}
        zoomStartDate={zoomStartDate}
        zoomEndDate={zoomEndDate}
        isLoading={isLoading}
        eventNameMapping={mapping}
        eventType={currentEvent.type}
      />
    );
  }, [isLoading, childEvents, currentEvent, zoomStartDate, zoomEndDate, loads]);

  const buttons = useMemo(() => {
    if (currentEvent == null) return null;
    const mapping = getEventNameMapping(childEvents, currentEvent);

    return <Buttons loads={loads} eventNameMapping={mapping} />;
  }, [loads, childEvents, currentEvent]);

  if (currentEvent == null || currentUser == null || creator == null) return null;

  return (
    <Space direction="vertical" style={{ display: 'flex' }} size="large">
      <Text>
        Here are the loads of this Tracking Map. Check the{' '}
        <BillingLink userId={creator.id} label="billing page" /> of your account for all summarized
        loads of the current month. Each page view of maps, each loading of leaderboards or
        monitors, and each query of data API is one load. You may archive your events at anytime to
        limit loads.
      </Text>
      {chart}
      <Space direction="vertical" style={{ width: '100%' }}>
        {table}
        {buttons}
      </Space>
    </Space>
  );
};

const getEventNameMapping = (
  events: Immutable<Array<RacemapEvent>>,
  currentEvent: Immutable<CurrentEvent>,
): Record<string, string> | undefined => {
  const commonPrefix = longestCommonPrefix(events.map((event) => event.name || ''));
  const mapping =
    events.length > 0
      ? events.reduce(
          (acc, event) => {
            acc[event.id] = event.name.replace(commonPrefix, '');
            return acc;
          },
          { [currentEvent.id]: 'Group' } as Record<string, string>,
        )
      : undefined;
  return mapping;
};

const getEventTimes = (
  events: Immutable<Array<RacemapEvent>>,
  currentEvent: Immutable<CurrentEvent>,
): Record<string, { start: Date; end: Date; label: string }> => {
  if (isAtomicEvent(currentEvent) || isContestGroupEvent(currentEvent)) {
    return {
      [currentEvent.id]: {
        start: new Date(currentEvent.startTime),
        end: new Date(currentEvent.endTime),
        label: isContestGroupEvent(currentEvent) ? 'Contest' : 'Event',
      },
    };
  }
  const mapping = events.reduce(
    (acc, event, index) => {
      acc[event.id] = {
        start: new Date(event.startTime),
        end: new Date(event.endTime),
        label: `Stage ${index + 1}`,
      };
      return acc;
    },
    {
      [currentEvent.id]: {
        start: new Date(currentEvent.startTime),
        end: new Date(currentEvent.endTime),
        label: 'Group',
      },
    } as Record<string, { start: Date; end: Date; label: string }>,
  );
  return mapping;
};
