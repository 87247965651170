import type { CSSProperties } from 'react';
import { Placeholder, Animations as PlaceholderAnimations } from './Placeholder';

/**
 * Renders a Placeholder component with a specified width.
 *
 * @param {Object} props - The component props.
 * @param {CSSProperties['width']} props.w - The width of the Placeholder component.
 * @returns {JSX.Element} The rendered Placeholder component.
 */
const Plg = ({ w }: { w: CSSProperties['width'] }): JSX.Element => {
  return <Placeholder animation={PlaceholderAnimations.GLOW} width={w} />;
};

export { Plg, Placeholder, PlaceholderAnimations };
