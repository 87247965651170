import { RacemapColors } from '@racemap/utilities/consts/common';
import {
  DeviceClasses,
  DeviceStateFlag,
  EventTypes,
  PanelState,
} from '@racemap/utilities/consts/events';
import { md5ShortHash } from '@racemap/utilities/functions/md5ShortHash';
import {
  type PreparedStarterInfo,
  type StarterInfo,
  type TagsCollection,
  sortTimes,
  testEventHasVirtDuration,
} from '@racemap/utilities/functions/timing';
import {
  isEmptyString,
  isNotEmptyString,
  isStageGroupEvent,
} from '@racemap/utilities/functions/utils';
import { isDefined } from '@racemap/utilities/functions/validation';
import type { Brand, BrandPublicVersion } from '@racemap/utilities/types/brand';
import type { RacemapEvent, RacemapStarter } from '@racemap/utilities/types/events';
import { RRImportMode } from '@racemap/utilities/types/events';
import type { StarterResult } from '@racemap/utilities/types/monitor';
import { type Immutable, produce } from 'immer';
import moment from 'moment';
import type { Variant } from 'react-bootstrap/esm/types';
import type { SortDirectionType } from 'react-virtualized/dist/es/Table';
import shortHash from 'shorthash2';
import type { CurrentEvent } from '../../store/events/events_reducers';
import type { EventData } from '../../store/leaderboard/leaderboard_reducer';
import { autoImportIsActive } from '../EventEditor/ParticipantTab/utils';
import type { Column } from '../Leaderboard/Leaderboard/TableBody';

export function getRedeemKeyLink(
  starterKey: string,
  brandInfos?: Brand | BrandPublicVersion | null | Immutable<Brand>,
  startNumber?: string,
  name?: string,
): string {
  let basicUrl =
    window.location.origin === 'https://racemap.com'
      ? 'https://racemap.app'
      : `${window.location.origin}/app`;
  const appIdentifier = brandInfos?.appIdentifier;

  if (appIdentifier == null) {
    basicUrl += `/redeem_key?key=${starterKey}`;
  } else {
    basicUrl += `/${appIdentifier}/redeem_key?key=${starterKey}`;
  }

  if (startNumber != null && startNumber !== '') {
    basicUrl += `&startNumber=${startNumber}`;
  }
  if (name != null && name !== '') {
    basicUrl += `&name=${name}`;
  }

  return basicUrl;
}

export function getAppLink(brandInfos?: Brand | BrandPublicVersion | Immutable<Brand>): string {
  const basicUrl =
    window.location.origin === 'https://racemap.com'
      ? 'https://racemap.app'
      : `${window.location.origin}/app`;
  const appIdentifier = brandInfos?.appIdentifier;

  if (appIdentifier == null) {
    return basicUrl;
  }
  return `${basicUrl}/${appIdentifier}`;
}

export function getApplicationName(brand?: Brand | BrandPublicVersion | null): string | null {
  if (brand == null) return null;
  return brand?.metadata[brand?.metadata.findIndex((m) => m.default === true)].shortApplicationName;
}

export async function getItunesStoreUrl(bundleId: string): Promise<string> {
  const lookup = await fetch(`https://itunes.apple.com/lookup?bundleId=${bundleId}`);
  const text = await lookup.text();
  const content = JSON.parse(text);

  if (content.resultCount === 0 || content.results[0].trackViewUrl == null) return '';
  return content.results[0].trackViewUrl;
}

/* Request Helpers */
export const getTransponderCount = (starters: Set<RacemapStarter>): number =>
  Array.from(starters).filter(
    (starter: RacemapStarter) =>
      starter.deviceClass === DeviceClasses.Transponder ||
      starter.deviceClass === DeviceClasses.FeibotTransponder ||
      starter.deviceClass === DeviceClasses.TrackPingTransponder ||
      starter.deviceClass === DeviceClasses.ChronoTrackTransponder,
  ).length;

export const triggerEnterEvent = (type: 'keydown' | 'keyup', targetId: string): void => {
  const target = document.getElementById(targetId);
  const ev = new KeyboardEvent(type, {
    altKey: false,
    bubbles: true,
    cancelable: true,
    charCode: 0,
    code: 'Enter',
    composed: true,
    ctrlKey: false,
    detail: 0,
    isComposing: false,
    key: 'Enter',
    keyCode: 13,
    location: 0,
    metaKey: false,
    repeat: false,
    shiftKey: false,
  });

  if (target == null) return;
  target.dispatchEvent(ev);
};

export function testStarterHasValidStartFlag(starter: Immutable<StarterInfo>): boolean {
  return starter?.state != null && Object.values(DeviceStateFlag).includes(starter.state);
}

function filterStarterPerTags<T extends StarterInfo | StarterResult | PreparedStarterInfo>(
  starter: Immutable<T>,
  selectedTags: Immutable<TagsCollection>,
): boolean {
  // no tags selected
  if (selectedTags.size < 1) return true;

  let oneTagColumnVisible = false;
  for (const [key, values] of selectedTags.entries()) {
    // filter all starter, that dont fit to the selected tags
    if (values.length > 0) {
      // starter has no tags (filterd)
      if (starter.tags == null) return false;

      const starterTag = starter.tags[key];
      // starter has not the requested tag group (continue)
      if (starterTag == null) continue;

      // starter has tags but not requested
      if (!values.find((t) => t.value === starterTag)) return false;
      oneTagColumnVisible = true;
    }
  }
  return oneTagColumnVisible;
}

export function getFilteredStarters<T extends StarterInfo | StarterResult | PreparedStarterInfo>(
  starters: Immutable<Array<T>>,
  filterText: string,
  showOnlyOnline: boolean,
  selectedTags?: Immutable<TagsCollection>,
): Immutable<Array<T>> {
  if (filterText === '' && !showOnlyOnline && selectedTags?.size === 0) return [...starters];

  // find the words in the filter text and make a
  // regex based on it
  const wordsMatch = filterText.match(/\w+/gi);
  const words = wordsMatch != null ? wordsMatch.map((element) => element.toLowerCase()) : [];
  const pattern = `(${words
    // only unique values
    .filter((value, index, self) => self.indexOf(value) === index)
    .join('|')})`;
  const regexp = new RegExp(pattern, 'igm');

  return starters.filter((starter) => {
    const startNumber = starter.startNumber != null ? starter.startNumber : '';
    const name = starter.name != null ? starter.name : '';

    if (showOnlyOnline && !starter.online) return false;
    if (filterText !== '' && (startNumber + name).toLocaleLowerCase().search(regexp) === -1)
      return false;
    if (selectedTags != null) {
      return filterStarterPerTags(starter, selectedTags);
    }
    return starter;
  });
}

export function getStarterStageResults(
  events: Immutable<Map<string, Immutable<EventData>>>,
): Map<string, Map<string, Immutable<StarterInfo>>> {
  const totalStageResults = new Map<string, Map<string, Immutable<StarterInfo>>>();

  for (const event of Array.from(events.values())) {
    for (const starter of event.starterData.starters) {
      const stageResults =
        totalStageResults.get(starter.id) || new Map<string, Immutable<StarterInfo>>();

      stageResults.set(event.eventObject.id, starter);
    }
  }

  return totalStageResults;
}

export function getSortedStarters(
  starters: Immutable<Array<PreparedStarterInfo>>,
  sorting: { key: string; order: SortDirectionType } | null,
  columns: Array<Column>,
  events: Immutable<Map<string, EventData>>,
  selectedEventData: Immutable<EventData>,
) {
  const sortedStarters = [...starters];

  if (sorting != null) {
    const sortingColumn = columns.find((col) => col.key === sorting.key);
    if (sortingColumn != null && sortingColumn.sorter != null) {
      const sorter = sortingColumn.sorter;
      sortedStarters.sort((a, b) => (sorting.order === 'ASC' ? sorter(a, b) : sorter(b, a)));
    }

    return sortedStarters;
  }

  if (isStageGroupEvent(selectedEventData.eventObject)) {
    const eventsArray = Array.from(events.values());
    const firstStageEvent = eventsArray.find((e) => e.eventObject.type === EventTypes.STAGE);
    if (firstStageEvent == null) return sortedStarters;

    const starterResults = new Map(firstStageEvent.starterData.starters.map((s) => [s.id, s]));
    const eventHasVirtDuration = testEventHasVirtDuration(firstStageEvent.eventObject);

    sortedStarters.sort((a, b) => {
      const resA = starterResults.get(a.id);
      const resB = starterResults.get(b.id);
      const progressA = resA?.progress || -1;
      const progressB = resB?.progress || -1;

      return eventHasVirtDuration ? sortTimes(progressB, progressA) : progressB - progressA;
    });

    return sortedStarters;
  }

  sortedStarters.sort((a, b) => {
    if (testStarterHasValidStartFlag(a)) {
      if (testStarterHasValidStartFlag(b)) {
        return b.progress - a.progress;
      }
      return 1;
    }
    if (testStarterHasValidStartFlag(b)) {
      return -1;
    }
    return a.rank - b.rank || b.progress - a.progress;
  });

  return sortedStarters;
}

export function getTagsWithCounts(
  starters: Immutable<Array<PreparedStarterInfo>>,
  possibleTags: Immutable<TagsCollection>,
  selectedTags: Immutable<TagsCollection>,
): TagsCollection {
  return produce(possibleTags, (tags) => {
    // prepare a matrix easy store the values of the counts
    const tagCountMatrix: Record<string, Record<string, number>> = {};
    for (const [tagKey, tagValues] of possibleTags.entries()) {
      for (const tagValue of tagValues) {
        if (tagCountMatrix[tagKey] == null) tagCountMatrix[tagKey] = {};
        tagCountMatrix[tagKey][tagValue.value] = 0;
      }
    }

    for (const starter of starters) {
      if (starter.tags == null) continue;

      for (const [possibleTagKey] of possibleTags.entries()) {
        const starterTag = starter.tags[possibleTagKey];
        if (starterTag == null) continue;

        const selectedTagWithCurrentValue = produce(selectedTags, (sT) => {
          sT.get(possibleTagKey)?.push({ value: starterTag });
        });
        const starterCountIfChoosed = filterStarterPerTags(starter, selectedTagWithCurrentValue);

        if (starterCountIfChoosed) tagCountMatrix[possibleTagKey][starterTag] += 1;
      }

      for (const [tagKey, tagValues] of tags.entries()) {
        for (const tagValue of tagValues) {
          tagValue.count = tagCountMatrix[tagKey][tagValue.value];
        }
      }
    }
  }) as TagsCollection;
}

export const getStatusColor = (status?: Variant): string | undefined => {
  switch (status) {
    case 'primary':
      return RacemapColors.PaleBlue;
    case 'secondary':
      return RacemapColors.CloudBlue;
    case 'success':
      return RacemapColors.BaseGreen;
    case 'danger':
      return RacemapColors.DarkRed;
    case 'warning':
      return RacemapColors.DarkYellow;
    case 'info':
      return RacemapColors.PaleBlue;
    case 'dark':
      return RacemapColors.DarkBlue;
    case 'light':
      return RacemapColors.DarkYellow;
    default:
      return undefined;
  }
};

export default {
  getTransponderCount,
  triggerEnterEvent,
};

export function isEventEndAfterStartTime(
  event: Immutable<RacemapEvent> | Immutable<CurrentEvent> | null,
): event is (Immutable<RacemapEvent> | Immutable<CurrentEvent>) & {
  endTime: string;
  startTime: string;
} {
  if (event == null) throw new Error('Event is null');
  if (event.startTime == null) throw new Error('Event has no start time');
  if (event.endTime == null) throw new Error('Event has no end time');

  return Date.parse(event.endTime.toString()) > Date.parse(event.startTime.toString());
}

export function getMetaDataStatus(event: Immutable<RacemapEvent> | Immutable<CurrentEvent> | null) {
  if (event == null) return PanelState.NONE;
  return isNotEmptyString(event.name) &&
    isNotEmptyString(event.location) &&
    isEventEndAfterStartTime(event)
    ? Date.parse(event.endTime) > Date.now() &&
      Date.parse(event.endTime) > Date.parse(event.startTime)
      ? PanelState.SUCCESS
      : PanelState.PRIMARY
    : PanelState.NONE;
}

export function evaluateFirstTime(
  event: Immutable<RacemapEvent | CurrentEvent> | null,
  starter: Immutable<RacemapStarter>,
): { timeClass: string; hint: string } {
  const startTime = starter.startTime;
  const momentTime = moment(startTime || starter.times.start || event?.startTime);
  const momentEventStart = moment(event?.startTime);
  const momentEventEnd = moment(event?.endTime);

  let timeClass = 'text-secondary';
  let hint = '';

  if (starter.startTime == null && starter.times.start == null) {
    return { timeClass, hint };
  }

  timeClass = 'text-success';
  hint = '';
  if (momentEventEnd.isSame(momentTime)) {
    timeClass = 'text-warning';
    hint =
      'Starter time is same as event end time. This is very unlikely! Shure your timing went well?';
  }

  if (momentTime.isAfter(moment())) {
    timeClass = 'text-warning';
    hint =
      'The used first time is in the future, that means currently no data will be processed in the map or leaderboard.';
  }
  if (momentTime.isBefore(momentEventStart) || momentTime.isAfter(momentEventEnd)) {
    timeClass = 'text-danger';

    hint = 'Starter time is not within event time frame.';
  }
  if (momentTime.isAfter(moment(starter.endTime || starter.times.end || event?.endTime))) {
    timeClass = 'text-danger';
    hint = 'The first time is after the last time.';
  }

  return { timeClass, hint };
}

export function evaluateLastTime(
  event: Immutable<RacemapEvent | CurrentEvent> | null,
  starter: Immutable<RacemapStarter>,
): { timeClass: string; hint: string } {
  const endTime = starter.endTime;
  const momentTime = moment(endTime || starter.times.end || event?.endTime);
  const momentEventStart = moment(event?.startTime);
  const momentEventEnd = moment(event?.endTime);

  let timeClass = 'text-secondary';
  let hint = '';

  if (starter.endTime == null && starter.times.end == null) {
    return { timeClass, hint };
  }

  timeClass = 'text-success';
  hint = '';
  if (momentEventEnd.isSame(momentTime)) {
    timeClass = 'text-warning';
    hint =
      'Starter time is same as event end time. This is very unlikely! Shure your timing went well?';
  }

  if (momentTime.isBefore(momentEventStart) || momentTime.isAfter(momentEventEnd)) {
    timeClass = 'text-danger';
    hint = 'Starter time is not within event time frame.';
  }
  if (momentTime.isBefore(moment(starter.startTime || starter.times.start || event?.startTime))) {
    timeClass = 'text-danger';
    hint = 'The last time is before the first time.';
  }

  return { timeClass, hint };
}

export function isSyncedStarter(
  starter: Immutable<RacemapStarter>,
  event: Immutable<RacemapEvent | CurrentEvent>,
): boolean {
  const importIsActive = autoImportIsActive(event);
  if (!importIsActive) return false;

  return (
    isImportingPerGenericUrl(starter, event) ||
    isImportingPerRRCustomUrl(starter, event) ||
    isImportingPerRREventId(starter, event)
  );
}

function isImportingPerRREventId(
  starter: RacemapStarter,
  event: Immutable<RacemapEvent | CurrentEvent>,
): boolean {
  if (
    starter.integrations?.raceresultKey == null ||
    isEmptyString(starter.integrations?.raceresultKey) ||
    (!event.integrations.raceresult.isActive &&
      event.integrations.raceresult.importMode !== RRImportMode.EVENT_ID)
  )
    return false;

  const importId = getRRImportId(starter);
  return isDefined(importId) && importId === event.integrations.raceresult.eventId;
}

function isImportingPerRRCustomUrl(
  starter: RacemapStarter,
  event: Immutable<RacemapEvent | CurrentEvent>,
): boolean {
  if (
    starter.integrations?.raceresultKey == null ||
    isEmptyString(starter.integrations?.raceresultKey) ||
    (!event.integrations.raceresult.isActive &&
      event.integrations.raceresult.importMode !== RRImportMode.CUSTOM_URL)
  )
    return false;

  const importUrlHash = getRRImportUrlHash(starter);
  return (
    isDefined(importUrlHash) &&
    importUrlHash === md5ShortHash(event.integrations.raceresult.starterListUrl)
  );
}

function isImportingPerGenericUrl(
  starter: RacemapStarter,
  event: Immutable<RacemapEvent | CurrentEvent>,
): boolean {
  if (
    starter.integrations?.genericImportKey == null ||
    isEmptyString(starter.integrations?.genericImportKey) ||
    !event.integrations.genericStarterImport.isActive
  )
    return false;

  const importUrlHash = getGenericImportUrlHash(starter);
  return (
    isDefined(importUrlHash) &&
    event.integrations.genericStarterImport.sources
      .map((s) => shortHash(s.sourceURL))
      .includes(importUrlHash)
  );
}

const getRRImportId = (starter: Immutable<RacemapStarter>): string | null => {
  return starter.integrations?.raceresultKey?.split('_')[1] || null;
};
const getRRImportUrlHash = (starter: Immutable<RacemapStarter>): string | null => {
  return starter.integrations?.raceresultKey?.split('_')[1] || null;
};
const getGenericImportUrlHash = (starter: Immutable<RacemapStarter>): string | null => {
  return starter.integrations?.genericImportKey?.split('_')[1] || null;
};
