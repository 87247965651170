import type { StringUnitLength } from 'luxon';
import { useState } from 'react';
import { useInterval } from '../../../lib/customHooks';
import { type TimeVariantInput, getRelativeTime } from '../../utils/timeUtils';

type Props = {
  time: TimeVariantInput;
  baseTime?: TimeVariantInput;
  size?: StringUnitLength;
};

export const RelativeTime: React.FC<Props> = ({ time, baseTime, size = 'long' }) => {
  const [relativeTime, setRelativeTime] = useState<string | null>();

  const updateRelativeTime = () => {
    const updatedRelativeTime = getRelativeTime(time, baseTime, size);
    setRelativeTime(updatedRelativeTime);
  };

  useInterval(async () => updateRelativeTime(), 1000);

  return <span>{relativeTime}</span>;
};
