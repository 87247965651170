import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import type { FC } from 'react';
import { Platforms, usePlatform } from '../../../lib/customHooks';
import { IconSearch } from '../../Icon';
import { HStack } from '../MetaComponent';

interface Props {
  onClick: () => void;
  text: string;
}

export const PseudoInput: FC<Props> = ({ onClick, text }) => {
  const platform = usePlatform();

  let hotkeys = null;
  switch (platform) {
    case Platforms.MAC:
      hotkeys = (
        <HStack gap="5px">
          <HotKey>K</HotKey>+<HotKey>⌘</HotKey>
        </HStack>
      );
      break;
    case Platforms.WINDOWS:
      hotkeys = (
        <HStack gap="2px">
          <HotKey>K</HotKey>+<HotKey>Ctrl</HotKey>
        </HStack>
      );
      break;
  }

  return (
    <Container onClick={onClick}>
      <IconAndText>
        <IconSearch />
        {text}
      </IconAndText>

      {hotkeys}
    </Container>
  );
};

const Container = styled.div`
    background-color: ${RacemapColors.LightGray};
    width: 100%;
    padding: 3px 8px;
    height: 33.5px;
    border-radius: 5px;
    border: 1px solid ${RacemapColors.Gray};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    &:hover {
        background-color: ${RacemapColors.Gray};
    }
`;

const IconAndText = styled(HStack)`
    gap: 5px;
    color: ${RacemapColors.DarkGray};
`;

const HotKey = styled.div`
    height: 23px;
    font-weight: 500;
    border-radius: 3px;
    border: 1px solid ${RacemapColors.Gray};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    min-width: 23px;
    padding: 3px;
`;
