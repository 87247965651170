import type { Device, GenericImportStarterRecord } from '../../types/events';

export type GenericImportSource<T = GenericImportStarterRecord> =
  | Array<T>
  | { starters: Array<T> }
  | { root: { starters: Array<T> } };

export function unwrapStarters(
  source: GenericImportSource<GenericImportStarterRecord> | null,
): Array<GenericImportStarterRecord> {
  if (Array.isArray(source)) {
    return source;
  }

  if (source != null && 'starters' in source && Array.isArray(source.starters)) {
    return source.starters;
  }

  if (
    source != null &&
    'root' in source &&
    'starters' in source.root &&
    Array.isArray(source.root.starters)
  ) {
    // only necessay for xml files
    source.root.starters.forEach((starter) => {
      if (
        typeof starter.devices === 'object' &&
        starter.devices != null &&
        !Array.isArray(starter.devices)
      )
        starter.devices = [starter.devices as Device];
    });

    return source.root.starters;
  }

  throw new Error('Invalid source');
}
