import { ObjectId } from '@racemap/sdk/schema/base';
import { AtomicEvents, EventTypes, VisibilityStates } from '@racemap/utilities/consts/events';
import {
  isAtomicEvent,
  isChildEvent,
  isContestGroupEvent,
} from '@racemap/utilities/functions/utils';
import type { RacemapContestGroup, RacemapEventCommon } from '@racemap/utilities/types/events';
import type { PathInto, TypeOfPath } from '@racemap/utilities/types/utils';
import { Typography } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useBrands, useCurrentEvent, useIsAdmin } from '../lib/customHooks';
import { useStore } from '../store/reducers';
import AddEditors from './AddEditors';
import { AddEventToCustomBrands } from './AddEventToCustomBrands';
import { ImageDropView } from './BasicComponents/ImageDropView';
import { PopoverHint } from './BasicComponents/PopoverHint';
import { ChangeCreator } from './ChangeCreator';
import { LoadsInfo } from './EventEditor/AnalyticsTab/LoadsInfo';
import FieldGroup from './FieldGroup';
import { BlurFormInput } from './FormComponents';
import { GroupEventList } from './GroupEventList';
import RemoveContestGroup from './RemoveContestGroup';
import SearchEvent from './SearchEvent';
import VisibilitySection from './VisibilitySection';
import { findLastDateAndFirstDate } from './utils/preprocess';

const { Title } = Typography;

export function ContestGroupEditor() {
  const contestGroup = useCurrentEvent();
  const isAdmin = useIsAdmin();
  const { brands } = useBrands();
  const { updateEvent, loadEvents } = useStore((s) => s.events.actions);
  const groupEvents = useStore((s) =>
    contestGroup != null
      ? Array.from(s.events.items.values()).filter(
          (e) => isChildEvent(e) && e.parent === contestGroup.id,
        )
      : [],
  );

  useEffect(() => {
    (async () => {
      await loadGroupEvents();
    })();
  }, []);

  if (contestGroup == null || !isContestGroupEvent(contestGroup)) return <></>;

  const slug = contestGroup.slug;
  const dateFormat = 'HH:mm dddd, D.M.Y';

  const onChangeContestGroupField = async <K extends PathInto<RacemapContestGroup>>(
    key: K,
    newValue: TypeOfPath<RacemapContestGroup, K>,
  ) => {
    if (key === 'childEvents') return;
    updateEvent(contestGroup.id, [{ key, newValue }]);
  };

  const loadGroupEvents = async () => {
    await loadEvents({ findByParentId: contestGroup.id });
  };

  const getStartTimeAndEndTimeExtends = () => {
    const [firstDate, lastDate] = findLastDateAndFirstDate(contestGroup?.childEvents);
    if (firstDate == null || lastDate == null) return ['', ''];
    return [moment(lastDate).format(dateFormat), moment(firstDate).format(dateFormat)];
  };

  const getIgnoreList = (): Array<string> => {
    return groupEvents.map((e) => e.id);
  };

  const handleEventFound = async (newChild: RacemapEventCommon | null) => {
    if (newChild == null) return;
    if (!isAtomicEvent(newChild)) return;

    const index = groupEvents.length > 0 ? (groupEvents[groupEvents.length - 1].index || 0) + 1 : 0;
    await updateEvent(newChild.id, [
      { key: 'index', newValue: index },
      { key: 'parent', newValue: contestGroup.id },
      { key: 'type', newValue: EventTypes.CONTEST },
    ]);

    await loadGroupEvents();
  };

  if (contestGroup == null) return <div />;
  const [lastDate, firstDate] = getStartTimeAndEndTimeExtends();
  const ignoreList = getIgnoreList();

  return (
    <Container className="mb-5">
      <form>
        <Row>
          <Col lg={12}>
            <h1>
              Edit contest group <small>Stack multiple contests in one</small>
            </h1>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            {slug != null && (
              <p>
                Map:&nbsp;
                <a href={`/player/${slug}`} target="_blank" rel="noopener noreferrer">
                  {`${window.location.protocol}//${window.location.host}/player/${slug}`}
                </a>{' '}
                <PopoverHint placement="bottom">
                  On desktop applications, the group URL preselects specific single events,
                  automatically. During the event, ALL RUNNING single events in the group are
                  selected, automatically. After the event, the 1st single event in group settings
                  is selected, automatically.
                </PopoverHint>
              </p>
            )}
            <p>
              Stack multiple Tracking Maps To summarize specific events in one map, e.g. for staged
              events and for events with several contests (marathon, half marathon etc.). This way
              spectators access multiple tracking maps under one link.
            </p>
          </Col>
        </Row>
        {}
        <h4>Basic Settings</h4>
        <Card>
          <Card.Body>
            <Row>
              <Col lg={4}>
                <BlurFormInput
                  id="contestGroupName"
                  type="text"
                  label="Name"
                  required
                  placeholder="Enter the name of the ContestGroup"
                  value={contestGroup.name}
                  onChange={(newValue: string) => onChangeContestGroupField('name', newValue)}
                />

                <BlurFormInput
                  id="contestGroupLocation"
                  type="text"
                  label="Location"
                  required
                  placeholder="Enter the location"
                  value={contestGroup.location}
                  onChange={(newValue: string) => onChangeContestGroupField('location', newValue)}
                />

                <Title level={5}>Visibility</Title>
                <VisibilitySection
                  value={contestGroup.visibility}
                  onChange={(newValue) => onChangeContestGroupField('visibility', newValue)}
                  authorized
                  possibleStates={[
                    VisibilityStates.LISTED,
                    VisibilityStates.UNLISTED,
                    VisibilityStates.ARCHIVED,
                  ]}
                  target="contestGroup"
                />
              </Col>
              <Col lg={4} className="pull-right">
                <fieldset className="pull-right">
                  <h3>
                    Logo for Event
                    <PopoverHint title="Logo for Event">
                      Racemap shows square logos as jpg or png files. You drag and drop the logo
                      into the box. You can always update the logo.
                    </PopoverHint>
                  </h3>
                  <ImageDropView
                    value={contestGroup.images}
                    onChange={(images) => onChangeContestGroupField('images', images)}
                  />
                </fieldset>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {contestGroup.editorIds != null && (
          <>
            <h4 style={{ marginTop: '20px' }}>Info</h4>
            <Card>
              <Card.Body>
                <Row>
                  <Col lg={12}>
                    <AddEditors
                      value={contestGroup.editorIds.map((id) => new ObjectId(id))}
                      onChange={(newEditorIds) => {
                        onChangeContestGroupField(
                          'editorIds',
                          newEditorIds.map((id) => id.toHexString()),
                        );
                      }}
                    />
                    {isAdmin && (
                      <ChangeCreator
                        value={new ObjectId(contestGroup.creatorId)}
                        onChange={(newCreatorId) => {
                          onChangeContestGroupField('creatorId', newCreatorId.toHexString());
                        }}
                      />
                    )}
                  </Col>
                </Row>
                {isAdmin && (
                  <Row>
                    <Col lg={12} className="mt-2">
                      <FieldGroup
                        id="contestGroupSlug"
                        label="Group Slug"
                        required
                        placeholder="Enter the slug of the ContestGroup"
                        value={contestGroup.slug}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          onChangeContestGroupField('slug', event.target.value)
                        }
                      />
                    </Col>
                  </Row>
                )}
              </Card.Body>
            </Card>
          </>
        )}
        <h4 style={{ marginTop: '20px' }}>Contests</h4>
        <Card>
          <Card.Body>
            <Row>
              <Col lg={12} className="mb-2">
                <SearchEvent
                  onSelect={handleEventFound}
                  filter="can-edit"
                  eventFilter={(e) => {
                    return AtomicEvents.includes(e.type);
                  }}
                  ignoreList={ignoreList}
                  emptyAfterChoose={true}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <GroupEventList />
              </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
              <Col lg={4}>
                <FieldGroup
                  id="contestGroupStartTime"
                  label="Starttime"
                  disabled
                  value={firstDate}
                />
              </Col>

              <Col lg={4}>
                <FieldGroup id="contestGroupEndTime" label="Endtime" disabled value={lastDate} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <h4 style={{ marginTop: '20px' }}>Loads</h4>
        <Card>
          <Card.Body>
            <LoadsInfo />
          </Card.Body>
        </Card>
        <Row>
          {isAdmin && (
            <Col lg={12}>
              <h4 style={{ marginTop: '20px' }}>Danger Zone</h4>
              <Card>
                <Card.Body>
                  <Row>
                    <Col lg={12}>
                      <RemoveContestGroup
                        contestGroupId={contestGroup.id}
                        className="btn-danger"
                        style={{ marginRight: '5px' }}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>
      </form>
      {!!brands?.length && (
        <section>
          <h4 style={{ marginTop: '20px' }}>Maintenance</h4>
          <Card>
            <Card.Body>
              <Row>
                <Col md={6}>
                  <AddEventToCustomBrands event={contestGroup} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </section>
      )}
    </Container>
  );
}
