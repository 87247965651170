import { HAIRSP } from '@racemap/utilities/consts/characters';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { UnitType } from '@racemap/utilities/consts/events';
import { shuffleArray } from '@racemap/utilities/functions/utils';
import type { PointRust } from '@racemap/utilities/types/trackping';
import { PingType } from '@racemap/utilities/types/types';
import tinyColor from 'tinycolor2';
import { IconCalculator, IconClock, IconFail, IconLocation } from '../../Icon';

export const SHADOW_TRACK_STEP = 10;

const colorList = shuffleArray([
  ...tinyColor('#af0').monochromatic(),
  ...tinyColor('#f73').monochromatic(),
  ...tinyColor('#3af').monochromatic(),
  ...tinyColor('#f024ff').monochromatic(),
]);

export const colorTable = {
  liveColor: '#ffa500',
  liveSpeedColor: '#ff7204',
  replayColor: '#15a49f',
  replaySpeedColor: RacemapColors.BaseGreen,
  estimationColor: '#f87a6d',
  estimationSpeedColor: '#ff4444',
  accPingColor: RacemapColors.LightGreen,
  rejPingColor: RacemapColors.BaseRed,
  compensationPointColor: '#000000',
  measuredSpeedColor: '#78c964',
  averagedSpeedColor: '#57bc3e',
  elevationColor: RacemapColors.PaleBlue,
  defSpeedColor: '#541464',
};

export function calculateVelocityInKMH(a: PointRust, b: PointRust) {
  if (b.wasJump) {
    return 0;
  }
  return ((b.offset - a.offset) / (Date.parse(b.timestamp) - Date.parse(a.timestamp))) * 1000 * 3.6;
}

export function unitAwareVelocity(speedInKMH: number | null, unit: UnitType) {
  if (speedInKMH == null) return -1;
  if (unit === UnitType.IMPERIAL) {
    return speedInKMH * 0.621371;
  }
  return speedInKMH;
}

export function unitAwareDistance(distanceInM: number | null | undefined, unit: UnitType) {
  /*
   * Returns distance in meter or foot based on unit type.
   * If distance is null, returns -1.
   * */

  if (distanceInM == null) return -1;
  if (unit === UnitType.IMPERIAL) {
    return distanceInM * 3.28084;
  }
  return distanceInM;
}

export const labelFormatterDistance = (
  valueInMeterOrFoot: number | null | undefined,
  unit: UnitType,
  digits = 2,
  showUnit = true,
) => {
  /*
   * Returns distance in km or mi based on unit type.
   * Input has to be in meter or foot.
   * If distance is null, returns -.
   *
   * */

  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
    unit: unit === UnitType.METRIC ? 'kilometer' : 'mile',
    style: !showUnit ? 'decimal' : 'unit',
  };
  if (valueInMeterOrFoot == null) {
    const unitString = !showUnit ? '' : unit === UnitType.METRIC ? 'km' : 'mi';
    return `-${HAIRSP}${unitString}`;
  }
  if (unit === UnitType.IMPERIAL) {
    return `${(valueInMeterOrFoot / 5280).toLocaleString(undefined, options)}`;
  }
  return `${(valueInMeterOrFoot / 1000).toLocaleString(undefined, options)}`;
};

export const labelFormatterElevation = (
  valueInMeterOrFoot: number,
  unit: UnitType,
  digits = 2,
  showUnit = true,
) => {
  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
    unit: unit === UnitType.METRIC ? 'meter' : 'foot',
    style: !showUnit ? 'decimal' : 'unit',
  };
  if (valueInMeterOrFoot == null)
    return `-${HAIRSP}${!showUnit ? '' : unit === UnitType.METRIC ? 'm' : 'ft'}`;

  return valueInMeterOrFoot.toLocaleString(undefined, options);
};

export const labelFormatterSpeed = (
  valueInKMHorMIH: number,
  unit: UnitType,
  digits = 2,
  showUnit = true,
) => {
  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
    unit: unit === UnitType.METRIC ? 'kilometer-per-hour' : 'mile-per-hour',
    style: !showUnit ? 'decimal' : 'unit',
  };

  if (valueInKMHorMIH == null) {
    const unitString = !showUnit ? '' : unit === UnitType.METRIC ? 'km/h' : 'mph';
    return `-${HAIRSP}${unitString}`;
  }

  return valueInKMHorMIH.toLocaleString(undefined, options);
};

type PingTypeIconProps = {
  pingType: PingType | null;
};

export const PingTypeIcon = ({ pingType }: PingTypeIconProps) => {
  if (pingType == null) return <IconFail title="We do not know the type of this Ping." />;
  switch (pingType) {
    case PingType.SyntheticPing: {
      return (
        <IconCalculator
          style={{ color: RacemapColors.PaleBlue }}
          title="This is a synthetic Ping. Sometimes no reading exists for a certain location. But we know a timestamp for this location. i.e. a gun start time or similar. Then we add this reading for loaction 0km knowing it was the start "
        />
      );
    }
    case PingType.TransponderPing: {
      return (
        <IconLocation
          style={{ color: RacemapColors.PaleBlue }}
          title="This is a TransponderPing. Its recorded by a wide range radio receiver and therefore has a lower accuracy in location and time. In contrast a TimingPing is recorded by decoder that offers higher accuracies in time due to its nearfield activation desing."
        />
      );
    }
    case PingType.TimingPing: {
      return (
        <IconClock
          style={{ color: RacemapColors.PaleBlue }}
          title="This is a TimingPing. Its reading was recorded by a decoder specialized to record times with hich accuracy. In contrast a TransponderPing is recorded by a wide range radio signal receiver and therfore has a lower accuracy."
        />
      );
    }
    case PingType.TimePing: {
      return (
        <IconClock
          style={{ color: RacemapColors.PaleBlue }}
          title="This is a TimingPing. Its reading was recorded by a decoder specialized to record times with hich accuracy. In contrast a TransponderPing is recorded by a wide range radio signal receiver and therfore has a lower accuracy."
        />
      );
    }
    default: {
      return (
        <>
          <IconFail
            style={{ color: RacemapColors.DangerRed }}
            title="We do not know the type of this Ping."
          />{' '}
          ({pingType})
        </>
      );
    }
  }
};

export const getAcceptedPingColor = (accPingId: number) => {
  return colorList[(accPingId || 0) % colorList.length];
};
