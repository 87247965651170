import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { truncateString } from '@racemap/utilities/functions/utils';
import { produce } from 'immer';
import { type InputHTMLAttributes, useEffect, useState } from 'react';
import type { Variant } from 'react-bootstrap/esm/types';
import ReactSelect, {
  type Props as SelectProps,
  type SingleValue,
  type StylesConfig,
} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import tinycolor from 'tinycolor2';
import { IconEdit, IconPlus, IconTrash } from '../../Icon';
import { getStatusColor } from '../../utils/helpers';
import { Center } from '../MetaComponent';
import { StatusCell } from './StatusCell';

const rowHeight = '27px';

export interface Entry {
  value: string;
  status?: Variant;
  title?: string;
}

export interface Props {
  value: Array<string | Entry>;
  onChange: (value: Array<string>) => void;
  options?: Array<string>;
  allowOnlyOptions?: boolean;
  disabled?: boolean;
  minNumRows?: number;
  pattern?: InputHTMLAttributes<HTMLInputElement>['pattern'];
  inputType?: InputHTMLAttributes<HTMLInputElement>['type'];
}

export function StringListEditor({
  value,
  onChange,
  options,
  allowOnlyOptions = false,
  disabled = false,
  minNumRows = 7,
  pattern,
  inputType = 'text',
}: Props) {
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [selectionEditable, setSelectionEditable] = useState(false);
  const [listCache, setListCache] = useState<Array<Entry>>([]);
  const withOptions = options != null && options.length > 0;
  const filteredOptions =
    options?.filter(
      (o) =>
        listCache.find(
          (v) => (typeof v === 'object' && v.value === o) || (typeof v === 'string' && v === o),
        ) == null,
    ) || [];
  const withStatusEntry = listCache.find((e) => typeof e === 'object' && e.status != null) != null;

  useEffect(() => {
    setListCache(
      value.map((s) => {
        if (typeof s === 'string') {
          return { value: s };
        }
        return s;
      }),
    );
  }, [value]);

  function updateList(newList: Array<Entry>, fullUpdate = true) {
    setListCache([...newList]);

    if (fullUpdate) {
      onChange([...newList.map((e) => e.value)]);
    }
  }

  function handleAdd() {
    updateList([...listCache, { value: '' }], false);
    setSelectedRow(listCache.length);
    setSelectionEditable(true);
  }

  function handleRemove() {
    if (selectedRow != null) {
      updateList(listCache.filter((_e, i) => i !== selectedRow));
      setSelectedRow(null);
    }
  }

  function handleEdit() {
    if (selectedRow != null && !selectionEditable) {
      setSelectionEditable(true);
      return;
    }
  }

  function handleInputChange(index: number, newValue: string) {
    const newList = produce(listCache, (draft) => {
      draft[index].value = newValue;
    });

    updateList(newList, false);
  }

  function handleInputBlur(event: React.FocusEvent<HTMLInputElement>) {
    const isValid = event.currentTarget.reportValidity();
    if (!isValid) return;

    if (
      listCache.map((e) => e.value).join('') !==
      value.map((e) => (typeof e === 'object' ? e.value : e)).join('')
    ) {
      updateList(listCache);
    }
    setSelectionEditable(false);
  }

  function handleSelectionChange(index: number, newValue: string) {
    const newList = produce(listCache, (draft) => {
      draft[index].value = newValue;
    });

    updateList(newList);
    setSelectionEditable(false);
    setSelectedRow(null);
  }

  function handleRowClick(rowIndex: number | null) {
    if (selectionEditable && selectedRow === rowIndex) {
      return;
    }
    if (selectionEditable && selectedRow !== rowIndex) {
      setSelectionEditable(false);
      setSelectedRow(rowIndex);
    } else if (selectedRow !== rowIndex) {
      setSelectedRow(rowIndex);
    } else if (rowIndex == null || selectedRow === rowIndex) {
      setSelectedRow(null);
    }
  }

  function handleDoubleRowClick(rowIndex: number) {
    if (!selectionEditable) {
      setSelectedRow(rowIndex);
      setSelectionEditable(true);
    }
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      const isValid = event.currentTarget.reportValidity();
      if (!isValid) return;

      if (listCache.join('') !== value.join('')) {
        updateList(listCache);
      }

      setSelectionEditable(false);
      setSelectedRow(null);
    }
  }

  const getEditCell = (value: string, index: number) => {
    type OptionType = { value: string; label?: string };
    if (withOptions) {
      const selectProps: SelectProps<OptionType, false> = {
        value: { value, label: value },
        options: filteredOptions.map((o) => ({ value: o, label: o })),
        onBlur: handleInputBlur,
        autoFocus: true,
        openMenuOnFocus: true,

        onChange: (newValue: SingleValue<OptionType>) =>
          handleSelectionChange(index, newValue?.value || ''),
        onKeyDown: handleKeyPress,
        styles: getSelectStyles<OptionType>(),
      };

      if (allowOnlyOptions) {
        return <ReactSelect {...selectProps} />;
      }

      return (
        <CreatableSelect
          {...selectProps}
          formatCreateLabel={(input: string) => `Set ${input}...`}
        />
      );
    }
    return (
      <Input
        type={inputType}
        value={value}
        onChange={({ target }) => handleInputChange(index, target.value)}
        onBlur={handleInputBlur}
        onKeyPress={handleKeyPress}
        pattern={pattern}
        autoFocus
      />
    );
  };

  return (
    <Container disabled={disabled}>
      <Table withStatusColumn={withStatusEntry}>
        {listCache.map((s, i) => {
          const rowIsSelected = i === selectedRow;
          const key = `${s.value}_${s.title}_${s.status}`;
          const rowGettingEdited = rowIsSelected && selectionEditable;
          const statusColor =
            s.status != null
              ? tinycolor(getStatusColor(s.status)).brighten(70).desaturate(20).toRgbString()
              : undefined;

          return (
            <Row
              isSelected={rowIsSelected}
              onClick={() => handleRowClick(i)}
              onDoubleClick={() => handleDoubleRowClick(i)}
              key={key}
              className="row"
            >
              <IndexCell
                key={`${key}-0`}
                className="cell"
                style={{ gridRow: i + 1, background: statusColor }}
              >
                {i + 1}.
              </IndexCell>
              <StringCell
                key={`${key}-1`}
                className="cell"
                getEditted={rowGettingEdited}
                style={{ gridRow: i + 1, background: statusColor }}
              >
                {rowGettingEdited ? getEditCell(s.value, i) : truncateString(s.value, 50)}
              </StringCell>
              {withStatusEntry ? (
                <StatusCell
                  className="cell"
                  status={s.status}
                  style={{ gridRow: i + 1, background: statusColor }}
                  title={s.title}
                />
              ) : (
                <></>
              )}
            </Row>
          );
        })}
        {Array(Math.max(minNumRows - listCache.length, 0))
          .fill(0)
          .map((_e, i) => (
            // biome-ignore lint/suspicious/noArrayIndexKey: only fill lines
            <Row key={i + listCache.length} className="row" datatype="row">
              <IndexCell
                key={`${i + listCache.length}-0`}
                className="cell"
                style={{ gridRow: i + 1 + listCache.length }}
                onClick={() => handleRowClick(null)}
              />
              <StringCell
                key={`${i + listCache.length}-1`}
                className="cell"
                style={{ gridRow: i + 1 + listCache.length }}
                onDoubleClick={handleAdd}
                onClick={() => handleRowClick(null)}
              />
              {withStatusEntry && (
                <StatusCell
                  className="cell"
                  style={{ gridRow: i + 1 + listCache.length }}
                  onClick={() => handleRowClick(null)}
                />
              )}
            </Row>
          ))}
      </Table>
      <ButtonRow>
        <Button onClick={handleAdd}>
          <IconPlus />
        </Button>
        {selectedRow != null && (
          <Button onClick={handleEdit} isActive={selectionEditable}>
            <IconEdit />
          </Button>
        )}
        {selectedRow != null && (
          <Button onClick={handleRemove}>
            <IconTrash />
          </Button>
        )}
      </ButtonRow>
    </Container>
  );
}

function getSelectStyles<Option = unknown>(): StylesConfig<Option, false> {
  return {
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    control: (provided) => ({
      ...provided,
      height: rowHeight,
      minHeight: rowHeight,
      alignItems: 'baseline',
      borderColor: 'unset',
      borderWidth: 'unset',
      borderRadius: 'unset',
      borderStyle: 'unset',
      backgroundColor: 'unset',
      boxShadow: 'unset',
    }),
    menuList: (provided) => ({ ...provided, padding: 0 }),
    menu: (provided) => ({ ...provided, margin: 0, zIndex: 3, color: 'black' }),
    option: (provided) => ({
      ...provided,
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
    }),
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: 'white',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: rowHeight,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'white',
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: rowHeight,
      gridTemplateRows: '100%',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
  };
}

const Container = styled.div<{ disabled: boolean }>`
  background: white;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  opacity: ${({ disabled }) => (disabled ? '0.5' : undefined)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : undefined)};
`;

const Table = styled.div<{ withStatusColumn: boolean }>`
  display: grid;
  grid-template-columns: ${({ withStatusColumn }) =>
    withStatusColumn ? '25px auto 25px' : '25px auto'};
  grid-auto-rows: ${rowHeight};
  grid-auto-flow: row;
  margin-bottom: 0px;

  div:nth-of-type(1) {
    .cell:first-of-type {
      border-top-left-radius: 5px;
    }
    .cell:last-of-type {
      border-top-right-radius: 5px;
    }
  }

  div:nth-last-of-type(odd) > .cell {
    background: #eeeeee;
  }
`;

const Row = styled.div<{ isSelected?: boolean }>`
  height: ${rowHeight};
  display: contents;

  :hover > .cell {
    background: ${({ isSelected }) =>
      isSelected ? '#36739a !important' : `${RacemapColors.CloudBlue} !important`};
  }

  .cell {
    height: 100%;
    display: flex;
    align-items: center;
    background: ${({ isSelected }) => (isSelected ? '#36739a !important' : undefined)};
    color: ${({ isSelected }) => (isSelected ? 'white !important' : undefined)};
  }
`;

const IndexCell = styled.div`
  border-bottom: 1px solid #dee2e6;
  padding: 3px;
  justify-content: center;
  font-weight: 600;
  border-right: solid 1px #dee2e6;
  grid-column: 1;
`;

const StringCell = styled.div<{ getEditted?: boolean }>`
  border-bottom: 1px solid #dee2e6;
  padding: ${({ getEditted }) => (getEditted ? '3px' : '3px 10px')};
  font-weight: 400;
  grid-column: 2;
`;

const Input = styled.input`
  height: 100%;
  width: 100%;
  border: unset;
  padding: 7px;
`;

const ButtonRow = styled.div`
  height: ${rowHeight};
  display: flex;
  flex-direction: row;
  padding-top: 0px;
  padding-left: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const Button = styled(Center)<{ isActive?: boolean }>`
  color: black;
  border: unset;
  border-right: 1px solid #dee2e6;
  border-radius: unset;
  width: 31px;
  background: transparent;
  cursor: pointer;
  color: ${({ isActive = false }) => (isActive ? RacemapColors.PaleBlue : 'black')};
  ${({ isActive = false }) => (isActive ? 'pointer-events: none;' : '')}

  :hover {
    background: ${({ isActive = false }) => (isActive ? 'unset' : RacemapColors.LightGray)};
  }
`;
