import { RacemapColors } from '@racemap/utilities/consts/common';
import { type ThemeConfig, theme } from 'antd';

const { getDesignToken } = theme;

export const themeConfig: ThemeConfig = {
  token: {
    colorPrimary: RacemapColors.PaleBlue,
    borderRadius: 3.5,
    colorBorder: '#ced4da',
    colorLink: RacemapColors.PaleBlue,
  },
  components: {
    Form: {
      labelColor: '#212529',
    },
    Table: {
      borderColor: '#ced4da',
      rowSelectedBg: RacemapColors.LightBlue,
    },
    Card: {
      colorBorderSecondary: '#ced4da',
    },
    Button: {
      colorLink: RacemapColors.PaleBlue,
      defaultColor: RacemapColors.PaleBlue,
    },
  },
};

// static theme to use outside of react lifecycle
export const staticThemeToken = getDesignToken(themeConfig);
