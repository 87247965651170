import {
  type BillableItem,
  type EventDayBasedBillableItem,
  type EventDayBasedBillableItemType,
  type FreeBillableItem,
  type SubscriptionBillableItemTypes,
  subscriptionBillableItemTypes,
} from '@racemap/sdk/schema/billing';
import { StripeProducts } from '@racemap/sdk/schema/billing/StripeProducts';
import { BillableItemTypes } from '@racemap/sdk/schema/user';
import {
  type BillableItemObject,
  EventLoadTypes,
  type Load,
} from '@racemap/utilities/types/events';
import { type Immutable, castDraft, produce } from 'immer';
import { DateTime } from 'luxon';
import moment, { type Moment } from 'moment';
import type Stripe from 'stripe';
import { type PRICING_VERSIONS, PRICING_VERSION_TABLE } from '../consts/billing';
import { AddOns, AuthorizationStates } from '../consts/events';
import type { ObjectId } from '../types/utils';

export function formatTimeKey(timestamp: number | Date): string {
  return moment(timestamp).utc().format('YYYY-MM-DD');
}

export function isCloseMonthChange(): boolean {
  const now = moment.utc();
  const startOfMonth = moment(now).utc().startOf('month');
  const endOfMonth = moment(now).utc().endOf('month');
  const THRESHOLD_MINUTES = 5;

  // as a result of an issue at the billing where we overwrite the usage exactly at the change of the month we skip this period for the sync to stripe
  if (
    now.isBefore(startOfMonth.add(THRESHOLD_MINUTES, 'minutes')) ||
    now.isAfter(endOfMonth.subtract(THRESHOLD_MINUTES, 'minutes'))
  ) {
    return true;
  }
  return false;
}

// function evaluate, that the time key starts a new billing cycle
// and dont belong to the last billing cycle
export function checkForRunningBillingCycle(
  billableEvents: Array<EventDayBasedBillableItem>,
  timeKey: string,
  daysPerBillingCycle = getCurrentNumberOfDaysOfEventCycle(),
) {
  const twoBillingCyclesBefore = moment
    .utc(timeKey, 'YYYY-MM-DD')
    .subtract(2 * daysPerBillingCycle, 'days');

  const oneBillingCycleBefore = moment
    .utc(timeKey, 'YYYY-MM-DD')
    .subtract(1 * daysPerBillingCycle, 'days');

  const baseTimeKeyValue = moment.utc(timeKey, 'YYYY-MM-DD').valueOf();
  const sortedBillableEvents = sortBillableItems(billableEvents, 'asc');
  const olderBillableEvents = sortedBillableEvents.filter(
    (billableEvent) =>
      moment.utc(billableEvent.timeKey, 'YYYY-MM-DD').valueOf() <= baseTimeKeyValue,
  );

  let lastRunningBillingCycleStartDate: null | Date = null;
  for (const [index, billableEvent] of olderBillableEvents.entries()) {
    const billableEventTimestamp = moment.utc(billableEvent.timeKey, 'YYYY-MM-DD');
    // events, that are older then 2 times billing cycle are not relevant
    if (billableEventTimestamp.valueOf() < twoBillingCyclesBefore.valueOf()) continue;
    // first event, means is no running billing cycle
    if (
      billableEventTimestamp.valueOf() === baseTimeKeyValue &&
      index === olderBillableEvents.length - 1
    )
      return false;

    if (lastRunningBillingCycleStartDate == null) {
      // if the last event was in the last billing cycle, the current event is not the first in the billing cycle
      if (billableEventTimestamp.valueOf() > oneBillingCycleBefore.valueOf()) return true;

      lastRunningBillingCycleStartDate = moment.utc(billableEvent.timeKey, 'YYYY-MM-DD').toDate();
    } else {
      const endOfLastCycle = moment(lastRunningBillingCycleStartDate)
        .add(daysPerBillingCycle, 'days')
        .valueOf();

      // if the current event is after the event end of the last billing cycle, the current event is the first in the billing cycle
      if (billableEventTimestamp.valueOf() > endOfLastCycle) {
        return true;
      }
    }
  }

  return false;
}

export interface EventCycle {
  startDate: Date;
  endDate: Date;
  startTimeKey: string;
  endTimeKey: string;
  eventId: ObjectId;
  billingEvents: Array<EventDayBasedBillableItem>;
  activatedAddons: Array<AddOns>;
}

export interface EventCycleInfos {
  eventCycles: Array<EventCycle>;
  totalCycles: number;
}

/**
 * Calculates the number of event cycles based on the provided billable event days.
 * Each event cycle represents a billing cycle.
 *
 * @param billableEventDays - An array of billable event days.
 * @param daysPerBillingCycle - The number of days per billing cycle. Defaults to DAYS_PER_BILLING_CYCLE.
 * @param startDate - The start date of the billing cycle. Defaults to the first day of the first billable event.
 * @param endDate - The end date of the billing cycle. Defaults to the last day of the last billable event.
 * @returns The number of event cycles.
 */
export function getEventCyclesOfTimeRange(
  billableItems: Array<EventDayBasedBillableItem>,
  daysPerBillingCycle: number,
  startDate?: Date,
  endDate?: Date,
): EventCycleInfos {
  const sortedBillableItems = sortBillableItems(billableItems);
  const out: EventCycleInfos = {
    eventCycles: [],
    totalCycles: 0,
  };
  if (sortedBillableItems.length === 0) return out;

  const start = moment.utc(startDate || sortedBillableItems[0].timeKey).startOf('day');
  const end = moment
    .utc(endDate || sortedBillableItems[sortedBillableItems.length - 1].timeKey)
    .endOf('day');
  let lastRunningBillingCycleStartDate: null | Moment = null;

  for (const billableItem of sortedBillableItems) {
    const timeKey = moment.utc(billableItem.timeKey);
    const lastBillingCycle =
      out.eventCycles.length > 0 ? out.eventCycles[out.eventCycles.length - 1] : null;
    const endOfRunningBillingCycle = lastRunningBillingCycleStartDate
      ?.clone()
      .add(daysPerBillingCycle, 'days');

    // update a existing billing event
    if (
      out.eventCycles.some((bC) => bC.billingEvents.some((e) => e.timeKey === billableItem.timeKey))
    ) {
      const billingEvent =
        out.eventCycles[
          out.eventCycles.findIndex((bC) =>
            bC.billingEvents.some((e) => e.timeKey === billableItem.timeKey),
          )
        ];
      billingEvent.activatedAddons = [
        ...new Set([
          ...out.eventCycles[out.eventCycles.length - 1].activatedAddons,
          ...billableItem.activatedAddons,
        ]),
      ];

      const indexOfBillableEvent = billingEvent.billingEvents.findIndex(
        (e) => e.timeKey === billableItem.timeKey,
      );
      const existingBillableEvent = billingEvent.billingEvents[indexOfBillableEvent];
      billingEvent.billingEvents[indexOfBillableEvent] = produce(existingBillableEvent, (draft) => {
        return {
          ...draft,
          ...billableItem,
          activatedAddons: [
            ...new Set([...existingBillableEvent.activatedAddons, ...billableItem.activatedAddons]),
          ],
        };
      });
      continue;
    }

    if (endOfRunningBillingCycle?.isAfter(timeKey)) {
      // running billing cycle
      if (lastBillingCycle != null && moment.utc(lastBillingCycle.endDate).isSameOrAfter(timeKey)) {
        // if there is already a billing cycle, add the event to the last billing cycle
        out.eventCycles[out.eventCycles.length - 1].billingEvents.push(billableItem);
        out.eventCycles[out.eventCycles.length - 1].activatedAddons = [
          ...new Set([
            ...out.eventCycles[out.eventCycles.length - 1].activatedAddons,
            ...billableItem.activatedAddons,
          ]),
        ];
      }
      continue;
    }

    if (timeKey.isSameOrAfter(start) && timeKey.isSameOrBefore(end)) {
      out.eventCycles.push({
        startDate: timeKey.clone().startOf('day').toDate(),
        endDate: timeKey
          .clone()
          .add(daysPerBillingCycle - 1, 'days')
          .endOf('day')
          .toDate(),
        startTimeKey: timeKey.clone().startOf('day').format('YYYY-MM-DD'),
        endTimeKey: timeKey
          .clone()
          .add(daysPerBillingCycle - 1, 'days')
          .endOf('day')
          .format('YYYY-MM-DD'),
        billingEvents: [billableItem],
        activatedAddons: billableItem.activatedAddons,
        eventId: billableItem.eventId,
      });
    }
    lastRunningBillingCycleStartDate = moment.utc(billableItem.timeKey);
    out.totalCycles++;
  }

  return out;
}

export type CostlyAddons =
  | StripeProducts.API_ADDON
  | StripeProducts.MONITOR_ADDON
  | StripeProducts.SPONSOR_ADDON
  | StripeProducts.TIMING_ADDON;
interface EventCycleProducts extends Partial<Record<CostlyAddons, number>> {
  [StripeProducts.MAP]: number;
}

function isCostlyAddOn(addOn: AddOns): boolean {
  return [AddOns.API, AddOns.MONITOR, AddOns.SPONSOR, AddOns.TIMING].includes(addOn);
}

const mappingAddonToProduct: Partial<Record<AddOns, CostlyAddons>> = {
  [AddOns.API]: StripeProducts.API_ADDON,
  [AddOns.MONITOR]: StripeProducts.MONITOR_ADDON,
  [AddOns.SPONSOR]: StripeProducts.SPONSOR_ADDON,
  [AddOns.TIMING]: StripeProducts.TIMING_ADDON,
};

export function getProductsOfEventCycles(
  billingEvents: Array<EventDayBasedBillableItem> | Immutable<Array<EventDayBasedBillableItem>>,
  daysPerBillingCycle: number,
  basePricePaid = false,
  freeItems: Array<Immutable<FreeBillableItem>> = [],
): EventCycleProducts {
  const billingCycles = getEventCyclesOfTimeRange(castDraft(billingEvents), daysPerBillingCycle);
  return getProductsOfBillingCycles(billingCycles, basePricePaid, freeItems);
}

function getDefaultProductsOfBillingCycle(): EventCycleProducts {
  return {
    [StripeProducts.MAP]: 0,
    [StripeProducts.TIMING_ADDON]: 0,
    [StripeProducts.API_ADDON]: 0,
    [StripeProducts.MONITOR_ADDON]: 0,
    [StripeProducts.SPONSOR_ADDON]: 0,
  };
}

const mappingFreeItems: Record<FreeBillableItem['type'], keyof EventCycleProducts> = {
  [BillableItemTypes.FREE_DATA_FEED]: StripeProducts.API_ADDON,
  [BillableItemTypes.FREE_MONITOR]: StripeProducts.MONITOR_ADDON,
  [BillableItemTypes.FREE_SPONSOR]: StripeProducts.SPONSOR_ADDON,
  [BillableItemTypes.FREE_TIMING]: StripeProducts.TIMING_ADDON,
  [BillableItemTypes.FREE_EVENT_CYCLE]: StripeProducts.MAP,
};

// TODO: write test for function
export function getProductsOfBillingCycles(
  billingCycles: EventCycleInfos,
  basePricePaid = false,
  freeItems: Array<Immutable<FreeBillableItem>> = [],
): EventCycleProducts {
  const products = getDefaultProductsOfBillingCycle();

  for (const billingCycle of billingCycles.eventCycles) {
    const productsOfCycle = getDefaultProductsOfBillingCycle();
    productsOfCycle[StripeProducts.MAP] = 1;

    for (const billingEvent of billingCycle.billingEvents) {
      for (const addOn of billingEvent.activatedAddons) {
        if (isCostlyAddOn(addOn)) {
          const product = mappingAddonToProduct[addOn];
          if (product == null) continue;

          productsOfCycle[product] = 1;
        }
      }
    }

    for (const [key, value] of Object.entries(productsOfCycle)) {
      products[key as CostlyAddons] += value;
    }
  }

  if (basePricePaid) {
    products[StripeProducts.MAP] = Math.max(products[StripeProducts.MAP] - 1, 0);
  }

  for (const freeItem of freeItems) {
    const mappingType = mappingFreeItems[freeItem.type];
    if (mappingType == null) continue;

    products[mappingType] = Math.max((products[mappingType] || 0) - freeItem.quantity, 0);
  }

  return products;
}

const CountBillingItemTypes = [
  StripeProducts.KEY,
  StripeProducts.PAGE_VIEW,
  StripeProducts.STARTER,
  StripeProducts.GPS_DEVICE,
  StripeProducts.TRANSPONDER,
  StripeProducts.MAP_OR_MONITOR_LOAD,
  StripeProducts.API_OR_LEADERBOARD_LOAD,
] as const;
type CountBillingItemType = (typeof CountBillingItemTypes)[number];
type CountBillingProducts = Partial<Record<StripeProducts, number>>;

export const isCountBillableItemType = (type: string): type is CountBillingItemType => {
  return CountBillingItemTypes.includes(type as CountBillingItemType);
};

export const getBillableItemOfDeviceClass = (
  deviceClass: 'gps_device' | 'transponder' | null,
): BillableItemTypes => {
  if (deviceClass == null) return BillableItemTypes.STARTER;
  if (deviceClass === 'gps_device') return BillableItemTypes.GPS_DEVICE;
  if (deviceClass === 'transponder') return BillableItemTypes.TRANSPONDER;
  throw new Error('Invalid device class');
};

export function getRegisteredCountProducts(
  billingItems: Immutable<Array<BillableItem>>,
): CountBillingProducts {
  return billingItems.reduce((acc, item) => {
    const stripeProduct = getStripeProductOfBillableItemType(item.type);
    if (stripeProduct == null) return acc;

    if (isCountBillableItemType(stripeProduct)) {
      acc[stripeProduct] = (acc[stripeProduct] || 0) + 1;
    }
    return acc;
  }, {} as CountBillingProducts);
}

export const getStripeProductOfBillableItemType = (
  type: BillableItemTypes,
): StripeProducts | null => {
  switch (type) {
    case BillableItemTypes.KEY:
      return StripeProducts.KEY;
    case BillableItemTypes.PAGE_VIEW:
      return StripeProducts.PAGE_VIEW;
    case BillableItemTypes.STARTER:
      return StripeProducts.STARTER;
    case BillableItemTypes.GPS_DEVICE:
      return StripeProducts.GPS_DEVICE;
    case BillableItemTypes.TRANSPONDER:
      return StripeProducts.TRANSPONDER;
    case BillableItemTypes.APP:
      return StripeProducts.CUSTOM_APP;
    case BillableItemTypes.TRACKER_MANAGEMENT:
      return StripeProducts.TRACKER_MANAGEMENT;
    case BillableItemTypes.SMS:
      return StripeProducts.SMS;
    case BillableItemTypes.DATA_USAGE_ZONE_1:
      return StripeProducts.DATA_USAGE_ZONE_1;
    case BillableItemTypes.DATA_USAGE_ZONE_2:
      return StripeProducts.DATA_USAGE_ZONE_2;
    case BillableItemTypes.DATA_USAGE_ZONE_3:
      return StripeProducts.DATA_USAGE_ZONE_3;
    case BillableItemTypes.BASE_PRICE:
      return StripeProducts.BASE_PRICE;
    case BillableItemTypes.DATA_FEED:
      return StripeProducts.API_ADDON;
    case BillableItemTypes.MONITOR:
      return StripeProducts.MONITOR_ADDON;
    case BillableItemTypes.SPONSOR:
      return StripeProducts.SPONSOR_ADDON;
    case BillableItemTypes.TIMING:
      return StripeProducts.TIMING_ADDON;
    case BillableItemTypes.EVENT_CYCLE:
      return StripeProducts.MAP;
    case BillableItemTypes.SIM_CARD:
      return StripeProducts.SIM_CARD_ACTIVATION;
    default:
      return null;
  }
};

const MapOrMonitorLoadTypes: Array<EventLoadTypes> = [
  EventLoadTypes.MAP_LOADS,
  EventLoadTypes.MONITOR_LOADS,
];

export const isMapOrMonitorLoad = (load: Load): boolean => {
  if (MapOrMonitorLoadTypes.includes(load.type) || load.type === null) return true;
  return false;
};

const ApiOrLeaderboardLoadTypes: Array<EventLoadTypes> = [
  EventLoadTypes.CURRENT_LOADS,
  EventLoadTypes.DISTANCE_LOADS,
  EventLoadTypes.RANKS_LOADS,
  EventLoadTypes.TIMES_ID_LOADS,
  EventLoadTypes.TIMES_LOADS,
  EventLoadTypes.TIMING_LOADS,
];

export const isApiOrLeaderboardLoad = (load: Load): boolean => {
  if (ApiOrLeaderboardLoadTypes.includes(load.type)) return true;
  return false;
};

export const aggregateLoadProductCounts = (
  loads: Immutable<Array<Load>>,
): {
  [StripeProducts.MAP_OR_MONITOR_LOAD]: number;
  [StripeProducts.API_OR_LEADERBOARD_LOAD]: number;
} => {
  const loadProducts = {
    [StripeProducts.MAP_OR_MONITOR_LOAD]: 0,
    [StripeProducts.API_OR_LEADERBOARD_LOAD]: 0,
  };

  loads.forEach((load) => {
    if (isMapOrMonitorLoad(load)) {
      loadProducts[StripeProducts.MAP_OR_MONITOR_LOAD] += load.count;
    } else if (isApiOrLeaderboardLoad(load)) {
      loadProducts[StripeProducts.API_OR_LEADERBOARD_LOAD] += load.count;
    }
  });

  return loadProducts;
};

export function calculateNumberOfEventCycles(
  billableEventDays: Array<EventDayBasedBillableItem> | Immutable<Array<EventDayBasedBillableItem>>,
  daysPerBillingCycle: number,
  startDate?: Date,
  endDate?: Date,
) {
  return getEventCyclesOfTimeRange(
    castDraft(billableEventDays),
    daysPerBillingCycle,
    startDate,
    endDate,
  ).eventCycles.length;
}

export const sortBillableItems = <T extends { timeKey?: string }>(
  billableEvents: Array<T>,
  order: 'asc' | 'desc' = 'asc',
): Array<T> => {
  const sortedBillableEvents = [...billableEvents].sort((a, b) => {
    const aTimestamp = moment(a.timeKey || Number.POSITIVE_INFINITY).valueOf();
    const bTimestamp = moment(b.timeKey || Number.NEGATIVE_INFINITY).valueOf();

    if (order === 'asc') return aTimestamp - bTimestamp;
    return bTimestamp - aTimestamp;
  });

  return sortedBillableEvents;
};

export function isBillableItem(item: unknown): item is BillableItem {
  return (
    typeof item === 'object' &&
    item !== null &&
    'type' in item &&
    'time' in item &&
    'timeKey' in item &&
    typeof item.type === 'string' &&
    Object.values(BillableItemTypes).includes(item.type as BillableItemTypes)
  );
}

export function isEventLoadType(type: string): type is EventLoadTypes {
  return Object.values(EventLoadTypes).includes(type as EventLoadTypes);
}

export function isBillableItemType(type: string): type is BillableItemTypes {
  return Object.values(BillableItemTypes).includes(type as BillableItemTypes);
}

export function isEventLoadTypeKey(type: string): type is keyof typeof EventLoadTypes {
  return Object.keys(EventLoadTypes).includes(type);
}

export function isEventDayBasedBillableItem(item: unknown): item is EventDayBasedBillableItem {
  return isBillableItem(item) && item.type === BillableItemTypes.EVENT_DAY;
}

export function isFreeBillableItem(item: unknown): item is FreeBillableItem {
  return (
    isBillableItem(item) &&
    [
      BillableItemTypes.FREE_EVENT_CYCLE,
      BillableItemTypes.FREE_TIMING,
      BillableItemTypes.FREE_DATA_FEED,
      BillableItemTypes.FREE_MONITOR,
      BillableItemTypes.FREE_SPONSOR,
    ].includes(item.type)
  );
}

export function isSubscriptionItem(itemType: unknown): itemType is SubscriptionBillableItemTypes {
  return (
    typeof itemType === 'string' &&
    isBillableItemType(itemType) &&
    subscriptionBillableItemTypes.includes(itemType as SubscriptionBillableItemTypes)
  );
}

export function isStripeProduct(product: unknown): product is StripeProducts {
  return (
    typeof product === 'string' &&
    Array.from(Object.values(StripeProducts)).includes(product as StripeProducts)
  );
}

export function getCurrentPricingVersion(referenceDate = DateTime.utc()): PRICING_VERSIONS {
  for (const [version, { from, to }] of Object.entries(PRICING_VERSION_TABLE)) {
    if (referenceDate >= from && (to == null || referenceDate < to)) {
      return version as PRICING_VERSIONS;
    }
  }

  throw new Error('No pricing version found for the given reference date');
}

export function getNumberOfFreeItems(
  product: StripeProducts,
  priceList: Record<StripeProducts, Stripe.Price>,
): number {
  const price = priceList[product];
  if (price == null || price.billing_scheme !== 'tiered') return 0;

  const packageSize = Number.parseInt(price.metadata?.packageSize || '1');
  const freeTier = price.tiers?.find(
    (tier) => tier.unit_amount === 0 && (tier.flat_amount == null || tier.flat_amount === 0),
  );
  if (freeTier == null) return 0;

  return (freeTier.up_to || Number.POSITIVE_INFINITY) * packageSize;
}

export function getCurrentNumberOfDaysOfEventCycle(referenceDate = DateTime.utc()): number {
  return PRICING_VERSION_TABLE[getCurrentPricingVersion(referenceDate)].numDaysEventCycle;
}

export function getPricingRulesOfEvent(event: {
  payments: { invariants: { pricingVersion: PRICING_VERSIONS } };
  authorization: AuthorizationStates;
}): { from: DateTime; to: DateTime | null; numDaysEventCycle: number } {
  const pricingVersion = event.payments.invariants.pricingVersion;
  const currentPricing = getCurrentPricingVersion();
  const currentPricingRules = PRICING_VERSION_TABLE[currentPricing];

  if (!(pricingVersion in PRICING_VERSION_TABLE)) {
    throw new Error(
      `No pricing version found for the given event. Pricing version ${pricingVersion} is not valid.`,
    );
  }

  // if the event is unpaid but the new pricing already started, we force the new pricing
  if (
    event.authorization === AuthorizationStates.NONE &&
    currentPricingRules.from < DateTime.utc()
  ) {
    return currentPricingRules;
  }

  return PRICING_VERSION_TABLE[pricingVersion];
}

export const costlyAddons = [AddOns.API, AddOns.MONITOR, AddOns.SPONSOR, AddOns.TIMING] as const;
export const billableItemTypesOfAddons: ReadonlyArray<BillableItemTypes> = [
  BillableItemTypes.DATA_FEED,
  BillableItemTypes.MONITOR,
  BillableItemTypes.SPONSOR,
  BillableItemTypes.TIMING,
] as const;
export const addOnBillableItemMapping: Record<
  (typeof costlyAddons)[number],
  EventDayBasedBillableItemType
> = {
  [AddOns.API]: BillableItemTypes.DATA_FEED,
  [AddOns.MONITOR]: BillableItemTypes.MONITOR,
  [AddOns.SPONSOR]: BillableItemTypes.SPONSOR,
  [AddOns.TIMING]: BillableItemTypes.TIMING,
};
export const billableItemAddonMapping: Partial<
  Record<EventDayBasedBillableItemType, (typeof costlyAddons)[number]>
> = {
  [BillableItemTypes.DATA_FEED]: AddOns.API,
  [BillableItemTypes.MONITOR]: AddOns.MONITOR,
  [BillableItemTypes.SPONSOR]: AddOns.SPONSOR,
  [BillableItemTypes.TIMING]: AddOns.TIMING,
};

export const holdsAllBillingItemTypes = (
  items: Array<BillableItem | BillableItemObject>,
  types: Array<BillableItemTypes>,
): boolean => {
  for (const type of types) {
    if (billableItemTypesOfAddons.includes(type)) {
      const addon = billableItemAddonMapping[type as EventDayBasedBillableItemType];
      if (addon == null) return false;

      if (
        !items.some(
          (item) => isEventDayBasedBillableItem(item) && item.activatedAddons.includes(addon),
        )
      )
        return false;
    } else if (!items.some((item) => item.type === type)) return false;
  }
  return true;
};
