import type { User } from '@racemap/sdk/schema/user';
import { RacemapAPIClient } from '@racemap/utilities/api-client';
import type { RacemapEvent, StripeInvoiceInfo } from '@racemap/utilities/types/events';
import { CURRENT_TOS_VERSION, type User_Legacy } from '@racemap/utilities/types/users';
import type { Immutable } from 'immer';
import type { CurrentEvent } from '../../../store/events/events_reducers';

const apiClient = RacemapAPIClient.fromWindowLocation();

export const isTOSAccepted = (user: Immutable<User_Legacy> | Immutable<User>): boolean => {
  return user.acceptedTOSVersion === CURRENT_TOS_VERSION;
};

export enum UserRole {
  ADMIN = 'admin',
  RESELLER = 'reseller',
  CHILD = 'child',
  REGULAR = 'regular',
}

export enum ViewStates {
  ADDRESS = 'ADDRESS',
  OVERVIEW = 'OVERVIEW',
  STRIPE = 'STRIPE',
  DONE = 'DONE',
}

export const getUserRole = (
  user: Immutable<User_Legacy>,
  event: Immutable<CurrentEvent> | Immutable<RacemapEvent>,
): UserRole => {
  if (user.admin) return UserRole.ADMIN;
  if (user.isReseller) return UserRole.RESELLER;
  if (user.parentId === event.creatorId) return UserRole.CHILD;

  return UserRole.REGULAR;
};

export const checkPaymentStatus = async (eventId: string): Promise<StripeInvoiceInfo> => {
  return await apiClient.checkEventPayment(eventId);
};

export const initPayment = async (event: Immutable<CurrentEvent>): Promise<StripeInvoiceInfo> => {
  return apiClient.initiateEventPayment(event.id);
};
