import { z } from 'zod';

export const TaxBreakdownSchema = z.array(
  z.object({
    rate: z.string(),
    amount: z.number(),
    reason: z.string(),
    inclusive: z.boolean(),
    taxableAmount: z.number(),
    country: z.string().nullable(),
    state: z.string().nullable(),
  }),
);

export type TaxBrakedown = z.infer<typeof TaxBreakdownSchema>;
