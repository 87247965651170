import type { RouteObject } from 'react-router';
import { BillingView } from 'src/admin/views/BillingView';

export const billingRoutes: Array<RouteObject> = [
  {
    path: 'billing/*',
    element: <BillingView />,
    children: [
      {
        path: 'report',
        lazy: async () => {
          const comp = await import('../admin/views/AdminBillingView');
          return { Component: comp.AdminBillingView };
        },
      },
    ],
  },
];
