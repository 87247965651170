import type { StripeProducts } from '@racemap/sdk/schema/billing/StripeProducts';
import { clamp } from '@racemap/utilities/math';
import { Col, Row } from 'antd';
import type { FC } from 'react';
import type Stripe from 'stripe';
import { formatNumber, formatPrice, getLabelForBillingItemType } from '../../misc/pricing';
import type { Price } from './getProductPriceFactory';

interface Props {
  product: StripeProducts;
  price?: Stripe.Price;
  quantity: number;
  getPricePerUnit: (product: StripeProducts, tierIndex?: number) => Price | undefined;
  getProductPrice: (number: StripeProducts, count: number, tierIndex?: number) => Price;
  lastEntry?: boolean;
}

export const ProductCostRow: FC<Props> = ({
  product,
  quantity,
  price,
  getPricePerUnit,
  getProductPrice,
  lastEntry,
}) => {
  const packageSize = Number.parseInt(price?.metadata?.packageSize || '', 10) || 1;
  const quantityFactor = 1 / packageSize;

  if (quantity === 0) return null;
  if (price?.billing_scheme !== 'tiered')
    return (
      <>
        <Row>
          <Col span={8}>{getLabelForBillingItemType(product)}</Col>
          <Col span={4}>{formatNumber(quantity)}</Col>
          <Col span={6}>x {formatPrice(getPricePerUnit(product))}</Col>
          <Col span={6}>= {formatPrice(getProductPrice(product, quantity * quantityFactor))}</Col>
        </Row>
        {!lastEntry && <hr />}
      </>
    );

  if (price.tiers == null) throw new Error('Tiered pricing without tiers');

  let tierDownBorder = 0;
  let tierUpBorder = 0;
  let tierQuantity = 0;
  let chargedQuantity = 0;

  return (
    <>
      <Row>
        <Col>{getLabelForBillingItemType(product)}</Col>
      </Row>
      {price.tiers.map((tier, index) => {
        tierDownBorder = tierUpBorder;
        tierUpBorder = (tier.up_to || Number.POSITIVE_INFINITY) / quantityFactor;
        tierQuantity = clamp(0, quantity - chargedQuantity, tierUpBorder - tierDownBorder);
        chargedQuantity += tierQuantity;

        if (tierQuantity === 0) return null;

        return (
          <Row key={`${price.id}_${tier.up_to}`}>
            <Col span={8}>
              {`${tierDownBorder > 0 ? formatNumber(tierDownBorder) : 0} - ${
                tierUpBorder === Number.POSITIVE_INFINITY ? '∞' : formatNumber(tierUpBorder)
              }`}
            </Col>
            <Col span={4}>
              {formatNumber(tierQuantity * quantityFactor)}
              {quantityFactor !== 1 ? ` x ${formatNumber(1 / quantityFactor)}` : ''}
            </Col>
            <Col span={6}>x {formatPrice(getPricePerUnit(product, index))}</Col>
            <Col span={6}>= {formatPrice(getProductPrice(product, tierQuantity, index))}</Col>
          </Row>
        );
      })}
      {!lastEntry && <hr />}
    </>
  );
};
