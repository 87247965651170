import type { RouteObject } from 'react-router';
import { AccountView } from '../admin/views/AccountView';

export const accountRoutes: Array<RouteObject> = [
  {
    path: 'account',
    element: <AccountView />,
    children: [
      {
        path: ':userId',
        lazy: async () => {
          const comp = await import('../admin/views/UserProfileView');
          return { Component: comp.UserProfileView };
        },
      },
      {
        path: ':userId/billing',
        lazy: async () => {
          const comp = await import('../admin/views/UserBillingView');
          return { Component: comp.UserBillingView };
        },
      },
    ],
  },
];
