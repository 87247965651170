import styled from '@emotion/styled';
import { Tag, type TagProps } from 'antd';
import type { FC } from 'react';

export const MiniBadge: FC<TagProps> = (props) => {
  return <MiniTag {...props} />;
};

const MiniTag = styled(Tag)`
  font-size: 9px;
  padding-inline: 5px;
  line-height: 10px;
  margin-inline-end: 4px;
  height: 13px;
`;
