import type { StripeProducts } from '@racemap/sdk/schema/billing/StripeProducts';
import { getAmountForQuantityBasedOnPrice } from '@racemap/utilities/functions/getAmount';
import type Stripe from 'stripe';

export type Price = {
  amount: number;
};

export const getProductPriceFactory = (
  priceList?: Record<StripeProducts, Stripe.Price>,
): ((product: StripeProducts, quantity: number, tier?: number | null) => Price) | undefined => {
  if (priceList == null) return undefined;

  // TODO: move that to the backend
  return (product: StripeProducts, quantity: number, tierIndex = null): Price => {
    const productPrice = priceList[product];
    if (productPrice == null) throw new Error(`Price for product ${product} not found`);

    return { amount: getAmountForQuantityBasedOnPrice(productPrice, quantity, tierIndex) };
  };
};
