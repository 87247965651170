import { toast } from 'react-toastify';
import type { SWRConfiguration } from 'swr';

export const swrConfig: SWRConfiguration = {
  onError: (error, key) => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(error);
      toast.error(`Error fetching ${key}: ${error.message}`);
    }
  },
};
