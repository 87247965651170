import { RacemapColors } from '@racemap/utilities/consts/common';
import { eventTypesByType, isEventSportType } from '@racemap/utilities/consts/eventTypes';
import type { UnitType } from '@racemap/utilities/consts/events';
import { formatOffsetInMeterBasedOnUnitType, formatSpeed } from '@racemap/utilities/formatting';
import { hasSplitOffset } from '@racemap/utilities/functions/map';
import {
  getDefaultSpeed,
  getEventTypeName,
  isNotEmptyString,
} from '@racemap/utilities/functions/utils';
import type { SplitObject } from '@racemap/utilities/types/geos';
import { type Immutable, castImmutable } from 'immer';
import React, { type FC, memo } from 'react';
import type { ElevationChartMarker } from '../ElevationChart';
import {
  RelationBarChart,
  type RelationBarChartEntry,
  type RelationBarChartMarkline,
  type RelationBarEntryTooltipFormatterCallback,
  type RelationBarMarklineTooltipFormatterCallback,
} from '../RelationBarChart';

type LabelKey = 'name' | 'id' | 'newSport' | 'defaultSpeed' | 'none';
interface Props {
  splits: Immutable<Array<SplitObject>>;
  readers?: Immutable<Array<ElevationChartMarker>>;
  useForLabel?: LabelKey;
  unit: UnitType;
  defaultSpeed: number;
}

export const SplitsChart: FC<Props> = memo(function SplitsChart({
  splits,
  readers,
  useForLabel = 'name',
  unit,
  defaultSpeed,
}: Props) {
  const sortedSplits = [...splits]
    .filter(hasSplitOffset)
    .sort((a, b) => a.properties.offset - b.properties.offset);

  const entryTooltipFormatter: RelationBarEntryTooltipFormatterCallback = ({
    label,
    start,
    end,
    marklines,
    meta,
  }) => {
    return `
    <strong>${title}:</strong> ${label}<br/>
    ${
      isNotEmptyString(meta.speedSource)
        ? `<strong>Speed Source:</strong> ${meta.speedSource}<br/>`
        : ''
    }
    <strong>From:</strong> ${formatOffsetInMeterBasedOnUnitType(start, unit)}<br/>
    <strong>To:</strong> ${formatOffsetInMeterBasedOnUnitType(end, unit)}<br/>
    <br/>
    <strong>Marks:</strong><br/>
    ${marklines
      .map(
        (mL) => `
      ${mL.label} (${formatOffsetInMeterBasedOnUnitType(mL.value, unit)})<br/>
    `,
      )
      .join('')}
  `;
  };

  const marklinesTooltipFormatter: RelationBarMarklineTooltipFormatterCallback = ({
    label,
    value,
  }) => `<strong>${label}</strong> (${formatOffsetInMeterBasedOnUnitType(value, unit)})`;

  const { data, marklines, title } = getDataMarklinesAndTitle(
    sortedSplits,
    readers,
    useForLabel,
    unit,
    defaultSpeed,
    entryTooltipFormatter,
    marklinesTooltipFormatter,
  );

  return <RelationBarChart data={data} marklines={marklines} title={title} />;
});

const getDataMarklinesAndTitle = (
  splits: Immutable<Array<SplitObject>>,
  readers: Immutable<Array<ElevationChartMarker>> | undefined,
  useForLabel: LabelKey,
  unit: UnitType,
  defaultSpeed: number,
  entryTooltipFormatter?: RelationBarEntryTooltipFormatterCallback,
  marklinesTooltipFormatter?: RelationBarMarklineTooltipFormatterCallback,
): {
  data: Immutable<Array<RelationBarChartEntry>>;
  marklines: Immutable<Array<RelationBarChartMarkline>>;
  title: string;
} => {
  const data: Array<RelationBarChartEntry> = [];
  const marklines: Array<RelationBarChartMarkline> = [];
  const title = getTitle(useForLabel);

  for (let i = 0; i < splits.length; i++) {
    const split = splits[i];
    const offset = split.properties.offset;
    if (offset == null) continue;

    marklines.push({
      label: split.properties.name || split.id,
      value: Math.floor(offset),
      tooltipFormatter: marklinesTooltipFormatter,
    });

    // skip the last split, dont need that for
    // between parts
    if (i === splits.length - 1) continue;

    const followingSplit = splits[i + 1];
    const followingOffset = followingSplit.properties.offset;
    if (followingOffset == null) continue;

    const label = getLabel(split, useForLabel, unit, defaultSpeed);

    const meta: Record<string, string> = {};
    if (useForLabel === 'defaultSpeed') {
      meta.speedSource = split.properties.defaultSpeed
        ? 'Segment Default'
        : isEventSportType(split.properties.newSport)
          ? `Segment Sport ${eventTypesByType[split.properties.newSport].name}`
          : 'Event Default';
    }

    data.push({
      label,
      value: followingOffset - offset,
      tooltipFormatter: entryTooltipFormatter,
      meta,
    });
  }

  if (readers != null) {
    for (const reader of readers) {
      if (reader.offset == null) continue;
      marklines.push({
        label: `Mapping ${reader.name}`,
        value: reader.offset,
        showLabel: false,
        lineStyle: {
          color: RacemapColors.DarkGreen,
        },
        tooltipFormatter: marklinesTooltipFormatter,
      });
    }
  }
  return { data, marklines: castImmutable(marklines), title };
};

const getLabel = (
  split: Immutable<SplitObject>,
  labelKey: LabelKey,
  unit: UnitType,
  eventDefaultSpeed: number,
): string => {
  switch (labelKey) {
    case 'name':
      return split.properties.name;
    case 'id':
      return split.id;
    case 'newSport':
      return getEventTypeName(split.properties.newSport) || '';
    case 'defaultSpeed': {
      const speed =
        split.properties.defaultSpeed || isNotEmptyString(split.properties.newSport)
          ? getDefaultSpeed(split.properties.newSport)
          : eventDefaultSpeed;
      return `${formatSpeed(speed / 3600, unit)}`;
    }
    case 'none':
      return '';
  }
};

const getTitle = (labelKey: LabelKey): string => {
  switch (labelKey) {
    case 'name':
      return 'Split Name';
    case 'id':
      return 'Split ID';
    case 'newSport':
      return 'Sport';
    case 'defaultSpeed':
      return 'Default\n Speed';
    case 'none':
      return '';
  }
};
