import type { ObjectId } from '@racemap/sdk/schema/base';
import {
  type MessageTemplate,
  MessageTemplateArgumentVariant,
  MessageTemplateVariant,
} from '@racemap/sdk/schema/messageTemplates';
import type { TrackerTag } from '@racemap/sdk/schema/trackerTags';
import {
  TRACKER_MESSAGE_HISTORY_RECORD_STATE,
  TRACKER_TYPES,
  type Tracker,
  type TrackerFull,
  type TrackerMessage,
  type TrackerMessageOverview,
  type TrackerOverview,
  type TrackerPrototype,
} from '@racemap/sdk/schema/trackers';
import { type UserOverview, UserOverviewSchema } from '@racemap/sdk/schema/user';
import { RacemapAPIClient } from '@racemap/utilities/api-client';
import type { USER_ROLE } from '@racemap/utilities/consts/trackers';
import { isValidIMEI } from '@racemap/utilities/functions/isValidIMEI';
import { getUserRoleForTracker } from '@racemap/utilities/functions/trackers';
import { isEmptyString, isNotEmptyString } from '@racemap/utilities/functions/utils';
import { isDefined } from '@racemap/utilities/functions/validation';
import {
  SCAN_SESSION_MESSAGE_TYPES,
  type ScanSessionConnectMessage,
  type ScanSessionDisconnectMessage,
  type ScanSessionReadMessage,
} from '@racemap/utilities/types/trackers';
import { type Draft, type Immutable, produce } from 'immer';
import { DateTime } from 'luxon';
import { NO_EVENT_KEY } from '../../components/TrackerManager/Filter/EventsNode';
import {
  type BatteryFilters,
  Filters,
  type LastAtFilters,
  MessageFilters,
  type UserRoleFilters,
  getActivationState,
  getLocationState,
  getOnlineStateValue,
  hasNoNewMessage,
  isInBatteryFilterRange,
  isInUpdatedAtTimeRange,
  isUserInTrackerRole,
} from '../../components/TrackerManager/Filter/FilterTreeFunctions';
import {
  getCurrentMessageStatusCode,
  isMessageTimedOut,
} from '../../components/TrackerManager/TrackerMessagesTable/TrackerMessagesTableHelper';
import { isSearchedTracker } from '../../lib/isSearchedTracker';
import type { DraftState } from '../reducers';
import type {
  ManagedMessageTemplate,
  ManagedMessageTemplateArgument,
  ManagedMessageTemplates,
  ManagedSampleTracker,
  ManagedTracker,
  ManagedTrackers,
  MessageCounts,
  Role,
  TrackerTags,
} from './trackers_reducers';

export const addUserRoleToTracker = (
  tracker: ManagedTracker,
  userId: string,
): ManagedTracker & { userRole: USER_ROLE | null } => {
  return {
    ...tracker,
    userRole: getUserRoleForTracker(tracker, userId),
  };
};

export const getBorrowStartDate = (tracker: ManagedTracker, userId: string): Date | null => {
  const borrower = tracker.borrowers.find((borrower) => {
    return borrower.id.toHexString() === userId;
  });
  return borrower?.startTime || null;
};

export const getBorrowEndDate = (tracker: ManagedTracker, userId: string): Date | null => {
  const borrower = tracker.borrowers.find((borrower) => {
    return borrower.id.toHexString() === userId;
  });
  return borrower?.endTime || null;
};

export const getMessagesNumber = (
  messages: Immutable<Array<TrackerMessageOverview | TrackerMessage>>,
  type: 'error' | 'pending' | 'scheduled',
): number => {
  let count = 0;
  if (messages.length === 0) return count;

  const messagesSorted = [...messages].sort(
    (a, b) => b.plannedAt.valueOf() - a.plannedAt.valueOf(),
  );
  for (const tM of messagesSorted) {
    if (DateTime.fromJSDate(tM.createdAt) < DateTime.now().minus({ days: 7 })) break;
    const state = StateNumberMapping[getMessageState(tM)];
    if (
      (type === 'error' && state === 9) ||
      (type === 'pending' && state === 4) ||
      (type === 'scheduled' && state === 5)
    )
      count = count + 1;
  }

  return count;
};

export const getMessageCounts = (
  messages: Immutable<Array<TrackerMessageOverview | TrackerMessage>>,
): MessageCounts => {
  const errorCount = getMessagesNumber(messages, 'error');
  const pendingCount = getMessagesNumber(messages, 'pending');
  const scheduledCount = getMessagesNumber(messages, 'scheduled');

  return {
    errorCount,
    pendingCount,
    scheduledCount,
  };
};

export const getTrackerWithMessageCounts = (
  tracker: Immutable<TrackerFull | TrackerOverview>,
): ManagedTracker => {
  return produce<Immutable<TrackerFull | TrackerOverview>, Draft<ManagedTracker>>(tracker, (t) => {
    t.messagesCounts = getMessageCounts(tracker.messages);
  }) as ManagedTracker;
};

export const combineMessages = (
  existingMessages: Array<TrackerMessageOverview | TrackerMessage>,
  newMessages: Immutable<Array<TrackerMessageOverview>>,
): Immutable<Array<TrackerMessageOverview & { payload?: Buffer }>> => {
  const combinedMessages: Array<Immutable<TrackerMessageOverview & { payload?: Buffer }>> = [];
  const updatedMessageIds = new Set<string>(newMessages.map((m) => m.id.toHexString()));

  for (const existingMessage of existingMessages) {
    const update = newMessages.find((newMessage) => newMessage.id.equals(existingMessage.id));
    if (update == null) {
      combinedMessages.push(existingMessage);
      continue;
    }

    combinedMessages.push(
      produce<Immutable<TrackerMessageOverview & { payload?: Buffer }>>(update, (cM) => {
        if ('payload' in existingMessage) {
          cM.payload = existingMessage.payload;
        }
      }),
    );

    updatedMessageIds.delete(update.id.toHexString());
  }

  for (const newMessage of newMessages) {
    if (updatedMessageIds.has(newMessage.id.toHexString())) {
      combinedMessages.push(newMessage);
    }
  }

  return combinedMessages;
};

export const getMessageState = (
  message: Immutable<TrackerMessageOverview | TrackerMessage>,
): string => {
  if (isMessageTimedOut(message)) {
    return 'TIMEOUT';
  }
  if ((message.historyRecords?.length ?? 0) > 0) {
    return getCurrentMessageStatusCode(message);
  }
  if (message.plannedAt != null) {
    if (message.plannedAt.valueOf() < Date.now()) {
      return 'REQUESTED';
    }
    if (message.plannedAt.valueOf() > Date.now()) {
      return 'SCHEDULED';
    }
  }
  return 'UNKNOWN';
};

export const StateNumberMapping: Record<string, number> = {
  UNKNOWN: 0,
  NO_NEW_MESSAGE: 1,
  [TRACKER_MESSAGE_HISTORY_RECORD_STATE.REVOKED]: 2,
  [TRACKER_MESSAGE_HISTORY_RECORD_STATE.CLEARED]: 3,
  REQUESTED: 4,
  SCHEDULED: 5,
  [TRACKER_MESSAGE_HISTORY_RECORD_STATE.COMPLETE]: 6,
  [TRACKER_MESSAGE_HISTORY_RECORD_STATE.SENT]: 7,
  [TRACKER_MESSAGE_HISTORY_RECORD_STATE.DELIVERED]: 8,
  [TRACKER_MESSAGE_HISTORY_RECORD_STATE.ERROR]: 9,
  TIMEOUT: 10,
};

export enum InternFilters {
  Owner = 'owner',
  Editor = 'editor',
  Borrower = 'borrower',
}

export const isFilteredTracker = (
  tracker: Immutable<ManagedTracker>,
  searchValue: string,
  filterSet: Immutable<Set<string>>,
  tags?: Immutable<TrackerTags>,
): boolean => {
  if (isNotEmptyString(searchValue)) {
    if (!isSearchedTracker(tracker, searchValue)) return false;
  }

  // if no filter are set, we can stop here
  if (filterSet.size === 0) return true;

  const filterValues = [...Object.values(Filters), ...Object.values(InternFilters)];

  const categoryRes = filterValues.reduce<Record<Filters | InternFilters, boolean | null>>(
    (pV, v) => {
      pV[v] = null;
      return pV;
    },
    {} as Record<Filters | InternFilters, boolean | null>,
  );

  for (const filter of filterSet) {
    const [, cat, ...group] = filter.split('/');
    const filterCategory = cat as Filters | InternFilters;

    // the tracker has to fit only once in category.
    categoryRes[filterCategory] =
      categoryRes[filterCategory] || checkFilterCategory(filterCategory, group, tracker, tags);
  }

  // but in the end every category has to be true or null
  return Object.values(categoryRes).every((c) => c == null || c);
};

export const filterTrackers = (
  trackers: Immutable<ManagedTrackers>,
  searchValue: string,
  filterSet: Immutable<Set<string>>,
  tags?: Immutable<TrackerTags>,
): Immutable<Array<ManagedTracker>> => {
  const trackerArray = Array.from(trackers.values());
  return trackerArray.filter((t) => isFilteredTracker(t, searchValue, filterSet, tags));
};

const checkFilterCategory = (
  category: Filters | InternFilters,
  group: Array<string>,
  tracker: Immutable<ManagedTracker>,
  tags: Immutable<TrackerTags>,
): boolean | null => {
  const mainGroup = group[0];

  switch (category) {
    case Filters.Tags: {
      const tag = tags.get(mainGroup);
      if (tag == null) return null;
      return tag.trackerIds.some((tId) => tId.equals(tracker.id));
    }
    case Filters.Battery:
      return isInBatteryFilterRange(mainGroup as BatteryFilters, tracker.state.battery);
    case Filters.Location:
      return mainGroup === getLocationState(tracker).toString();
    case Filters.Online:
      return mainGroup === getOnlineStateValue(tracker.state).toString();
    case Filters.TrackerType:
      return mainGroup === tracker.trackerType;
    case Filters.Events:
      if (mainGroup === NO_EVENT_KEY)
        return tracker.eventIds == null || tracker.eventIds.length === 0;
      return tracker.eventIds?.findIndex((id) => id.toHexString() === mainGroup) !== -1;
    case Filters.LastConnectedAt:
      return isInUpdatedAtTimeRange(mainGroup as LastAtFilters, tracker.state.lastConnectedAt);
    case Filters.LastDataAt:
      return isInUpdatedAtTimeRange(mainGroup as LastAtFilters, tracker.state.lastDataAt);
    case Filters.LastDisconnectedAt:
      return isInUpdatedAtTimeRange(mainGroup as LastAtFilters, tracker.state.lastDisconnectedAt);
    case Filters.Activation:
      return mainGroup === getActivationState(tracker.activation);
    case Filters.Messages:
      if (mainGroup === MessageFilters.NO_NEW_MESSAGES) return hasNoNewMessage(tracker.messages);
      if (
        mainGroup === MessageFilters.WITH_ERROR_MESSAGES ||
        mainGroup === MessageFilters.WITH_PENDING_MESSAGES ||
        mainGroup === MessageFilters.WITH_SCHEDULED_MESSAGES
      )
        return tracker.messagesCounts != null && tracker.messagesCounts[mainGroup] > 0;
      return false;
    case Filters.Logistics:
      return mainGroup === tracker.logistics;
    case Filters.Users: {
      const userId = group[0];
      const userRole = group[1] as UserRoleFilters;
      return isUserInTrackerRole(userId, userRole, tracker);
    }
    default:
      return null;
  }
};

export const getCategoryBasedCounts = (
  filter: Filters,
  groups: Array<string | number>,
  trackers: Immutable<Map<string, ManagedTracker>>,
  tags: Immutable<Map<string, TrackerTag>>,
): Map<string, number> => {
  const counts: Map<string, number> = new Map();
  for (const group of groups) {
    const count = filterTrackers(trackers, '', new Set([`/${filter}/${group}`]), tags).length;

    counts.set(group.toString(), count);
  }

  return counts;
};

export function testScanSessionConnectionMessage(
  message: unknown,
): asserts message is ScanSessionConnectMessage {
  if (
    message == null ||
    typeof message !== 'object' ||
    !('type' in message) ||
    !('readerId' in message) ||
    message.type !== SCAN_SESSION_MESSAGE_TYPES.CONNECT ||
    typeof message.readerId !== 'string'
  ) {
    throw new Error('Receive invalid connect message!');
  }
}

export function testScanSessionDisconnectionMessage(
  message: unknown,
): asserts message is ScanSessionDisconnectMessage {
  if (
    message == null ||
    typeof message !== 'object' ||
    !('type' in message) ||
    !('readerId' in message) ||
    message.type !== SCAN_SESSION_MESSAGE_TYPES.DISCONNECT ||
    typeof message.readerId !== 'string'
  ) {
    throw new Error('Receive invalid disconnect message!');
  }
}

export function testScanSessionReadMessage(
  message: unknown,
): asserts message is ScanSessionReadMessage {
  if (
    message == null ||
    typeof message !== 'object' ||
    !('type' in message) ||
    !('readerId' in message) ||
    !('trackerId' in message) ||
    message.type !== SCAN_SESSION_MESSAGE_TYPES.READ ||
    typeof message.readerId !== 'string' ||
    typeof message.trackerId !== 'string'
  ) {
    throw new Error('Receive invalid read message!');
  }
}

function updateUserMap(
  usersWithRoleAndTrackers: DraftState['trackers']['usersWithRoleAndTrackers'],
  userId: string,
  role: Role,
  trackerId: string,
) {
  if (!usersWithRoleAndTrackers.has(userId)) {
    usersWithRoleAndTrackers.set(userId, new Map());
  }
  const userMap = usersWithRoleAndTrackers.get(userId);
  if (!userMap?.has(role)) userMap?.set(role, new Set());
  const trackerList = userMap?.get(role);
  trackerList?.add(trackerId);
}

export function updateUsersWithRoleAndTrackers(
  tracker: ManagedTracker,
  usersWithRoleAndTrackers: DraftState['trackers']['usersWithRoleAndTrackers'],
) {
  tracker.borrowers.forEach(
    (borrower: {
      id: ObjectId;
      startTime: Date;
      endTime: Date;
    }) => {
      updateUserMap(
        usersWithRoleAndTrackers,
        borrower.id.toHexString(),
        'borrower',
        tracker.id.toHexString(),
      );
    },
  );

  tracker.editors.forEach((editor: ObjectId) => {
    updateUserMap(
      usersWithRoleAndTrackers,
      editor.toHexString(),
      'editor',
      tracker.id.toHexString(),
    );
  });

  updateUserMap(
    usersWithRoleAndTrackers,
    tracker.owner.toHexString(),
    'owner',
    tracker.id.toHexString(),
  );
}

export function getAllUserIDsFromTracker(tracker: ManagedTracker): string[] {
  const userIds: string[] = [];
  userIds.push(tracker.owner.toHexString());
  tracker.editors.forEach((editor) => userIds.push(editor.toHexString()));
  tracker.borrowers.forEach((borrower) => userIds.push(borrower.id.toHexString()));
  return userIds;
}

export const getTrackerTypesOfMessageTemplateGroup = (
  children: Immutable<Array<MessageTemplate>>,
): Array<TRACKER_TYPES> => {
  return Array.from(
    new Set(
      children.reduce((acc, t) => acc.concat(t.trackerTypes), [] as Array<TRACKER_TYPES>) || [],
    ),
  );
};

export const getArgumentsOfMessageTemplateGroup = (
  children: Array<MessageTemplate>,
): Record<string, ManagedMessageTemplateArgument> => {
  const args: Record<string, ManagedMessageTemplateArgument> = {};

  for (const template of children) {
    const trackerTypes = template.trackerTypes;

    for (const arg of template.arguments) {
      if (arg.variant !== MessageTemplateArgumentVariant.PARAMETER) continue;

      args[arg.id.toHexString()] = {
        ...arg,
        trackerTypes: [...trackerTypes],
      };
    }
  }

  return args;
};

export const prepareMessageTemplates = (
  templates: Array<MessageTemplate>,
): ManagedMessageTemplates => {
  const preparedTemplates = new Map<string, ManagedMessageTemplate>();
  const groupes = templates.filter((template) => template.variant === MessageTemplateVariant.GROUP);

  groupes.forEach((template) => {
    const children = template.subTemplates
      .map((sId) => templates.find((t) => t.id.equals(sId)))
      .filter(isDefined);
    const trackerTypes = getTrackerTypesOfMessageTemplateGroup(children);
    const args = getArgumentsOfMessageTemplateGroup(children);

    template.trackerTypes = Array.from(trackerTypes);
    template.arguments = Object.values(args);
    preparedTemplates.set(template.id.toHexString(), template);
  });
  const singles = templates.filter(
    (template) =>
      template.variant === MessageTemplateVariant.SINGLE &&
      !groupes.some((g) => g.subTemplates.find((sId) => sId.equals(template.id))),
  );
  singles.forEach((template) => {
    const args = template.arguments.map((arg) => ({
      ...arg,
      trackerTypes: [...template.trackerTypes],
    }));
    preparedTemplates.set(template.id.toHexString(), { ...template, arguments: args });
  });
  return preparedTemplates;
};

export function isManagedTracker(tracker: Tracker | Immutable<Tracker>): tracker is ManagedTracker {
  return 'messages' in tracker;
}

export function isManagedSampleTracker(
  tracker: Tracker | Immutable<Tracker>,
): tracker is ManagedSampleTracker {
  return isManagedTracker(tracker) && 'index' in tracker;
}

export const getUserIdsFromFilterSet = (filterSet: Immutable<Set<string>>): Set<string> => {
  const usersPrefix = '/users/';

  const result = new Set<string>(
    Array.from(filterSet)
      .filter((item) => item.startsWith(usersPrefix))
      .map((item) => item.slice(usersPrefix.length).split('/')[0]),
  );

  return result;
};

export const validateTrackerPrototypes = (
  trackerPrototypes: Immutable<Array<TrackerPrototype>>,
): void => {
  for (const tracker of trackerPrototypes) {
    if (tracker.trackerType == null || isEmptyString(tracker.trackerType)) {
      throw new Error(`Type of tracker ${tracker.trackerName || tracker.trackerId} is missing`);
    }

    if (!Object.values(TRACKER_TYPES).includes(tracker.trackerType as TRACKER_TYPES)) {
      throw new Error(`Type of tracker ${tracker.trackerName || tracker.trackerId} is invalid`);
    }

    if (tracker.trackerId == null || isEmptyString(tracker.trackerId)) {
      throw new Error(
        `Tracker ID of tracker ${tracker.trackerName || tracker.trackerId} is missing`,
      );
    }

    if (/^[0-9]{15}$/.test(tracker.trackerId) && !isValidIMEI(tracker.trackerId)) {
      throw new Error(
        `Tracker ID of tracker ${tracker.trackerName || tracker.trackerId} is invalid imei!`,
      );
    }

    if (/^[0-9]{14}$/.test(tracker.trackerId) && isValidIMEI(`0${tracker.trackerId}`)) {
      throw new Error(
        `Tracker ID of tracker ${
          tracker.trackerName || tracker.trackerId
        } is invalid. Leading zero was removed.`,
      );
    }
  }
};

const apiClient = RacemapAPIClient.fromWindowLocation();

export async function fetchUsers(
  searchValue: string,
  filterSet: Immutable<Set<string>>,
): Promise<Map<string, UserOverview>> {
  const searchedUsersResponse = await apiClient.getUsers({
    limit: 100,
    search: searchValue,
  });

  const userIdRegex = /\/users\/(\w+)/;
  const userIds = new Set<string>();
  filterSet.forEach((path) => {
    const match = userIdRegex.exec(path);
    if (match?.[1]) userIds.add(match[1]);
  });

  const filteredUsersResponse = userIds.size
    ? await apiClient.getUsers({ ids: Array.from(userIds) })
    : { users: [] };

  const merged = new Map<string, UserOverview>();
  for (const user of [...searchedUsersResponse.users, ...filteredUsersResponse.users]) {
    merged.set(user.id, UserOverviewSchema.parse(user));
  }

  return merged;
}
