import type { User } from '@racemap/sdk/schema/user';
import type { User_Legacy } from '@racemap/utilities/types/users';
import { Flex } from 'antd';
import type { Immutable } from 'immer';
import type { FC } from 'react';
import { useCurrentUser } from '../../lib/customHooks';
import { AdminBadge, ResellerBadge } from '../BasicComponents/Badges';

interface Props {
  user?: Immutable<User> | Immutable<User_Legacy> | null;
}

export const UserRoleBadges: FC<Props> = ({ user }) => {
  const currentUser = useCurrentUser();
  const targetUser = user || currentUser;

  if (!targetUser) return null;
  const isAdmin = targetUser?.admin;

  return (
    <Flex justify="flex-end" className="user-role-badges">
      {isAdmin && <AdminBadge />}
      {targetUser.isReseller && <ResellerBadge />}
    </Flex>
  );
};
