import React, { type ReactElement } from 'react';
import { Component } from 'react';
import Button from './Button';
import type { Props } from './Button';
import { IconFail, IconOk, IconSpinner } from './Icon';

export enum Tristate {
  NORMAL = 0,
  SPINNING = 1,
  FAILURE = 2,
}

export function tristateTask<T>(func: () => Promise<T>, setter: (arg0: Tristate) => void) {
  setter(Tristate.SPINNING);
  const result = func();
  result.then(
    () => {
      setter(Tristate.NORMAL);
    },
    () => {
      setter(Tristate.FAILURE);
    },
  );
  return result;
}

type ButtonWithSpinnerProps = Props & {
  id?: string;
  disabled?: boolean;
  buttonState: Tristate;
  onClick?: () => void;
  iconComponent?: ReactElement;
};

type ButtonWithSpinnerState = {
  iconComponent: null | ReactElement;
};

class ButtonWithSpinner extends Component<ButtonWithSpinnerProps, ButtonWithSpinnerState> {
  timerId: number | null = null;
  state: ButtonWithSpinnerState = {
    iconComponent: null,
  };

  componentDidUpdate(prevProps: ButtonWithSpinnerProps) {
    if (this.props.buttonState !== prevProps.buttonState) {
      switch (this.props.buttonState) {
        case Tristate.NORMAL:
          if (prevProps.buttonState === Tristate.SPINNING) {
            this.setState({ iconComponent: <IconOk /> });
            this.timerId = window.setTimeout(() => {
              this.setState({ iconComponent: null });
            }, 5000);
          } else {
            this.setState({ iconComponent: null });
          }
          break;
        case Tristate.FAILURE:
          this.setState({ iconComponent: <IconFail /> });
          this.clearTimeout();
          break;
        case Tristate.SPINNING:
          this.setState({ iconComponent: <IconSpinner /> });
          this.clearTimeout();
          break;
      }
    }
  }

  componentWillUnmount() {
    this.clearTimeout();
  }

  clearTimeout() {
    if (this.timerId != null) {
      window.clearTimeout(this.timerId);
      this.timerId = null;
    }
  }

  render() {
    const { iconComponent, buttonState, disabled = false, ...props } = this.props;
    const currentIconComponent =
      this.state.iconComponent != null ? this.state.iconComponent : iconComponent;

    return (
      <Button
        icon={currentIconComponent}
        disabled={disabled || buttonState === Tristate.SPINNING}
        {...props}
      />
    );
  }
}
export default ButtonWithSpinner;
